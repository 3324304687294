import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {
  flowTypeInfotext,
  onchainFlowTypeInfotext,
  offchainFlowTypeInfotext,
} from 'texts';
import { InfoIcon } from '../../../components/Icons/Icons';
import { flowTypes } from '../liquidityTypes';
import {
  BpCheckedIconRadio,
  BpIconRadio,
} from '../../../components/ControlButtons/Radio';
import { GradientBox } from '../../../components/Containers/Box';

type Props = {
  value: flowTypes;
  onChange: FormControlLabelProps['onChange'];
};

export const FlowTypeSection = (props: Props) => {
  return (
    <GradientBox>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        flex={1}
      >
        <Box
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          flex={1}
          mb={1}
        >
          <Typography variant="h2">Flow Type </Typography>
          <InfoIcon title={flowTypeInfotext} />
        </Box>

        <FormControl sx={{ flex: 1 }}>
          <RadioGroup value={props.value}>
            <Grid container direction="column">
              <Grid item display="flex">
                <Box display="flex" flexDirection="column" flex={1}>
                  <FormControlLabel
                    value={flowTypes.ON_CHAIN}
                    control={
                      <Radio
                        icon={<BpIconRadio />}
                        checkedIcon={<BpCheckedIconRadio />}
                      />
                    }
                    label={
                      <Box display="inline-flex" alignItems="center">
                        <Typography variant="body2">On-chain</Typography>
                        <InfoIcon title={onchainFlowTypeInfotext} />
                      </Box>
                    }
                    onChange={props.onChange}
                  />
                </Box>
              </Grid>

              <Grid item display="flex">
                <Box display="flex" flexDirection="column" flex={1}>
                  <FormControlLabel
                    value={flowTypes.OFF_CHAIN}
                    control={
                      <Radio
                        icon={<BpIconRadio />}
                        checkedIcon={<BpCheckedIconRadio />}
                      />
                    }
                    label={
                      <Box display="inline-flex" alignItems="center">
                        <Typography variant="body2">Off-chain</Typography>
                        <InfoIcon title={offchainFlowTypeInfotext} />
                      </Box>
                    }
                    onChange={props.onChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Box>
    </GradientBox>
  );
};
