import { lazy } from 'react';
import { ChartData } from 'chart.js';
import { Box, Typography } from '@mui/material';
import { TokenFields } from 'enums';
import { useCurrentToken } from 'hooks/useCurrentToken';
import { BondingCurve } from 'types/Token';
import trimHash from 'utils/trimHash';
import round from 'utils/round';
import { useAmountChartTraces } from '../../components/LineChart/lineChartUtils';
import { GradientBox } from '../../components/Containers/Box';
import { convertValuesToInt } from '../Liquidity/liquidityUtils';

const LineChart = lazy(() => import('../../components/LineChart/LineChart'));

const AmountChart = ({
  tokenName,
  bondingCurve,
}: {
  tokenName: string;
  bondingCurve: BondingCurve;
}) => {
  const traces = useAmountChartTraces({
    domain: bondingCurve.domain,
    buy: convertValuesToInt({
      a: bondingCurve.buy.poly[2],
      b: bondingCurve.buy.poly[1],
      c: bondingCurve.buy.poly[0],
    }),
    sell: convertValuesToInt({
      a: bondingCurve.sell.poly[2],
      b: bondingCurve.sell.poly[1],
      c: bondingCurve.sell.poly[0],
    }),
    x0: bondingCurve.supply,
  });

  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      <Box display="flex" alignItems="flex-start" flexDirection="column">
        <Typography variant="h4" sx={{ whiteSpace: 'nowrap' }}>
          Supply CWEB:
        </Typography>
        <Typography variant="h4" sx={{ whiteSpace: 'nowrap' }} mb={3}>
          {round(bondingCurve.supply, 5).toFixed()}
        </Typography>
        <Typography variant="h4" sx={{ whiteSpace: 'nowrap' }}>
          Excess CWEB:
        </Typography>
        <Typography variant="h4" sx={{ whiteSpace: 'nowrap' }}>
          {round(bondingCurve.profit, 5).toFixed()}{' '}
        </Typography>
      </Box>

      <Box sx={{ mt: 5, flex: 1, ml: 4, height: '50vh', width: '80%' }}>
        <LineChart
          xAxisTitle="CWEB"
          yAxisTitle="Token"
          tooltipTitle="CWEB"
          tooltipLabelSuffix={tokenName}
          data={traces as ChartData<'line'>}
        />
      </Box>
    </Box>
  );
};

export const BondingCurveChart = () => {
  const [currentToken] = useCurrentToken();

  if (!currentToken || !currentToken.bondingCurve) {
    return null;
  }

  return (
    <GradientBox>
      <Box>
        <Typography variant="h2">Bonding curve</Typography>
        <Box marginLeft="auto" marginRight="auto">
          <AmountChart
            tokenName={
              currentToken.extraField(TokenFields.NAME) ??
              trimHash(currentToken.hashId)
            }
            bondingCurve={currentToken.bondingCurve}
          />
        </Box>
      </Box>
    </GradientBox>
  );
};
