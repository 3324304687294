import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { noop } from 'lodash-es';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { REACT_APP_DEVNET } from 'conf';
import { useIsMediumScreen } from 'hooks/useMediaQuery';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ReactComponent as Logo } from 'images/linkMintLogo.svg';
import { ReactComponent as CwebLogo } from 'images/logo.svg';
import variables from 'styles/variables.module.scss';
import { IssuedToken } from 'modules/tokenization/components/Tokenization/IssuedToken';
import { fieldSidebarOpen } from 'redux/reducers/tokenReducer';
import { useSectionsState } from './sidebarHooks';
import { NestedList } from './NestedList';
import styles from './Sidebar.module.scss';
import { Link } from './Link';
import { SidebarItemType } from './types';

type Props = {
  items: SidebarItemType[];
  footerItems?: SidebarItemType[];
  isDAO: boolean;
};

export const Sidebar = (props: Props) => {
  const location = useLocation();
  const isMediumScreen = useIsMediumScreen();
  const [sectionsState, toggleSectionState] = useSectionsState(props.items);
  const dispatch = useDispatch();
  const { sidebarOpen } = useSelector((state: any) => state.token);
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: sidebarOpen ? '#090a39' : 'transparent',
        height: 'fit-content',
        zIndex: '1202',
      }}
    >
      {sidebarOpen ? (
        <>
          {props.isDAO ? (
            <CwebLogo
              className={styles.logo}
              onClick={() => dispatch(fieldSidebarOpen(!sidebarOpen))}
            />
          ) : (
            <div onClick={() => dispatch(fieldSidebarOpen(!sidebarOpen))}>
              <Logo className={styles.logo} />
              {REACT_APP_DEVNET === 'true' && (
                <Typography
                  fontSize="18px"
                  sx={{
                    position: 'fixed',
                    zIndex: 1204,
                    left: '110px',
                    top: '60px',
                    color: '#fff',
                    fontWeight: 900,
                    fontFamily: 'inherit',
                  }}
                >
                  DEVNET
                </Typography>
              )}
            </div>
          )}

          <Box className={styles.arrowIconsWrapper}>
            <KeyboardDoubleArrowLeftIcon
              className={styles.arrowIcons}
              onClick={() => dispatch(fieldSidebarOpen(false))}
            />
          </Box>
        </>
      ) : (
        <>
          {props.isDAO ? (
            <CwebLogo
              className={styles.logo}
              onClick={() => dispatch(fieldSidebarOpen(!sidebarOpen))}
            />
          ) : (
            <div onClick={() => dispatch(fieldSidebarOpen(!sidebarOpen))}>
              <Logo
                className={styles.logo}
                onClick={() => dispatch(fieldSidebarOpen(!sidebarOpen))}
              />
              {REACT_APP_DEVNET === 'true' && (
                <Typography
                  fontSize="18px"
                  sx={{
                    position: 'fixed',
                    zIndex: 1204,
                    left: '110px',
                    top: '60px',
                    color: '#fff',
                    fontWeight: 900,
                    fontFamily: 'inherit',
                  }}
                >
                  DEVNET
                </Typography>
              )}
            </div>
          )}
          <Box className={styles.arrowIconsWrapper}>
            <KeyboardDoubleArrowRightIcon
              className={styles.arrowIcons}
              onClick={() => dispatch(fieldSidebarOpen(true))}
            />
          </Box>
        </>
      )}
      <Drawer
        sx={{
          width: {
            xs: sidebarOpen ? variables.sidebarWidthXs : '0px',
            sm: sidebarOpen ? variables.sidebarWidth : '0px',
          },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: {
              xs: variables.sidebarWidthXs,
              sm: variables.sidebarWidth,
            },
            boxSizing: 'border-box',
            paddingTop: '100px',
          },
        }}
        variant="persistent"
        PaperProps={{
          elevation: 0,
          className: styles.sidebar,
        }}
        anchor="left"
        open={sidebarOpen}
      >
        {!isMediumScreen ? <IssuedToken /> : null}
        {/* {isMobile && REACT_APP_MODE === 'pro' ? (
          <Box sx={{ paddingLeft: '10px' }}>
            <SelectNetwork
              disableUnderline
              defaultValue={0}
              variant="standard"
              sx={{
                width: {
                  xs: '187px',
                  sm: '238px',
                },
              }}
            >
              <MenuItem value={0}>Betanet</MenuItem>
            </SelectNetwork>
          </Box>
        ) : null} */}
        <List component="nav" sx={{ padding: '0px' }}>
          {props.items.map((item, index) => {
            // @ts-ignore TODO
            const isExpanded = sectionsState[index];

            return (
              <Fragment key={item.url}>
                <ListItem
                  disablePadding
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    pointerEvents: item.disabled ? 'none' : undefined,
                  }}
                  to={item.url}
                  component={Link}
                  // @ts-ignore
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 600 : 500,
                  })}
                >
                  <ListItemButton
                    disabled={item.disabled}
                    sx={{ width: '100%' }}
                    onClick={
                      item.items ? () => toggleSectionState(index) : noop
                    }
                  >
                    <ListItemIcon
                      sx={{ minWidth: '36px', marginRight: '10px' }}
                      className="text-inherit"
                    >
                      {location.pathname.includes(item.url)
                        ? item.activeIcon
                        : item.icon}
                    </ListItemIcon>
                    <ListItemText disableTypography primary={item.text} />
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {item.items ? (
                      isExpanded ? (
                        <ExpandLess className="text-inherit" />
                      ) : (
                        <ExpandMore className="text-inherit" />
                      )
                    ) : null}
                  </ListItemButton>
                </ListItem>

                {item.items && (
                  <NestedList
                    // @ts-ignore TODO
                    icon={item.icon}
                    nestingLevel={2}
                    isOpen={isExpanded}
                    items={item.items}
                    url={item.url}
                  />
                )}
              </Fragment>
            );
          })}
        </List>
        {props.footerItems && (
          <Box mt="auto" mb={5}>
            {props.footerItems.map((item) => {
              return (
                <ListItem
                  key={item.url}
                  disablePadding
                  onClick={item.onClick}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  to={item.url}
                  component={Link}
                  // @ts-ignore
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 600 : 500,
                  })}
                >
                  <ListItemButton
                    sx={{ width: '100%' }}
                    disabled={item.disabled}
                  >
                    <ListItemIcon className="text-inherit">
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText disableTypography primary={item.text} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </Box>
        )}
      </Drawer>
    </Box>
  );
};
