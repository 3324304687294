import {
  Box,
  Button,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const DetailsPage = () => {
  return (
    <Paper elevation={0}>
      <Stack pt={2} pb={6} pl={2} pr={2} spacing={3}>
        <Typography variant="h6" textAlign="center">
          Details
        </Typography>

        <Box>
          <Typography className="font-medium" mb={1}>
            Contract version
          </Typography>
          <Link sx={{ display: 'inline-flex' }} className="cursor-pointer">
            <Typography mr={1}>1.0</Typography>
            <OpenInNewIcon />
          </Link>
        </Box>

        <Box>
          <Typography className="font-medium" mb={1}>
            Blockchain network
          </Typography>
          <Box display="flex">
            <CheckCircleIcon color="success" />
            <Typography variant="body1" sx={{ display: 'inline-block', ml: 2 }}>
              Ethereum
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography className="font-medium" mb={1}>
            Modify Safe Name
          </Typography>
          <Typography variant="body1" mb={1}>
            You can change the name of this Safe. This name is only stored
            locally and never shared with us or any third parties.
          </Typography>
          <TextField
            required
            error
            helperText="Required"
            label="Safe name"
            placeholder="Safe name"
          />
        </Box>

        <Box textAlign="right">
          <Button variant="contained">Save</Button>
        </Box>
      </Stack>
    </Paper>
  );
};
