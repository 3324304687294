import { useState } from 'react';
import { noop } from 'lodash-es';
import cx from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Select,
  TextField,
  Typography,
  Divider,
} from '@mui/material';
import { DatePicker } from 'components/DatePicker';
import { NumericInput } from 'components/NumericInput';
import styles from './Staking.module.scss';
import { StakingTable } from './Table';
import {
  BpCheckedIconRadio,
  BpIconRadio,
} from '../../components/ControlButtons/Radio';
import { BeigeButton } from '../../components/ControlButtons/Button';
import { GradientBox, SpaceBetweenBox } from '../../components/Containers/Box';
import { SelectTokenization } from '../../components/Input/Select';

const fundingTypes = {
  FIXED: 'fixed',
  VARIABLE: 'minted',
  MANUAL: 'manual',
};

const flowTypes = {
  PERCENTAGE: 'percentage',
  PER_DAY: 'per_day',
};

export const StakingPage = () => {
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const [fundingType, setFundingType] = useState(fundingTypes.FIXED);
  const [flowType, setFlowType] = useState(flowTypes.PERCENTAGE);
  const [add, setAdd] = useState<boolean>(false);

  const handleDateChange = (newDate: Dayjs | null) => {
    setDate(newDate);
  };

  const handleFundingTypeChange: RadioGroupProps['onChange'] = (ev) => {
    setFundingType(ev.target.value);
  };

  const handleFlowTypeChange: RadioGroupProps['onChange'] = (ev) => {
    setFlowType(ev.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" padding="0px 20px">
      <GradientBox>
        <Typography variant="h2">Staking</Typography>
        <StakingTable />
      </GradientBox>

      {add ? (
        <GradientBox>
          <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
            <Typography variant="h4">Start date & End date</Typography>
            <Box display="flex">
              <DatePicker
                disabled
                className="w-full mr-5"
                date={date}
                onChange={handleDateChange}
              />
              <DatePicker
                disabled
                className="w-full"
                date={date}
                onChange={handleDateChange}
              />
            </Box>
          </SpaceBetweenBox>
          <Divider />

          <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
            <Typography variant="h4">Minimum staking period</Typography>
            <div>
              <SelectTokenization
                disabled
                className={styles.selectCommon}
                value={0}
              >
                <MenuItem value={0}>1 week</MenuItem>
                <MenuItem value={1}>Option #2</MenuItem>
              </SelectTokenization>
            </div>
          </SpaceBetweenBox>
          <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
            <Typography variant="h4">Staking Pool</Typography>
            <div>
              <SelectTokenization
                disabled
                className={styles.selectCommon}
                value={0}
              >
                <MenuItem value={0}>Burned</MenuItem>
                <MenuItem value={1}>Option #2</MenuItem>
              </SelectTokenization>
            </div>
          </SpaceBetweenBox>

          <Grid
            container
            className={styles.section}
            mt={2}
            pb={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={6} flexDirection="column" display="flex">
              <Typography
                className={cx('font-medium', styles.label)}
                mb={1}
                pt={1}
                variant="body2"
              >
                Penalty for early withdrawal
              </Typography>
            </Grid>
            <Grid item xs={6} flexDirection="column" display="flex">
              <Box width="45%" pl={2}>
                <FormControl fullWidth>
                  <NumericInput
                    disabled
                    placeholder="Enter amount"
                    onChange={noop}
                    dispatch={false}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            className={styles.section}
            mt={2}
            pb={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={6} flexDirection="column" display="flex">
              <Typography
                className={cx('font-medium', styles.label)}
                mb={1}
                pt={1}
                variant="body2"
              >
                Staking pool
              </Typography>
            </Grid>
            <Grid item xs={6} flexDirection="column" display="flex">
              <Box width="45%" pl={2}>
                <FormControl fullWidth>
                  <Select disabled defaultValue={1}>
                    <MenuItem value={1}>Burned</MenuItem>
                    <MenuItem value={2}>Specific address</MenuItem>
                    <MenuItem value={3}>Liquid staking</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            className={styles.section}
            mt={2}
            pb={2}
            alignItems="top"
            justifyContent="center"
          >
            <Grid item xs={6} flexDirection="column" display="flex">
              <Typography
                className={cx('font-medium', styles.label)}
                mb={1}
                pt={2}
                variant="body2"
              >
                How program is funded
              </Typography>
            </Grid>
            <Grid item xs={6} flexDirection="column" display="flex">
              <Box width="45%" pl={2}>
                <FormControl fullWidth>
                  <NumericInput
                    disabled
                    placeholder="Enter amount"
                    onChange={noop}
                    dispatch={false}
                  />
                </FormControl>
                <FormControl sx={{ mt: 1, whiteSpace: 'noWrap' }}>
                  <RadioGroup
                    value={fundingType}
                    onChange={handleFundingTypeChange}
                  >
                    <FormControlLabel
                      value={fundingTypes.FIXED}
                      control={
                        <Radio
                          disabled
                          icon={<BpIconRadio />}
                          checkedIcon={<BpCheckedIconRadio />}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          Fixed minted amount
                        </Typography>
                      }
                    />
                    {/* {fundingType === fundingTypes.FIXED && ( */}
                    {/*  <Box ml={4}> */}
                    {/*    <TextField placeholder="Enter amount" type="number" /> */}
                    {/*  </Box> */}
                    {/* )} */}
                    <FormControlLabel
                      value={fundingTypes.VARIABLE}
                      control={
                        <Radio
                          disabled
                          icon={<BpIconRadio />}
                          checkedIcon={<BpCheckedIconRadio />}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          Variable minted amount (APR %)
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={fundingTypes.MANUAL}
                      control={
                        <Radio
                          disabled
                          icon={<BpIconRadio />}
                          checkedIcon={<BpCheckedIconRadio />}
                        />
                      }
                      label={
                        <Typography variant="body2">Manually funded</Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            className={styles.section}
            mt={2}
            pb={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={6} flexDirection="column" display="flex">
              <Typography
                className={cx('font-medium', styles.label)}
                mb={1}
                pt={1}
                variant="body2"
              >
                Max flow out of the staking program
              </Typography>
            </Grid>
            <Grid item xs={6} flexDirection="column" display="flex">
              <Box width="45%" pl={2}>
                <FormControl>
                  {/* {flowType === flowTypes.PERCENTAGE && ( */}
                  <TextField
                    disabled
                    placeholder="Enter amount"
                    type="number"
                    sx={{ mb: 1 }}
                  />
                  {/* )} */}
                  <RadioGroup value={flowType} onChange={handleFlowTypeChange}>
                    <FormControlLabel
                      value={flowTypes.PERCENTAGE}
                      control={
                        <Radio
                          disabled
                          icon={<BpIconRadio />}
                          checkedIcon={<BpCheckedIconRadio />}
                        />
                      }
                      label={<Typography variant="body2">% per day</Typography>}
                    />
                    <FormControlLabel
                      value={flowTypes.PER_DAY}
                      control={
                        <Radio
                          disabled
                          icon={<BpIconRadio />}
                          checkedIcon={<BpCheckedIconRadio />}
                        />
                      }
                      label={<Typography variant="body2">per day</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </GradientBox>
      ) : null}
      <Box display="flex" justifyContent="center" mt={2}>
        <BeigeButton
          disabled
          onClick={() => setAdd(true)}
          className={styles.button}
          variant="contained"
        >
          Add Staking Program
        </BeigeButton>
      </Box>
    </Box>
  );
};
