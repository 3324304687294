import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import { GradientBox } from '../components/Containers/Box';
import { Switch } from '../components/ControlButtons/Switch';

export const ExchangePage = () => {
  return (
    <Grid
      container
      gap={0}
      justifyContent="center"
      spacing="20px "
      padding="0px 20px"
    >
      <Grid item xs={4}>
        <GradientBox sx={{ marginTop: ' 0px', height: '100%' }}>
          <Typography variant="h2" mb="10px">
            Fiat Payment Rails
          </Typography>
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              control={<Switch disabled />}
              label={<Typography variant="body2">Bank Transfer</Typography>}
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 0,
              }}
            />
            <FormControlLabel
              control={<Switch disabled />}
              label={<Typography variant="body2">Credit/Debit Card</Typography>}
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 0,
              }}
            />
            <FormControlLabel
              control={<Switch disabled />}
              label={<Typography variant="body2">Wallet</Typography>}
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 0,
              }}
            />
          </Box>
        </GradientBox>
      </Grid>

      <Grid item xs={4}>
        <GradientBox sx={{ marginTop: ' 0px', height: '100%' }}>
          <Typography variant="h2" mb="10px">
            Centralized Exchanges
          </Typography>
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              control={<Switch disabled />}
              label={
                <Typography variant="body2">Coinweb Marketplace</Typography>
              }
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 0,
              }}
            />
            <Typography variant="h2" mb="10px">
              Application Exchanges
            </Typography>
            <FormControlLabel
              control={<Switch disabled />}
              label={<Typography variant="body2">KuCoin</Typography>}
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 0,
              }}
            />
            <FormControlLabel
              control={<Switch disabled />}
              label={<Typography variant="body2">ECX</Typography>}
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 0,
              }}
            />
            <FormControlLabel
              control={<Switch disabled />}
              label={<Typography variant="body2">Gate.io</Typography>}
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 0,
              }}
            />
            <FormControlLabel
              control={<Switch disabled />}
              label={<Typography variant="body2"> Bitmap</Typography>}
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 0,
              }}
            />
            <FormControlLabel
              control={<Switch disabled />}
              label={<Typography variant="body2">Bitex</Typography>}
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 0,
              }}
            />
          </Box>
        </GradientBox>
      </Grid>

      <Grid item xs={4}>
        <GradientBox sx={{ marginTop: ' 0px', height: '100%' }}>
          <Typography variant="h2" mb="10px">
            Decentralized Exchanges
          </Typography>
          <FormControlLabel
            control={<Switch disabled />}
            label={<Typography variant="body2">Uniswap</Typography>}
            labelPlacement="start"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              ml: 0,
            }}
          />
          <FormControlLabel
            control={<Switch disabled />}
            label={<Typography variant="body2">Wallet Integration</Typography>}
            labelPlacement="start"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              ml: 0,
            }}
          />
        </GradientBox>
      </Grid>
    </Grid>
  );
};
