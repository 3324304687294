import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import iconOk from 'images/success.svg';
import styles from './styles.module.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: '-webkit-fill-available',
  maxWidth: '600px',
  border: '1px solid rgba(153, 161, 189, 0.14)',
  boxShadow: 24,
  color: 'var(--main-white-color)',
  p: '16px',
  backgroundColor: '#1f1d47',
};

type WarningModalProps = {
  warningMessage: string | undefined;
  onClose: (value: boolean) => void;
  open: boolean;
};

const WarningModal = (props: WarningModalProps) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={() => {
        props.onClose(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.admissible}>
            <img src={iconOk} alt="" />
          </div>
          <div className={styles.validity}>
            <div className={styles.validityBlock}>{props.warningMessage}</div>
          </div>
          <div
            className={styles.greenBlock}
            onClick={() => {
              props.onClose(false);
            }}
          >
            <div className={styles.greenText}>
              <p>Close</p>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default WarningModal;
