import { useState } from 'react';
import { noop } from 'lodash-es';
import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Select,
  Typography,
  Divider,
} from '@mui/material';
import { NumericInput } from 'components/NumericInput';

import { GradientBox, SpaceBetweenBox } from '../../components/Containers/Box';
import {
  BpCheckedIconRadio,
  BpIconRadio,
} from '../../components/ControlButtons/Radio';

const feesHandlingTypes = {
  BURN: 'burn',
  LINK: 'link',
};

export const FeesPage = () => {
  const [handlingType, setHandlingType] = useState(feesHandlingTypes.BURN);

  const handlingTypeChange: RadioGroupProps['onChange'] = (ev) => {
    setHandlingType(ev.target.value);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minHeight="100%"
      padding="0px 20px"
    >
      <GradientBox sx={{ marginTop: '0px' }}>
        <Typography variant="h2">Fees</Typography>

        <SpaceBetweenBox sx={{ marginTop: '20px', alignItems: 'center' }}>
          <Typography fontSize="14px">Fees on transfer - Amount</Typography>
          <NumericInput
            disabled
            sx={{ maxWidth: '156px', marginTop: '7px' }}
            onChange={noop}
            placeholder="0"
            containerSx={{ width: 'auto' }}
            dispatch={false}
          />
        </SpaceBetweenBox>
        <Divider sx={{ marginTop: '12px' }} />

        <SpaceBetweenBox sx={{ marginTop: '20px', alignItems: 'center' }}>
          <Typography fontSize="14px">Fees on transfer - Percentage</Typography>
          <NumericInput
            disabled
            sx={{ maxWidth: '156px', marginTop: '7px' }}
            onChange={noop}
            placeholder="0 %"
            containerSx={{ width: 'auto' }}
            dispatch={false}
          />
        </SpaceBetweenBox>
        <Divider sx={{ marginTop: '12px' }} />

        <Box mt={2}>
          <Typography fontSize="14px">How fees are handled</Typography>
          <FormControl>
            <RadioGroup value={handlingType} onChange={handlingTypeChange}>
              <FormControlLabel
                value={feesHandlingTypes.BURN}
                control={
                  <Radio
                    disabled
                    icon={<BpIconRadio />}
                    checkedIcon={<BpCheckedIconRadio />}
                  />
                }
                label={<Typography variant="body2">Burn</Typography>}
              />
              <FormControlLabel
                value={feesHandlingTypes.LINK}
                control={
                  <Radio
                    disabled
                    icon={<BpIconRadio />}
                    checkedIcon={<BpCheckedIconRadio />}
                  />
                }
                label={
                  <Typography variant="body2">
                    Link to staking program
                  </Typography>
                }
              />
              {handlingType === feesHandlingTypes.LINK && (
                <Box ml={4}>
                  <Select disabled defaultValue={1}>
                    <MenuItem value={1}>Staking program #1</MenuItem>
                    <MenuItem value={2}>Staking program #2</MenuItem>
                  </Select>
                </Box>
              )}
            </RadioGroup>
          </FormControl>
        </Box>
        <Typography variant="h4" mt="10px">
          Note: Fees added to token transfers are in addition to network fees
          and L1 embedding fees, both which can fluctuate over time
        </Typography>
      </GradientBox>
    </Box>
  );
};
