import { useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NiceModal from '@ebay/nice-modal-react';
import { CopyButton } from 'components/CopyButton';
import { Owner } from 'types/DAO';
import { OwnersModal } from './OwnersModal';

const rows: Owner[] = [
  { address: '0x0C48dfb3FAaFBCECF21f0D1F4e75E1fE6e731Ad6', name: 'Name 1' },
  { address: '0x0C48dfb3FAaFBCECF21f0D1F4e75E1fE6e731Ad7', name: 'Name 2' },
  { address: '0x0C48dfb3FAaFBCECF21f0D1F4e75E1fE6e731Ad8', name: 'Name 3' },
];

const descendingComparator = (a: any, b: any, orderBy: 'name' | 'address') => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'desc' | 'asc', orderBy: 'name' | 'address') =>
  order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);

const headCells = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'address',
    label: 'Address',
  },
];

export const OwnersTable = () => {
  const [order, setOrder] = useState<'desc' | 'asc'>('asc');
  const [orderBy, setOrderBy] = useState<'name' | 'address'>('name');
  const [owners, setOwners] = useState<Owner[]>(rows);

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: any) => (event: any) => {
    handleRequestSort(event, property);
  };

  const handleEdit = (index: number) => {
    NiceModal.show(OwnersModal, {
      index,
      owners,
      setOwners,
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'right' : 'left'}
                // sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  // direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    '&.Mui-active': {
                      color: '#00e1d4 !important',
                    },
                    '.MuiTableSortLabel-icon': {
                      color: '#00e1d4 !important',
                    },
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}

            <TableCell align="right" />
          </TableRow>
        </TableHead>

        <TableBody>
          {owners.sort(getComparator(order, orderBy)).map((row, index) => (
            <TableRow
              key={row.address}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">
                {row.address}{' '}
                <CopyButton text={row.address} hint="Copy address" />
              </TableCell>
              <TableCell align="right">
                <Tooltip title="Edit owner name">
                  <IconButton onClick={() => handleEdit(index)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
