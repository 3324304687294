import { useState } from 'react';
import { noop } from 'lodash-es';
import {
  Box,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  Divider,
} from '@mui/material';
import { NumericInput } from 'components/NumericInput';
import { Switch } from '../../components/ControlButtons/Switch';
// @ts-ignore
import {
  BpCheckedIconCheck,
  BpIconCheck,
} from '../../components/ControlButtons/Check';
import { SelectTokenization } from '../../components/Input/Select';
import {
  BridgeButton,
  BlueButton,
  BeigeButton,
} from '../../components/ControlButtons/Button';
import styles from './styles.module.scss';
import {
  GradientBox,
  CenteredBox,
  SpaceBetweenBox,
} from '../../components/Containers/Box';
import {
  BpIconRadio,
  BpCheckedIconRadio,
} from '../../components/ControlButtons/Radio';

export const CommonSettingsPage = () => {
  const [tab, setTab] = useState('security');
  const [optimistic, setOptimistic] = useState(true);
  const [access, setAccess] = useState('help');
  const [multiSig, setMultiSig] = useState(true);
  const [shutDown, setShutDown] = useState(true);
  const [freezeSupport, setFreezeSupport] = useState(true);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minHeight="100vh"
      padding="0px 20px"
    >
      <Box className="w-full grid grid-cols-2 gap-5">
        <BridgeButton
          className={tab === 'security' ? styles.activeTabButton : undefined}
          onClick={() => setTab('security')}
        >
          Security
        </BridgeButton>
        <BridgeButton
          className={tab === 'fees' ? styles.activeTabButton : undefined}
          onClick={() => setTab('fees')}
        >
          Fees
        </BridgeButton>
      </Box>

      {tab === 'security' ? (
        <>
          <GradientBox>
            <Typography variant="h2">Bridge Minting</Typography>
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Should the bridge have a security pool?
              </Typography>
              <Switch disabled />
            </SpaceBetweenBox>
            <Divider />
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Optimistic bridge mechanism
              </Typography>
              <Switch
                disabled
                checked={optimistic}
                onChange={(e) => setOptimistic(e.target.checked)}
              />
            </SpaceBetweenBox>
            <Divider />
            {optimistic ? (
              <>
                <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
                  <Typography alignSelf="center" fontSize="14px">
                    Delay
                  </Typography>
                  <div className="flex">
                    <NumericInput
                      disabled
                      sx={{ maxWidth: '156px' }}
                      inputLabelSx={{ marginTop: '-7px' }}
                      onChange={noop}
                      placeholder="5 Mins"
                      dispatch={false}
                    />
                  </div>
                </SpaceBetweenBox>
                <Divider />
                <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
                  <Typography alignSelf="center" fontSize="14px">
                    Required L1 collateral for wrapping/minting
                  </Typography>
                  <div className="flex">
                    <NumericInput
                      disabled
                      inputLabelSx={{ marginTop: '-7px' }}
                      sx={{ maxWidth: '156px' }}
                      onChange={noop}
                      placeholder="5 %"
                      dispatch={false}
                    />
                  </div>
                </SpaceBetweenBox>
                <Divider />
                <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
                  <Typography alignSelf="center" fontSize="14px">
                    Required L2 collateral for wrapping/minting
                  </Typography>
                  <div className="flex">
                    <NumericInput
                      disabled
                      sx={{ maxWidth: '156px' }}
                      inputLabelSx={{ marginTop: '-7px' }}
                      onChange={noop}
                      placeholder="5 %"
                      dispatch={false}
                    />
                  </div>
                </SpaceBetweenBox>
                <Divider />
                <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
                  <Typography alignSelf="center" fontSize="14px">
                    L2 collateral in CWEB
                  </Typography>
                  <Switch disabled />
                </SpaceBetweenBox>
              </>
            ) : null}
          </GradientBox>
          <GradientBox>
            <Typography variant="h2">Detected fraudulent wrapping</Typography>

            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Amount of L1 collateral is given to fisherman?
              </Typography>
              <div className="flex">
                <NumericInput
                  disabled
                  sx={{ maxWidth: '156px' }}
                  inputLabelSx={{ marginTop: '-7px' }}
                  onChange={noop}
                  placeholder="5 %"
                  dispatch={false}
                />
              </div>
            </SpaceBetweenBox>
            <Divider />
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Amount of L2 collateral is given to fisherman?
              </Typography>
              <div className="flex">
                <NumericInput
                  disabled
                  sx={{ maxWidth: '156px' }}
                  inputLabelSx={{ marginTop: '-7px' }}
                  onChange={noop}
                  placeholder="5 %"
                  dispatch={false}
                />
              </div>
            </SpaceBetweenBox>
            <Divider />
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Amount of bridge security pool is given to fisherman?
              </Typography>
              <div className="flex">
                <NumericInput
                  disabled
                  sx={{ maxWidth: '156px' }}
                  inputLabelSx={{ marginTop: '-7px' }}
                  onChange={noop}
                  placeholder="5 %"
                  dispatch={false}
                />
              </div>
            </SpaceBetweenBox>
            <Divider />
            <Box sx={{ alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px" mt="20px">
                Rest of collateral is given to
              </Typography>
              <div style={{ textIndent: '0px', display: 'grid' }}>
                <FormControlLabel
                  value="burned"
                  control={
                    <Checkbox
                      disabled
                      checkedIcon={<BpCheckedIconCheck />}
                      icon={<BpIconCheck />}
                    />
                  }
                  label={
                    <Typography alignSelf="center" fontSize="14px">
                      Burned{' '}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="refer"
                  control={
                    <Checkbox
                      disabled
                      checkedIcon={<BpCheckedIconCheck />}
                      icon={<BpIconCheck />}
                    />
                  }
                  label={
                    <Typography alignSelf="center" fontSize="14px">
                      Refer to staking program / Bridge security pool
                    </Typography>
                  }
                />
              </div>
            </Box>
            <Divider />
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Multi-sig
              </Typography>
              <Switch
                disabled
                checked={multiSig}
                onChange={(e) => setMultiSig(e.target.checked)}
              />
            </SpaceBetweenBox>
            <Divider />
            {multiSig ? (
              <Box sx={{ alignItems: 'center' }}>
                <Typography alignSelf="center" fontSize="14px" mt="20px">
                  Who should run and have access to signing keys?
                </Typography>
                <RadioGroup
                  value={access}
                  onChange={(e) => setAccess(e.target.value)}
                >
                  <FormControlLabel
                    sx={{ color: '#fff !important' }}
                    value="help"
                    control={
                      <Radio
                        disabled
                        icon={<BpIconRadio />}
                        checkedIcon={<BpCheckedIconRadio />}
                      />
                    }
                    label="I need help with this"
                  />
                  <FormControlLabel
                    value="myself"
                    control={
                      <Radio
                        disabled
                        icon={<BpIconRadio />}
                        checkedIcon={<BpCheckedIconRadio />}
                      />
                    }
                    label="I will do it myself"
                  />
                </RadioGroup>
              </Box>
            ) : null}
          </GradientBox>

          <GradientBox>
            <Typography variant="h2">Bridge Shutdown Security</Typography>
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Bridge shutdown security pool
              </Typography>
              <Switch
                disabled
                checked={shutDown}
                onChange={(e) => setShutDown(e.target.checked)}
              />
            </SpaceBetweenBox>
            <Divider />
            {shutDown ? (
              <Box sx={{ alignItems: 'center' }}>
                <Typography alignSelf="center" fontSize="14px" mt="20px">
                  Who should run and have access to signing keys?
                </Typography>
                <RadioGroup
                  value={access}
                  onChange={(e) => setAccess(e.target.value)}
                >
                  <FormControlLabel
                    value="help"
                    control={
                      <Radio
                        disabled
                        icon={<BpIconRadio />}
                        checkedIcon={<BpCheckedIconRadio />}
                      />
                    }
                    label="I need help with this"
                  />
                  <FormControlLabel
                    value="myself"
                    control={
                      <Radio
                        disabled
                        icon={<BpIconRadio />}
                        checkedIcon={<BpCheckedIconRadio />}
                      />
                    }
                    label="I will do it myself"
                  />
                </RadioGroup>
              </Box>
            ) : null}
          </GradientBox>
          <GradientBox>
            <Typography variant="h2">Bridge Flow Control</Typography>

            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Max number of tokens wrapped
              </Typography>
              <div>
                <NumericInput
                  disabled
                  sx={{ maxWidth: '156px' }}
                  inputLabelSx={{ marginTop: '-7px' }}
                  onChange={noop}
                  placeholder="5"
                  dispatch={false}
                />
              </div>
            </SpaceBetweenBox>
            <Divider />
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Tokens per
              </Typography>
              <div>
                <SelectTokenization
                  disabled
                  className={styles.selectCommon}
                  value="day"
                >
                  <MenuItem value="hour">hour</MenuItem>
                  <MenuItem value="day">day</MenuItem>
                </SelectTokenization>
              </div>
            </SpaceBetweenBox>
          </GradientBox>
          <GradientBox>
            <Typography variant="h2">Bridge Freeze Support</Typography>
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Bridge freeze support
              </Typography>
              <Switch
                disabled
                checked={freezeSupport}
                onChange={(e) => setFreezeSupport(e.target.checked)}
              />
            </SpaceBetweenBox>
            <Divider />
            {freezeSupport ? (
              <Box sx={{ alignItems: 'center' }}>
                <Typography alignSelf="center" fontSize="14px" mt="20px">
                  Who controls freezing of bridge?
                </Typography>
                <RadioGroup>
                  <FormControlLabel
                    value=""
                    control={
                      <Radio
                        icon={<BpIconRadio />}
                        checkedIcon={<BpCheckedIconRadio />}
                        disabled
                      />
                    }
                    label="Link to DAO"
                  />
                  <FormControlLabel
                    value="myself"
                    control={
                      <Radio
                        icon={<BpIconRadio />}
                        checkedIcon={<BpCheckedIconRadio />}
                        disabled
                      />
                    }
                    label="I need help with monitoring this"
                  />
                </RadioGroup>
              </Box>
            ) : null}
          </GradientBox>

          <GradientBox>
            <Typography variant="h2">Bridge Blacklist Support</Typography>
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Bridge blacklist support
              </Typography>
              <Switch
                disabled
                checked={freezeSupport}
                onChange={(e) => setFreezeSupport(e.target.checked)}
              />
            </SpaceBetweenBox>
            <Divider />
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Required L1 collateral for updating blacklist
              </Typography>
              <div>
                <NumericInput
                  disabled
                  sx={{ maxWidth: '156px' }}
                  inputLabelSx={{ marginTop: '-7px' }}
                  onChange={noop}
                  placeholder="5"
                  dispatch={false}
                />
              </div>
            </SpaceBetweenBox>
            <Divider />
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Required L2 collateral for updating blacklist
              </Typography>
              <div>
                <NumericInput
                  disabled
                  sx={{ maxWidth: '156px' }}
                  inputLabelSx={{ marginTop: '-7px' }}
                  onChange={noop}
                  placeholder="5"
                  dispatch={false}
                />
              </div>
            </SpaceBetweenBox>
          </GradientBox>
          <CenteredBox>
            <BeigeButton
              disabled
              sx={{ minWidth: '150px', marginRight: '10px' }}
              variant="outlined"
            >
              Edit
            </BeigeButton>
            <BlueButton
              disabled
              sx={{ minWidth: '150px', marginLeft: '10px' }}
              variant="outlined"
            >
              Freeze
            </BlueButton>
          </CenteredBox>
        </>
      ) : (
        <>
          <GradientBox>
            <Typography variant="h2">Bridge Fees</Typography>
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Fees On Wrapping
              </Typography>
              <div className="flex">
                <NumericInput
                  disabled
                  sx={{ maxWidth: '156px' }}
                  inputLabelSx={{ marginTop: '-7px' }}
                  onChange={noop}
                  placeholder="5 %"
                  dispatch={false}
                />
              </div>
            </SpaceBetweenBox>
            <Divider />
            <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
              <Typography alignSelf="center" fontSize="14px">
                Fees On Wrapping(Burning)
              </Typography>
              <div className="flex">
                <NumericInput
                  disabled
                  sx={{ maxWidth: '156px' }}
                  inputLabelSx={{ marginTop: '-7px' }}
                  onChange={noop}
                  placeholder="5 %"
                  dispatch={false}
                />
              </div>
            </SpaceBetweenBox>
          </GradientBox>
          <CenteredBox>
            <BeigeButton disabled sx={{ minWidth: '150px' }} variant="outlined">
              Edit
            </BeigeButton>
          </CenteredBox>
        </>
      )}
    </Box>
  );
};
