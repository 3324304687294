import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  complianceSettingsInfoText,
  complianceLicensedTokensaleInfoText,
} from 'texts';
import { BlueButton } from 'modules/tokenization/components/ControlButtons/Button';
import { InfoIcon } from '../../components/Icons/Icons';
import { GradientBox } from '../../components/Containers/Box';
import {
  BpCheckedIconCheck,
  BpIconCheck,
} from '../../components/ControlButtons/Check';
import { InputTokenization } from '../../components/Input/Input';

export const CompliancePage = () => {
  return (
    <>
      <Box
        display="grid"
        sx={{ gridTemplateColumns: '1fr 1fr', gridColumnGap: '20px' }}
      >
        <GradientBox>
          <Box display="flex" flexDirection="column" width="100%">
            <Box display="flex" alignItems="center">
              <Typography variant="h2">
                Compliance settings{' '}
                <InfoIcon title={complianceSettingsInfoText} />
              </Typography>
            </Box>

            <Box display="flex" justifyContent="flex-start">
              <FormGroup sx={{ flex: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={<BpCheckedIconCheck />}
                      icon={<BpIconCheck />}
                      disabled
                      defaultChecked
                      variant="vibrant"
                    />
                  }
                  label={
                    <Typography className="font-medium" variant="body2">
                      Security token
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>

            <Box display="flex" justifyContent="flex-start" mt={2}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2">
                  Liquidity pool management type
                </Typography>

                <FormControl fullWidth>
                  <Select
                    value={1}
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    <MenuItem value={1}>Licensed exchange</MenuItem>
                    <MenuItem value={2}>On-chain</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box mt={2}>
              <Typography variant="body2">Max amount</Typography>
              <InputTokenization
                disabled
                placeholder="Insert Amount"
                variant="outlined"
                sx={{ width: '100%' }}
              />
            </Box>
          </Box>
        </GradientBox>
        <GradientBox>
          <Box display="flex" flexDirection="column">
            <Typography variant="h2" sx={{ marginBottom: '20px' }}>
              Licensed tokensale{' '}
              <InfoIcon title={complianceLicensedTokensaleInfoText} />
            </Typography>

            <Typography variant="body2" fontSize="14px">
              Coinweb holds multiple licenses in popular jurisdictions and is
              constantly acquiring additional licenses in a variety of
              countries. Our compliance team can assist in finding the optimal
              solution for each use-case and project.
            </Typography>
          </Box>
        </GradientBox>
      </Box>
      <Box width="100% " display="flex" mt="20px">
        <BlueButton disabled variant="outlined" sx={{ margin: 'auto' }}>
          Contact Sales
        </BlueButton>
      </Box>
    </>
  );
};
