import { useEffect, useCallback } from 'react';
import {
  subscribe_to_notifications as subscribeToNotifications,
  get_user_operations_history_per_token as getUserOperationsHistoryPerToken,
} from '@coinweb/cweb-wallet-library';
import { useSelector, useDispatch } from 'react-redux';
import {
  fieldTokenNotifications,
  fieldModalMessage,
} from 'redux/reducers/tokenReducer';
import { useCurrentToken } from 'hooks/useCurrentToken';
import { NotificationType } from 'utils/notifications';
import { OperationType } from 'types/operationType';
import { usePrevious } from './usePrevious';
import { useWalletFromParams } from './useWalletFromParams';

// eslint-disable-next-line
export const getNotificationMessage = ({
  operationType,
  displayedTokenName,
}: {
  operationType: OperationType;
  displayedTokenName: string;
}) => {
  if (operationType === OperationType.CREATE_CUSTOM_TOKEN) {
    return `${displayedTokenName} created. `;
  }
  if (operationType === OperationType.CUSTOM_TOKEN_UPDATED)
    return `${displayedTokenName} updated.`;

  if (operationType === OperationType.CUSTOM_TOKEN_LIQUIDITY_UPDATED)
    return `Liquidity for ${displayedTokenName} updated.`;

  return '';
};

export const useTokenNotificationsSubscription = () => {
  const [token] = useCurrentToken();
  const [wallet] = useWalletFromParams();
  const previousToken = usePrevious(token);
  const dispatch = useDispatch();

  const { tokenNotifications, tokenHashId, tokenName, tokenSymbol } =
    useSelector((state: any) => state.token);

  const handleNotifications = useCallback(() => {
    if (wallet && token) {
      const subscription = subscribeToNotifications(wallet, undefined, {
        keepAlive: 10_000,
        retryAttempts: 100,
        shouldRetry: (error) => {
          // eslint-disable-next-line no-console
          console.error('WS error at notification subscription:', error);
          return true;
        },
        onNonLazyError: (errorOrCloseEvent) => {
          // eslint-disable-next-line no-console
          console.error('WS error or close event:', errorOrCloseEvent);
        },
        on: {
          error: (received) => {
            // eslint-disable-next-line no-console
            console.error('WS `error`:', received);
          },
          closed: (received) => {
            // eslint-disable-next-line no-console
            console.warn('WS `closed`:', received);
          },
          message: () => {},
        },
      });

      if (previousToken?.hashId !== token.hashId) {
        dispatch(fieldTokenNotifications([]));
        getUserOperationsHistoryPerToken(wallet, token.hashId, null).then(
          (response) => {
            if (response.operations.length !== tokenNotifications?.length) {
              dispatch(
                fieldTokenNotifications(
                  response.operations.reduce(
                    (acc: NotificationType[], item) => {
                      const [[key, value]] = Object.entries(item);
                      if (
                        (key as OperationType) ===
                          OperationType.CREATE_CUSTOM_TOKEN ||
                        (key as OperationType) ===
                          OperationType.CUSTOM_TOKEN_LIQUIDITY_UPDATED ||
                        (key as OperationType) ===
                          OperationType.CUSTOM_TOKEN_UPDATED
                      ) {
                        acc.push({
                          id: value.txid,
                          message: getNotificationMessage({
                            operationType: key as OperationType,
                            displayedTokenName:
                              token.tokenName ?? tokenName ?? tokenSymbol,
                          }),
                          read: false,
                        });
                      }
                      if (
                        (key as OperationType) ===
                        OperationType.CUSTOM_TOKEN_FAILURE
                      ) {
                        acc.push({
                          id: value.txid,
                          message: `Token Failure for ${value.command} command.`,
                          message2: value.message,
                          read: false,
                        });
                      }
                      return acc;
                    },
                    [],
                  ),
                ),
              );
            }
          },
        );
      }
      // NOTE: call to `subscribe` is needed, even if supplied lambda
      // will do nothing
      subscription.subscribe((x: any) => {
        // eslint-disable-next-line no-console
        console.log('New update:', x);
        const { body } = x.notifications.writeOp.op.issuedClaim.content;
        if (
          body &&
          body?.details &&
          body?.details?.CustomTokenSuccess &&
          body?.details?.CustomTokenSuccess?.token_id &&
          body?.details?.CustomTokenSuccess?.command_name
        ) {
          if (
            body?.details?.CustomTokenSuccess.token_id === tokenHashId &&
            body?.details?.CustomTokenSuccess?.command_name === 'UpdateToken'
          ) {
            dispatch(
              fieldModalMessage({
                text: `${tokenName} is updated! Please be advised that data retrieval is in progress and may take a few minutes.`,
                type: OperationType.CUSTOM_TOKEN_UPDATED,
                tokenName: tokenName ?? '',
              }),
            );
          }
        }
      });
    }
  }, [
    tokenHashId,
    tokenName,
    tokenSymbol,
    tokenNotifications,
    wallet,
    token,
    dispatch,
    previousToken?.hashId,
  ]);

  useEffect(() => {
    if ('Notification' in window && Notification.permission === 'granted') {
      handleNotifications();
    } else if (
      'Notification' in window &&
      Notification.permission !== 'denied'
    ) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          handleNotifications();
        }
      });
    }

    return () => {};
  }, [token, handleNotifications]);
};
