import { Box } from '@mui/material';
import { mapValues } from 'lodash-es';
import BigNumber from 'bignumber.js';
import {
  ExtraFields,
  TokenUiCommand,
  UiCommand,
  Polynomial,
} from '@coinweb/cweb-wallet-library';
import intToHexString from 'utils/intToHexString';
import calculateFixedBCparameter from 'utils/calculateFixedBCparameter';
import { priceTypes, Quadratic } from './liquidityTypes';

const MAX_AMOUNT_OF_CWEB = BigNumber(7680000000);

BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_HALF_DOWN });

export const displayAbuyValue = (priceType: priceTypes) => {
  if (priceType === priceTypes.FIXED || priceType === priceTypes.FIXED_NAKED)
    return '0';
  if (priceType === priceTypes.SMART || priceType === priceTypes.SMART_NAKED)
    return (
      <>
        -token<span className="sub">per cweb acceleration</span>/2
      </>
    );
  if (priceType === priceTypes.QUADRATIC || priceType === priceTypes.HYPERBOLIC)
    return (
      <>
        a<span className="sub">buy</span>
      </>
    );
  if (priceType === priceTypes.CUSTOM) return '0';
  return '';
};

export const displayBbuyValue = (priceType: priceTypes) => {
  if (priceType === priceTypes.FIXED || priceType === priceTypes.FIXED_NAKED)
    return '(fee -1)/price';
  if (priceType === priceTypes.SMART || priceType === priceTypes.SMART_NAKED)
    return (
      <>
        -1 / (price/(1 - fee) - 2 <span className="super_dot">.</span> a
        <span className="sub">buy</span>
        <span className="super_dot">.</span>x<span className="sub">0</span>)
      </>
    );
  if (priceType === priceTypes.QUADRATIC || priceType === priceTypes.HYPERBOLIC)
    return (
      <>
        b<span className="sub">buy</span>
      </>
    );
  if (priceType === priceTypes.CUSTOM) return '0';
  return '';
};

export const displayCbuyValue = (priceType: priceTypes) => {
  if (priceType === priceTypes.FIXED || priceType === priceTypes.FIXED_NAKED)
    return (
      <>
        -x<span className="sub">0</span> <span className="super_dot">.</span>b
        <span className="sub">buy</span>
      </>
    );
  if (
    priceType === priceTypes.SMART ||
    priceType === priceTypes.SMART_NAKED ||
    priceType === priceTypes.HYPERBOLIC
  )
    return (
      <>
        -a<span className="sub">buy</span> <span className="super_dot">.</span>x
        <span className="sub">0</span>
        <span className="super">2</span> - b<span className="sub">buy</span>
        <span className="super_dot">.</span>x<span className="sub">0</span>
      </>
    );
  if (priceType === priceTypes.QUADRATIC)
    return (
      <>
        (a<span className="sub">sell</span>-a<span className="sub">buy</span>).
        x<span className="sub">0</span>
        <span className="super">2</span> + (b<span className="sub">sell</span>-b
        <span className="sub">buy</span>) <span className="super_dot">.</span> x
        <span className="sub">0</span>+ c<span className="sub">sell</span>
      </>
    );
  if (priceType === priceTypes.CUSTOM) return '0';
  return '';
};

export const displayAsellValue = (priceType: priceTypes) => {
  if (priceType === priceTypes.FIXED || priceType === priceTypes.FIXED_NAKED)
    return '0';
  if (priceType === priceTypes.SMART || priceType === priceTypes.SMART_NAKED)
    return (
      <>
        -token<span className="sub">per cweb acceleration</span>/2
      </>
    );
  if (priceType === priceTypes.QUADRATIC || priceType === priceTypes.HYPERBOLIC)
    return (
      <>
        a<span className="sub">sell</span>
      </>
    );
  if (priceType === priceTypes.CUSTOM) return '0';
  return '';
};

export const displayBsellValue = (priceType: priceTypes) => {
  if (priceType === priceTypes.FIXED || priceType === priceTypes.FIXED_NAKED)
    return (
      <>
        1/(price <span className="super_dot">.</span> (fee - 1 ))
      </>
    );
  if (priceType === priceTypes.SMART || priceType === priceTypes.SMART_NAKED)
    return (
      <>
        -1 / (price<span className="super_dot">.</span>(1 - fee) - 2{' '}
        <span className="super_dot">.</span> a<span className="sub">sell</span>
        <span className="super_dot">.</span>x<span className="sub">0</span>)
      </>
    );

  if (priceType === priceTypes.QUADRATIC || priceType === priceTypes.HYPERBOLIC)
    return (
      <>
        b<span className="sub">sell</span>
      </>
    );
  return '';
};

export const displayCsellValue = (priceType: priceTypes) => {
  if (priceType === priceTypes.FIXED || priceType === priceTypes.SMART)
    return 'premint';
  if (
    priceType === priceTypes.FIXED_NAKED ||
    priceType === priceTypes.SMART_NAKED
  )
    return (
      <>
        premint - token<span className="sub">naked</span>
      </>
    );
  if (priceType === priceTypes.QUADRATIC)
    return (
      <>
        c<span className="sub">cell</span>
      </>
    );
  if (priceType === priceTypes.HYPERBOLIC)
    return (
      <>
        -a<span className="sub">sell</span>. x<span className="sub">0</span>
        <span className="super">2</span> - b<span className="sub">sell</span>{' '}
        <span className="super_dot"> . </span> x <span className="sub">0</span>
      </>
    );
  return '';
};

export const displayDomainValue = (priceType: priceTypes) => {
  if (priceType === priceTypes.FIXED || priceType === priceTypes.FIXED_NAKED)
    return 'MAX_AMOUNT_OF_CWEB';
  if (
    priceType === priceTypes.SMART ||
    priceType === priceTypes.SMART_NAKED ||
    priceType === priceTypes.QUADRATIC ||
    priceType === priceTypes.HYPERBOLIC
  )
    return (
      <>
        min(domain<span className="sub">buy</span>, domain
        <span className="sub">sell</span>, MAX_AMOUNT_OF_CWEB)
      </>
    );
  return '';
};

export const displayX0Value = (priceType: priceTypes) => {
  if (priceType === priceTypes.FIXED || priceType === priceTypes.FIXED_NAKED)
    return (
      <>
        -c<span className="sub">sell</span> / b<span className="sub">sell</span>
      </>
    );
  if (priceType === priceTypes.SMART || priceType === priceTypes.SMART_NAKED)
    return (
      <Box>
        <Box>
          if a<span className="sub">sell</span> = 0 then {`  `}{' '}
          &nbsp;&nbsp;&nbsp;&nbsp; c<span className="sub">sell</span>{' '}
          <span className="super_dot">.</span> p{' '}
          <span className="super_dot">.</span> ( 1 - fee)
        </Box>
        <Box>
          <Box>
            {' '}
            if a<span className="sub">sell</span> {'> '} 0 then{' '}
          </Box>
          (- 1 / ( price <span className="super_dot">.</span> (1 - fee)) +
          &#8730; ( 1 / ( price <span className="super_dot">.</span> (1 - fee)){' '}
          <span className="super">2</span> + 4{' '}
          <span className="super_dot">.</span>a<span className="sub">sell</span>{' '}
          <span className="super_dot">.</span> c
          <span className="sub">sell</span>) / 2{' '}
          <span className="super_dot">.</span> a
          <span className="sub">sell</span>
        </Box>
        <Box>
          <Box>else </Box>
          (- 1 / ( price <span className="super_dot">.</span> (1 - fee)) -
          &#8730; ( 1 / ( price <span className="super_dot">.</span> (1 - fee)){' '}
          <span className="super">2</span> + 4{' '}
          <span className="super_dot">.</span>a<span className="sub">sell</span>{' '}
          <span className="super_dot">.</span> c
          <span className="sub">sell</span>) / 2{' '}
          <span className="super_dot">.</span> a
          <span className="sub">sell</span>
        </Box>
      </Box>
    );
  if (priceType === priceTypes.QUADRATIC)
    return (
      <Box>
        <Box>
          if a<span className="sub">sell</span> = 0 then {`  `}{' '}
          &nbsp;&nbsp;&nbsp;&nbsp; -c
          <span className="sub">sell</span> / b<span className="sub">sell</span>
        </Box>
        <Box>
          <Box>else </Box>
          {`  `} ( -b<span className="sub">sell</span> - &#8730; (b
          <span className="sub">sell</span>
          <span className="super">2</span> - 4{' '}
          <span className="super_dot">.</span> a
          <span className="sub">sell</span>{' '}
          <span className="super_dot"> . </span>c
          <span className="sub">sell</span> )) / ( 2 a
          <span className="sub">sell</span>)
        </Box>
      </Box>
    );
  if (priceType === priceTypes.HYPERBOLIC)
    return (
      <>
        x<span className="sub">0</span>
      </>
    );
  return '';
};

export function findQuadraticDomain(
  aBuy: BigNumber,
  bBuy: BigNumber,
  aSell: BigNumber,
  bSell: BigNumber,
  decimals: BigNumber,
) {
  let domainBuy = BigNumber(0);
  if (aBuy.isGreaterThan(domainBuy))
    domainBuy = bBuy.dividedBy(aBuy.multipliedBy(2)).negated();
  else domainBuy = MAX_AMOUNT_OF_CWEB;
  let domainSell = BigNumber(0);
  if (aSell.isGreaterThan(domainSell))
    domainSell = bSell.dividedBy(aSell.multipliedBy(2)).negated();
  else domainSell = MAX_AMOUNT_OF_CWEB;

  return BigNumber.minimum(MAX_AMOUNT_OF_CWEB, domainBuy, domainSell);
}

export function createBondingCurveJson(
  hash: string,
  quadratic: Quadratic,
  extraFields: ExtraFields,
  decimals: BigNumber,
  priceType: priceTypes,
) {
  const buyPoly = [
    quadratic.buy.c
      .multipliedBy(BigNumber(10).exponentiatedBy(decimals))
      .toFixed(),
    quadratic.buy.b.toFixed(),
    quadratic.buy.a
      .dividedBy(BigNumber(10).exponentiatedBy(decimals))
      .toFixed(),
  ] as Polynomial;

  const sellPoly = [
    quadratic.sell.c
      .multipliedBy(BigNumber(10).exponentiatedBy(decimals))
      .toFixed(),
    quadratic.sell.b.toFixed(),
    quadratic.sell.a
      .dividedBy(BigNumber(10).exponentiatedBy(decimals))
      .toFixed(),
  ] as Polynomial;

  const initialCweb = BigNumber(quadratic.x0).multipliedBy(
    BigNumber(10).exponentiatedBy(decimals),
  );

  const res = {
    TokenV1: {
      UpdateBondingCurveUi: {
        token: hash,
        curve_buy: {
          Polynomial: {
            poly:
              priceType === priceTypes.FIXED ||
              priceType === priceTypes.FIXED_NAKED
                ? calculateFixedBCparameter(initialCweb, buyPoly)
                : buyPoly,
          },
        },
        curve_sell: {
          Polynomial: {
            poly:
              priceType === priceTypes.FIXED ||
              priceType === priceTypes.FIXED_NAKED
                ? calculateFixedBCparameter(initialCweb, sellPoly)
                : sellPoly,
          },
        },
        domain: BigInt(
          intToHexString(
            priceType === priceTypes.FIXED ||
              priceType === priceTypes.FIXED_NAKED
              ? MAX_AMOUNT_OF_CWEB.multipliedBy(
                  BigNumber(10).exponentiatedBy(18),
                )
              : findQuadraticDomain(
                  quadratic.buy.a,
                  quadratic.buy.b,
                  quadratic.sell.a,
                  quadratic.sell.b,
                  decimals,
                ).multipliedBy(BigNumber(10).exponentiatedBy(decimals)),
            64,
          ),
        ),
        extra_fields: extraFields,
        initial_cweb: BigInt(initialCweb.toFixed()),
        memo: null,
        rate_limiter_params: null,
      },
    } as TokenUiCommand,
  } as UiCommand;

  return JSON.stringify(res);
}

export function handleQuadraticCheck(quadratic: Quadratic) {
  if (
    quadratic.buy.a.isNaN() ||
    !quadratic.buy.a.isFinite() ||
    quadratic.buy.b.isNaN() ||
    !quadratic.buy.b.isFinite() ||
    quadratic.buy.c.isNaN() ||
    !quadratic.buy.c.isFinite() ||
    quadratic.sell.a.isNaN() ||
    !quadratic.sell.a.isFinite() ||
    quadratic.sell.b.isNaN() ||
    !quadratic.sell.b.isFinite() ||
    quadratic.sell.b.isNaN() ||
    !quadratic.sell.b.isFinite() ||
    quadratic.sell.c.isNaN() ||
    !quadratic.sell.c.isFinite() ||
    quadratic.domain.isNaN() ||
    !quadratic.domain.isFinite() ||
    quadratic.kMax?.isNaN() ||
    !quadratic.kMax?.isFinite() ||
    quadratic.x0.isNaN() ||
    !quadratic.x0.isFinite()
  )
    return false;

  return true;
}

export function createBondingCurveCommand(
  hash: string,
  quadratic: Quadratic,
  extraFields: ExtraFields,
  decimals: BigNumber,
  priceType: priceTypes,
) {
  const buyPoly = [
    quadratic.buy.c
      .multipliedBy(BigNumber(10).exponentiatedBy(decimals))
      .toFixed(),
    quadratic.buy.b.toFixed(),
    quadratic.buy.a
      .dividedBy(BigNumber(10).exponentiatedBy(decimals))
      .toFixed(),
  ] as Polynomial;

  const sellPoly = [
    quadratic.sell.c
      .multipliedBy(BigNumber(10).exponentiatedBy(decimals))
      .toFixed(),
    quadratic.sell.b.toFixed(),
    quadratic.sell.a
      .dividedBy(BigNumber(10).exponentiatedBy(decimals))
      .toFixed(),
  ];

  const initialCweb = BigNumber(quadratic.x0).multipliedBy(
    BigNumber(10).exponentiatedBy(decimals),
  );

  const res = {
    UpdateBondingCurveUi: {
      token: hash,
      curve_buy: {
        Polynomial: {
          poly:
            priceType === priceTypes.FIXED ||
            priceType === priceTypes.FIXED_NAKED
              ? calculateFixedBCparameter(initialCweb, buyPoly)
              : buyPoly,
        },
      },
      curve_sell: {
        Polynomial: {
          poly:
            priceType === priceTypes.FIXED ||
            priceType === priceTypes.FIXED_NAKED
              ? calculateFixedBCparameter(initialCweb, sellPoly)
              : sellPoly,
        },
      },
      domain: BigInt(
        intToHexString(
          priceType === priceTypes.FIXED || priceType === priceTypes.FIXED_NAKED
            ? MAX_AMOUNT_OF_CWEB.multipliedBy(
                BigNumber(10).exponentiatedBy(decimals),
              )
            : findQuadraticDomain(
                quadratic.buy.a,
                quadratic.buy.b,
                quadratic.sell.a,
                quadratic.sell.b,
                decimals,
              ).multipliedBy(BigNumber(10).exponentiatedBy(decimals)),
          64,
        ),
      ),
      extra_fields: extraFields,
      initial_cweb: BigInt(initialCweb.toFixed()),
      memo: null,
      rate_limiter_params: null,
    },
  };
  return res as TokenUiCommand;
}

export function findFIXEDX0(
  price: BigNumber,
  fee: BigNumber,
  premint: BigNumber,
) {
  return BigNumber(
    premint
      .negated()
      .multipliedBy(price.multipliedBy(fee.dividedBy(100).minus(1)))
      .toFixed(0),
  );
}
export function findFIXEDBuyB(price: BigNumber, fee: BigNumber) {
  return fee.dividedBy(100).minus(1).dividedBy(price);
}
export function findFIXEDBuyC(x0: BigNumber, bBuy: BigNumber) {
  return x0.negated().multipliedBy(bBuy);
}

export function findSellB(price: BigNumber, fee: BigNumber) {
  return BigNumber(1).dividedBy(
    price.multipliedBy(fee.dividedBy(100).minus(1)),
  );
}

function findPBuy(price: BigNumber, fee: BigNumber) {
  return price.dividedBy(BigNumber(1).minus(fee.dividedBy(100)));
}
function findPSell(price: BigNumber, fee: BigNumber) {
  return price.multipliedBy(BigNumber(1).minus(fee.dividedBy(100)));
}

export function findSMARTX0(
  aSell: BigNumber,
  cSell: BigNumber,
  price: BigNumber,
  fee: BigNumber,
) {
  const p = findPSell(price, fee);

  if (aSell.eq(0)) {
    return BigNumber(cSell.multipliedBy(p).toFixed(0));
  }
  if (aSell.isGreaterThan(0)) {
    return BigNumber(
      BigNumber(-1)
        .dividedBy(p)
        .plus(
          BigNumber(1)
            .dividedBy(p.exponentiatedBy(2))
            .plus(aSell.multipliedBy(BigNumber(4)).multipliedBy(cSell))
            .sqrt(),
        )
        .dividedBy(aSell.multipliedBy(2))
        .toFixed(0),
    );
  }
  return BigNumber(
    BigNumber(-1)
      .dividedBy(p)
      .minus(
        BigNumber(1)
          .dividedBy(p.exponentiatedBy(2))
          .plus(aSell.multipliedBy(BigNumber(4)).multipliedBy(cSell))
          .sqrt(),
      )
      .dividedBy(aSell.multipliedBy(2))
      .toFixed(0),
  );
}

export function findSMARTA(k: BigNumber) {
  return k.dividedBy(-2);
}
export function findSMARTBuyB(
  price: BigNumber,
  fee: BigNumber,
  x0: BigNumber,
  aBuy: BigNumber,
) {
  return BigNumber(-1).dividedBy(
    findPBuy(price, fee).minus(aBuy.multipliedBy(2).multipliedBy(x0)),
  );
}
export function findSMARTSellB(
  price: BigNumber,
  fee: BigNumber,
  x0: BigNumber,
  aSell: BigNumber,
) {
  return BigNumber(-1).dividedBy(
    findPSell(price, fee).minus(aSell.multipliedBy(2).multipliedBy(x0)),
  );
}
export function findSMARTBuyC(aBuy: BigNumber, x0: BigNumber, bBuy: BigNumber) {
  return aBuy
    .negated()
    .multipliedBy(x0.exponentiatedBy(2))
    .minus(bBuy.multipliedBy(x0));
}
export function findKMax(cSell: BigNumber, price: BigNumber, fee: BigNumber) {
  return BigNumber(1).dividedBy(
    findPSell(price, fee)
      .exponentiatedBy(2)
      .multipliedBy(2)
      .multipliedBy(cSell),
  );
}
export function findQuadraticBuyC(
  aBuy: BigNumber,
  aSell: BigNumber,
  bBuy: BigNumber,
  bSell: BigNumber,
  cSell: BigNumber,
  x0: BigNumber,
) {
  return aSell
    .minus(aBuy)
    .multipliedBy(x0.exponentiatedBy(2))
    .plus(bSell.minus(bBuy).multipliedBy(x0))
    .plus(cSell);
}
export function findQuadraticX0(
  aSell: BigNumber,
  bSell: BigNumber,
  cSell: BigNumber,
) {
  if (aSell.eq(0))
    return BigNumber(cSell.negated().dividedBy(bSell).toFixed(0));

  return BigNumber(
    bSell
      .negated()
      .minus(
        bSell
          .exponentiatedBy(2)
          .minus(aSell.multipliedBy(4).multipliedBy(cSell))
          .sqrt(),
      )
      .dividedBy(aSell.multipliedBy(2))
      .toFixed(0),
  );
}

export function handlePriceMinimalUnits(price: BigNumber, decimals: BigNumber) {
  return price.dividedBy(BigNumber(10).exponentiatedBy(decimals));
}

export const convertValuesToInt = <T,>(obj: { [key: string]: BigNumber }): T =>
  mapValues(obj, (value: BigNumber) => value.toNumber());
