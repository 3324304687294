import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

export const SelectTokenization = styled(Select)(() => ({
  '&.MuiInputBase-input': {
    color: '#adadd0 !important',
  },
  '&.MuiInputBase-root': {
    color: '#adadd0 !important',
  },
}));

// export const SelectNetwork = styled(Select)(() => ({
//   marginRight: '16px',
//   width: '9rem',
//   height: '40px',
//   borderRadius: '6px',
//   backgroundColor: '#3778ff !important',
//   '& .MuiSelect-select': {
//     color: 'white',
//     fontWeight: 500,
//   },

//   '& .MuiInput-input': {
//     textAlign: 'center',
//   },
//   '& .MuiSelect-icon': {
//     paddingRight: '5px',
//   },
// }));
