import Tooltip from '@mui/material/Tooltip';
import Info from 'images/icons/icon-information.svg';

type InfoIconProps = {
  title: string;
};

export const InfoIcon = (props: InfoIconProps) => (
  <Tooltip
    title={props.title}
    placement="top"
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: '#fff',
          borderRadius: '2px',
          boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.2)',
          padding: ' 8px 15px',
          color: '#000',
        },
      },
      arrow: {
        sx: {
          color: '#fff',
          boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.2)',
        },
      },
    }}
  >
    <img src={Info} width="14px" alt="info_icon" className="ml-2.5" />
  </Tooltip>
);
