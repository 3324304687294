export const routePaths = {
  ADMIN: 'admin',
  LOGOUT: 'logout',
  TOKENIZATION: {
    LOGIN: '/',
    ROOT: '/:pubKey/*',
    HOME: '',
    ANALYTICS: 'analytics',
    BASIC_ISSUANCE: {
      FUNGIBLE: 'fungible',
      NONFUNGIBLE: 'nonfungible',
      MULTI: 'multi',
      ROOT: 'issuance/*',
    },
    BATCH_SEND: 'batch-send',
    COMPLIANCE: 'compliance',
    EXCHANGE: 'exchange',
    FEES: 'fees',
    BLACKLIST: 'blacklist',
    WHITELIST: 'whitelist',
    LIQUIDITY: 'liquidity',
    NOTIFICATIONS: 'notifications',
    STAKING: 'staking',
    TRANSFERS_ROOT: 'transfers/*',
    BRIDGES: {
      COMMON_SETTINGS: 'common-settings',
      BNB: 'bnb',
      ELROND: 'elrond',
      POLYGON: 'polygon',
      ETHEREUM: 'ethereum',
      EVM: 'evm',
      ROOT: 'bridges/*',
    },
  },

  DAO: {
    ADDRESS_BOOK: 'address-book',
    DAO_ROOT: '/dao/*',
    SETTINGS: 'settings',
    TRANSACTIONS: 'transactions',
    QUEUE: 'queue',
    HISTORY: 'history',
    DETAILS: 'details',
    OWNERS: 'owners',
    SPENDING_LIMIT: 'limit',
  },
};
