import { NumericFormat } from 'react-number-format';
import BigNumber from 'bignumber.js';
import { useCallback, forwardRef } from 'react';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  suffix?: string;
  prefix?: string;
  max?: BigNumber | undefined;
  allowNegative?: boolean;
  value: BigNumber;
  onErrorChange: (value: boolean) => void;
}

export const NumberFormatCustom = forwardRef<typeof NumericFormat, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    const withValueCap = useCallback(
      (inputObj: any) => {
        const { value } = inputObj;
        const val = BigNumber(value);
        if (props.max) {
          if (val.isLessThanOrEqualTo(props.max) || val.isNaN()) {
            props.onErrorChange(false);
            return true;
          }
          props.onErrorChange(true);
          setTimeout(() => {
            props.onErrorChange(false);
          }, 1000);
          return false;
        }
        props.onErrorChange(false);
        return true;
      },
      [props],
    );
    return (
      <NumericFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        getInputRef={ref}
        value={props.value?.toFixed() ?? ''}
        onValueChange={(values: any) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isAllowed={withValueCap}
        thousandSeparator
        isNumericString
        allowNegative={props.allowNegative}
        allowedDecimalSeparators={[',']}
        suffix={props.suffix}
        prefix={props.prefix}
      />
    );
  },
);
