/* eslint-disable import/no-unused-modules */
import { useState } from 'react';
import {
  query_tokenization_block as queryTokenizationBlock,
  OnChainQueryResult,
  Wallet,
  OnChainQuery,
  TokenQuery,
  TokenHashId,
  TokenQueryResult,
  TrackError,
  ProtocolFields,
  ExtraFields,
  Curve,
  CwebToken,
  Timestamp,
  L2Txid,
  TokenAmount,
  User,
} from '@coinweb/cweb-wallet-library';
import useShallowCompareEffect from './useShallowCompareEffect';

export const createUseTokenizationBlock =
  <I, O>(
    toQuery: (input: I) => OnChainQuery,
    fromResult: (result: OnChainQueryResult) => O[],
  ) =>
  (wallet: Wallet | undefined, queries: I[]): O[] | undefined => {
    const [queryResult, setQueryResult] = useState<O[] | undefined>();

    useShallowCompareEffect(() => {
      if (!wallet) {
        return undefined;
      }

      if (queries.length <= 0) {
        return undefined;
      }

      let active = true;

      (async () => {
        const convertedQueries: OnChainQuery[] = queries.map((q) => toQuery(q));
        const results = await queryTokenizationBlock(wallet, convertedQueries);

        if (active) {
          setQueryResult(results.flatMap((r) => fromResult(r)));
        }
      })();

      return () => {
        active = false;
      };
    }, [wallet, queries]);

    return queryResult;
  };

const identity = <X>(x: X) => x;

export const useTokenizationBlock = createUseTokenizationBlock<
  OnChainQuery,
  OnChainQueryResult
>(identity, (x) => [x]);

export const createUseTokenQuery = <I, O>(
  toQuery: (input: I) => [TokenHashId, TokenQuery],
  fromResult: (result: TokenQueryResult) => O[],
) =>
  createUseTokenizationBlock<I, O>(
    (input: I) => ({ TokenQuery: toQuery(input) }),
    (result: OnChainQueryResult) =>
      'TokenQueryResult' in result ? fromResult(result.TokenQueryResult) : [],
  );

export const useTokenQuery = createUseTokenQuery<
  [TokenHashId, TokenQuery],
  TokenQueryResult
>(identity, (x) => [x]);

export type Result<L, R> = { Ok: L } | { Err: R };

export type ResultTE<L> = Result<L, TrackError>;

export const useTokenQueryProtocolFields = createUseTokenQuery<
  TokenHashId,
  ResultTE<ProtocolFields>
>(
  (input: TokenHashId) => [input, TokenQuery.ProtocolFields],
  (result: TokenQueryResult) =>
    'ProtocolFields' in result ? [result.ProtocolFields] : [],
);

export const useTokenQueryLastExtraFields = createUseTokenQuery<
  TokenHashId,
  ResultTE<ExtraFields>
>(
  (input: TokenHashId) => [input, TokenQuery.LastExtraFields],
  (result: TokenQueryResult) =>
    'LastExtraFields' in result ? [result.LastExtraFields] : [],
);

export const useTokenQueryBondingCurve = createUseTokenQuery<
  TokenHashId,
  ResultTE<Curve | null>
>(
  (input: TokenHashId) => [input, TokenQuery.BondingCurve],
  (result: TokenQueryResult) =>
    'BondingCurve' in result ? [result.BondingCurve] : [],
);

export const useTokenQueryCalculateLiquidityChangeFees = createUseTokenQuery<
  TokenHashId,
  ResultTE<CwebToken>
>(
  (input: TokenHashId) => [input, TokenQuery.CalculateLiquidityChangeFees],
  (result: TokenQueryResult) =>
    'CalculateLiquidityChangeFees' in result
      ? [result.CalculateLiquidityChangeFees]
      : [],
);

export const useTokenQueryHistoricalExtraFields = createUseTokenQuery<
  TokenHashId,
  ResultTE<[Timestamp, ExtraFields][]>
>(
  (input: TokenHashId) => [input, TokenQuery.HistoricalExtraFields],
  (result: TokenQueryResult) =>
    'HistoricalExtraFields' in result ? [result.HistoricalExtraFields] : [],
);

export const useTokenQueryCurrentPrice = createUseTokenQuery<
  TokenHashId,
  ResultTE<[Timestamp, ExtraFields][]>
>(
  (input: TokenHashId) => [input, TokenQuery.HistoricalExtraFields],
  (result: TokenQueryResult) =>
    'HistoricalExtraFields' in result ? [result.HistoricalExtraFields] : [],
);

// export const useTokenQueryHistoricalPrice = createUseTokenQuery<
//   TokenHashId,
//   ResultTE<[Timestamp, CwebToken][]>
// >(
//   (input: TokenHashId) => [input, TokenQuery.HistoricalPrice],
//   (result: TokenQueryResult) =>
//     'HistoricalPrice' in result ? [result.HistoricalPrice] : [],
// );

export const useTokenQueryTotalSupply = createUseTokenQuery<
  TokenHashId,
  TokenAmount
>(
  (input: TokenHashId) => [input, TokenQuery.TotalSupply],
  (result: TokenQueryResult) =>
    'TotalSupply' in result && 'Ok' in result.TotalSupply
      ? [result.TotalSupply.Ok]
      : [],
);

export const useTokenQueryHistoricalSupply = createUseTokenQuery<
  TokenHashId,
  [Timestamp, TokenAmount]
>(
  (input: TokenHashId) => [input, TokenQuery.HistoricalSupply],
  (result: TokenQueryResult) =>
    'HistoricalSupply' in result && 'Ok' in result.HistoricalSupply
      ? result.HistoricalSupply.Ok
      : [],
);

export const useTokenQueryL2Txid: (
  wallet: Wallet | undefined,
  queries: string[],
) => string[] | undefined = createUseTokenQuery<TokenHashId, L2Txid>(
  (input: TokenHashId) => [input, TokenQuery.L2Txid],
  (result: TokenQueryResult) =>
    'L2Txid' in result && 'Ok' in result.L2Txid ? [result.L2Txid.Ok] : [],
);

export const useTokenQueryTopHolders: (
  wallet: Wallet | undefined,
  queries: string[],
) => [User, BigInt][] | undefined = createUseTokenQuery<
  TokenHashId,
  [User, TokenAmount]
>(
  (input: TokenHashId) => [input, TokenQuery.TopHolders],
  (result: TokenQueryResult) =>
    'TopHolders' in result && 'Ok' in result.TopHolders
      ? result.TopHolders.Ok
      : [],
);
