import { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { routePaths } from 'routePaths';
import { TokenFields } from 'enums';
import checkPubkey from 'utils/checkPubkey';
import { useTokenNotificationsSubscription } from 'hooks/useTokenNotificationsSubscription';
import { useNotificationsSubscription } from 'hooks/useNotificationsSubscription';
import { usePubKeyParam } from 'hooks/usePubKeyParam';
import { useCurrentToken } from 'hooks/useCurrentToken';
import { usePrevious } from 'hooks/usePrevious';
import { REACT_APP_API_ENDPOINT, REACT_APP_DEVNET } from 'conf';
import {
  fieldIsSTBL,
  fieldTokenName,
  fieldTokenSymbol,
  fieldTokenHashId,
} from 'redux/reducers/tokenReducer';
import { CompliancePage } from '../../pages/Compliance';
import { FeesPage } from '../../pages/transfers/Fees';
import { BlacklistPage } from '../../pages/transfers/Blacklist';
import { WhitelistPage } from '../../pages/transfers/Whitelist';
import { StakingPage } from '../../pages/Staking';
import { AnalyticsPage } from '../../pages/Analytics';
import { BasicIssuancePage } from '../../pages/BasicIssuance/BasicIssuance';
import { ExchangePage } from '../../pages/Exchange';
import { LiquidityPage } from '../../pages/Liquidity';
import { NotificationsPage } from '../../pages/Notifications';
import { BatchSendPage } from '../../pages/BatchSend';
import { CommonSettingsPage } from '../../pages/bridges/CommonSettings';
import { BNBPage } from '../../pages/bridges/BNB';
import { EthereumPage } from '../../pages/bridges/Ethereum';
import { ElrondPage } from '../../pages/bridges/Elrond';
import { PolygonPage } from '../../pages/bridges/Polygon';
import { TokenizationHeader } from './TokenizationHeader';
import { ButterBar } from './ButterBar';
import { TokenizationSidebar } from './TokenizationSidebar';
import { LoginPage } from '../../pages/Login';

type TokenizationFrameProps = {
  serverWarning: boolean;
};

const TokenizationFrame = (props: TokenizationFrameProps) => {
  const navigate = useNavigate();
  const pubKey = usePubKeyParam();
  useNotificationsSubscription();
  useTokenNotificationsSubscription();
  if (!checkPubkey(pubKey)) {
    navigate(routePaths.TOKENIZATION.LOGIN);
  }

  return (
    <>
      <TokenizationHeader />
      {props.serverWarning && <ButterBar />}
      <Box display="flex">
        <TokenizationSidebar />
        <Box
          pt="100px"
          pl="20px"
          pr="20px"
          pb="40px"
          width="100%"
          minHeight="100vh"
          component="main"
          sx={{ flexGrow: 1 }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
export const Tokenization = () => {
  const [token] = useCurrentToken();
  const dispatch = useDispatch();
  const previousToken = usePrevious(token);
  const [serverWarning, setServerWarning] = useState<boolean>(false);

  useEffect(() => {
    const url = new URL('ready', REACT_APP_API_ENDPOINT);
    fetch(url.href).catch(() => setServerWarning(true));
  }, []);
  useEffect(() => {
    const isDevEnvironment = REACT_APP_DEVNET === 'true';

    document.documentElement.style.setProperty(
      '--background-gradient',
      isDevEnvironment
        ? 'linear-gradient(to top, #006874, #003737)'
        : 'linear-gradient(to top, #1f1d47, #0a0b43)',
    );
    document.documentElement.style.setProperty(
      '--background-color',
      isDevEnvironment ? '#0f3e43' : '#1a1946',
    );
  });
  useEffect(() => {
    if (previousToken && token && previousToken.hashId === token.hashId) {
      return;
    }
    if (token) {
      if (
        token.hashId ===
        '0xc9895090994839f5bbb111fbe86179325375f41e01ab7f3b9c20ebdb0830371b'
      ) {
        dispatch(fieldIsSTBL(true));
        dispatch(fieldTokenName('STBL'));
        dispatch(fieldTokenSymbol('STBL'));
        dispatch(
          fieldTokenHashId(
            '0xc9895090994839f5bbb111fbe86179325375f41e01ab7f3b9c20ebdb0830371b',
          ),
        );
      } else {
        dispatch(fieldIsSTBL(false));
        const code: string | undefined = token?.extraField(TokenFields.CODE);
        const name: string | undefined = token?.extraField(TokenFields.NAME);
        dispatch(fieldTokenName(name ?? ''));
        dispatch(fieldTokenSymbol(code ?? ''));
        dispatch(fieldTokenHashId(token.hashId ?? undefined));
      }
    } else {
      dispatch(fieldIsSTBL(false));
      dispatch(fieldTokenName(''));
      dispatch(fieldTokenSymbol(''));
      dispatch(fieldTokenHashId(undefined));
    }
  }, [token, previousToken, dispatch]);
  const root = routePaths.TOKENIZATION;

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Routes>
        <Route path={root.LOGIN} element={<LoginPage />} />
        <Route
          path={root.ROOT}
          element={<TokenizationFrame serverWarning={serverWarning} />}
        >
          <Route
            path={root.HOME}
            element={<BasicIssuancePage onBeWarning={setServerWarning} />}
          />
          <Route path={root.BASIC_ISSUANCE.ROOT} element={<Outlet />}>
            <Route
              path={root.BASIC_ISSUANCE.FUNGIBLE}
              element={<BasicIssuancePage onBeWarning={setServerWarning} />}
            />
            <Route
              path={root.BASIC_ISSUANCE.NONFUNGIBLE}
              element={<BasicIssuancePage onBeWarning={setServerWarning} />}
            />
            <Route
              path={root.BASIC_ISSUANCE.MULTI}
              element={<BasicIssuancePage onBeWarning={setServerWarning} />}
            />
          </Route>
          <Route path={root.LIQUIDITY} element={<LiquidityPage />} />
          <Route path={root.COMPLIANCE} element={<CompliancePage />} />
          <Route path={root.EXCHANGE} element={<ExchangePage />} />
          <Route path={root.BRIDGES.ROOT} element={<Outlet />}>
            <Route
              path={root.BRIDGES.COMMON_SETTINGS}
              element={<CommonSettingsPage />}
            />
            <Route path={root.BRIDGES.EVM} element={<Outlet />}>
              <Route path={root.BRIDGES.ETHEREUM} element={<EthereumPage />} />
              <Route path={root.BRIDGES.BNB} element={<BNBPage />} />
              <Route path={root.BRIDGES.ELROND} element={<ElrondPage />} />
              <Route path={root.BRIDGES.POLYGON} element={<PolygonPage />} />
            </Route>
          </Route>
          <Route path={root.ANALYTICS} element={<AnalyticsPage />} />
          <Route path={root.NOTIFICATIONS} element={<NotificationsPage />} />
          <Route path={root.TRANSFERS_ROOT} element={<Outlet />}>
            <Route path={root.FEES} element={<FeesPage />} />
            <Route path={root.BLACKLIST} element={<BlacklistPage />} />
            <Route path={root.WHITELIST} element={<WhitelistPage />} />
          </Route>
          <Route path={root.STAKING} element={<StakingPage />} />
          <Route path={root.BATCH_SEND} element={<BatchSendPage />} />
        </Route>
      </Routes>
    </Box>
  );
};
