import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetState } from 'hooks/useSetState';

export const useSectionsState = (items) => {
  const location = useLocation();

  const [nestedSectionsState, setNestedSectionsState] = useSetState(() =>
    items.reduce((acc, nextValue, index) => {
      if (nextValue.items) {
        acc[index] = location.pathname.includes(nextValue.url);
      }

      return acc;
    }, {}),
  );

  const handleSectionToggle = useCallback(
    (index) => {
      const isExpanded = nestedSectionsState[index];
      setNestedSectionsState({ [index]: !isExpanded });
    },
    [nestedSectionsState, setNestedSectionsState],
  );

  return [nestedSectionsState, handleSectionToggle];
};
