import { Fragment } from 'react';
import { arrayOf, bool, string, number } from 'prop-types';
import { noop } from 'lodash-es';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { joinUrl } from 'utils/utils';
import { SidebarItemType } from './sidebarTypes';
import { Link } from './Link';
import { useSectionsState } from './sidebarHooks';

export const NestedList = (props) => {
  const [sectionsState, toggleSectionState] = useSectionsState(props.items);

  return (
    <Collapse
      in={props.isOpen}
      timeout="auto"
      sx={{ width: '100%' }}
      unmountOnExit
    >
      <List component="div" disablePadding sx={{ width: '100%' }}>
        {props.items.map((item, index) => {
          const isExpanded = sectionsState[index];

          return item.items ? (
            <Fragment key={item.url}>
              <ListItem
                disablePadding
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                to={joinUrl(props.url, item.url)}
                component={Link}
                style={({ isActive }) => ({
                  fontWeight: isActive ? 600 : 400,
                })}
              >
                <ListItemButton
                  disabled={item.disabled}
                  sx={{ pl: `${props.nestingLevel * 2}rem`, width: '100%' }}
                  style={{
                    fontSize: '0.8rem',
                  }}
                  onClick={item.items ? () => toggleSectionState(index) : noop}
                >
                  <ListItemText disableTypography primary={item.text} />
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {item.items ? (
                    isExpanded ? (
                      <ExpandLess className="text-inherit" />
                    ) : (
                      <ExpandMore className="text-inherit" />
                    )
                  ) : null}
                </ListItemButton>
              </ListItem>

              <NestedList
                nestingLevel={props.nestingLevel + 1}
                isOpen={isExpanded}
                items={item.items}
                url={joinUrl(props.url, item.url)}
              />
            </Fragment>
          ) : (
            <Fragment key={item.url}>
              <ListItem disablePadding>
                <ListItemButton
                  disabled={item.disabled}
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 600 : 400,
                    fontSize: '0.8rem',
                    paddingLeft: `${props.nestingLevel * 2}rem`,
                    width: '100%',
                  })}
                  to={joinUrl(props.url, item.url)}
                  component={Link}
                >
                  <ListItemText disableTypography primary={item.text} />
                </ListItemButton>
              </ListItem>
            </Fragment>
          );
        })}
      </List>
    </Collapse>
  );
};

NestedList.propTypes = {
  isOpen: bool.isRequired,
  url: string.isRequired,
  nestingLevel: number.isRequired,
  items: arrayOf(SidebarItemType).isRequired,
};
