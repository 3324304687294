import { Dayjs } from 'dayjs';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

type Props = {
  className?: string;
  disabled?: boolean;
  inputFormat?: string;
  time: Dayjs | null;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: Dayjs | null) => void;
};

export const TimePicker = ({
  className,
  disabled,
  inputFormat,
  time,
  onChange,
  ...props
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopTimePicker
        disabled={disabled}
        value={time}
        onChange={onChange}
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          OpenPickerIcon: () => null,
        }}
        renderInput={(params) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...params} />
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(inputFormat ? { inputFormat } : {})}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </LocalizationProvider>
  );
};
