import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import { chainPairsInfoText, thirdPartyInfoText } from 'texts';
import ClearIcon from '@mui/icons-material/Clear';
import commonStyles from 'styles/commonStyles.module.scss';
import { chainTypes, chainOptions } from '../liquidityTypes';
import styles from '../Liquidity.module.scss';
import { GradientBox } from '../../../components/Containers/Box';
import { InfoIcon } from '../../../components/Icons/Icons';
import { SelectTokenization } from '../../../components/Input/Select';
import {
  BpCheckedIconCheck,
  BpIconCheck,
} from '../../../components/ControlButtons/Check';
import { LineChart } from '../../../components/LineChart';

type Props = {
  disabled: boolean;
  values: chainTypes[];
  onAdd: () => void;
  onRemove: (removeIndex: number) => void;
  onChain1Change: (value: number, index: number) => void;
  onChain2Change: (value: number, index: number) => void;
};

const mockChartData = {
  labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  datasets: [
    {
      label: 'Chain #1',
      data: [
        {
          x: 0,
          y: 1,
        },
        {
          x: 1,
          y: 1,
        },
        {
          x: 2,
          y: 1,
        },
        {
          x: 3,
          y: 1,
        },
        {
          x: 4,
          y: 1,
        },
        {
          x: 5,
          y: 1,
        },
        {
          x: 6,
          y: 1,
        },
        {
          x: 7,
          y: 1,
        },
        {
          x: 8,
          y: 1,
        },
        {
          x: 9,
          y: 1,
        },
        {
          x: 10,
          y: 1,
        },
        {
          x: 11,
          y: 1,
        },
        {
          x: 12,
          y: 1,
        },
        {
          x: 13,
          y: 1,
        },
        {
          x: 14,
          y: 1,
        },
        {
          x: 15,
          y: 1,
        },
      ],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Chain #2',
      data: [
        {
          x: 0,
          y: 1.5,
        },
        {
          x: 1,
          y: 1.5,
        },
        {
          x: 2,
          y: 1.5,
        },
        {
          x: 3,
          y: 1.5,
        },
        {
          x: 4,
          y: 1.5,
        },
        {
          x: 5,
          y: 1.5,
        },
        {
          x: 6,
          y: 1.5,
        },
        {
          x: 7,
          y: 1.5,
        },
        {
          x: 8,
          y: 1.5,
        },
        {
          x: 9,
          y: 1.5,
        },
        {
          x: 10,
          y: 1.5,
        },
        {
          x: 11,
          y: 1.5,
        },
        {
          x: 12,
          y: 1.5,
        },
        {
          x: 13,
          y: 1.5,
        },
        {
          x: 14,
          y: 1.5,
        },
        {
          x: 15,
          y: 1.5,
        },
      ],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export const ChainPairsSection = (props: Props) => {
  return (
    <GradientBox>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h2">Chain Pairs</Typography>
        <InfoIcon title={chainPairsInfoText} />
      </Box>

      {props.values.map((value: chainTypes, index: number) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} mb={3}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={1}
            >
              <Grid container>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>
                      <Typography variant="h4">Chain #1</Typography>
                    </InputLabel>
                    <SelectTokenization
                      value={value.chain1}
                      label="Chain #1"
                      disabled={props.disabled}
                      onChange={(e: any) => {
                        props.onChain1Change(e.target.value, index);
                      }}
                    >
                      {chainOptions &&
                        chainOptions.map((item: any) => (
                          <MenuItem value={item.value} key={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </SelectTokenization>
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>
                      <Typography variant="h4">Chain #2</Typography>
                    </InputLabel>
                    <SelectTokenization
                      value={value.chain2}
                      label="Chain #2"
                      disabled={props.disabled}
                      onChange={(e: any) => {
                        props.onChain2Change(e.target.value, index);
                      }}
                    >
                      {chainOptions &&
                        chainOptions.map((item: any) => (
                          <MenuItem value={item.value} key={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </SelectTokenization>
                  </FormControl>
                  <Button
                    className={styles.addButton}
                    onClick={props.onAdd}
                    disabled={props.disabled}
                  >
                    +
                  </Button>
                  <FormGroup sx={{ mt: '20px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          disabled={props.disabled}
                          variant="vibrant"
                          checkedIcon={<BpCheckedIconCheck />}
                          icon={<BpIconCheck />}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          Work with external liquidity market-maker services
                          <InfoIcon title={thirdPartyInfoText} />
                        </Typography>
                      }
                    />
                  </FormGroup>
                  {props.values.length > 1 && index > 0 && (
                    <Button
                      onClick={() => props.onRemove(index)}
                      sx={{ ml: 1, mt: 5 }}
                      disabled={props.disabled}
                    >
                      <ClearIcon className={commonStyles.mutedText} />
                    </Button>
                  )}
                </Grid>

                <Grid item xs={6} pl={0}>
                  <LineChart
                    xAxisTitle=""
                    yAxisTitle=""
                    tooltipTitle=""
                    data={mockChartData}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        );
      })}
    </GradientBox>
  );
};
