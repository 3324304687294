import { useEffect, useCallback, useRef } from 'react';
import {
  get_user_operations_history as getUserOperationsHistory,
  subscribe_to_notifications as subscribeToNotifications,
} from '@coinweb/cweb-wallet-library';
import { useDispatch } from 'react-redux';
import { fieldModalMessage } from 'redux/reducers/tokenReducer';
import { TokenFields } from 'enums';
import { OperationType } from 'types/operationType';
import { useWalletFromParams } from './useWalletFromParams';
// eslint-disable-next-line import/no-unused-modules
export const useNotificationsSubscription = () => {
  const dispatch = useDispatch();

  const [wallet] = useWalletFromParams();
  let subscription: any;

  const ref = useRef<
    {
      id: any;
      message: string;
      read: boolean;
    }[]
  >();
  ref.current = [];

  const handleNotifications = useCallback(() => {
    if (wallet) {
      // eslint-disable-next-line
      subscription = subscribeToNotifications(wallet, undefined, {
        keepAlive: 10000,
        retryAttempts: 100,
        shouldRetry: (error) => {
          // eslint-disable-next-line no-console
          console.error('WS error at notification subscription:', error);
          return false;
        },
        onNonLazyError: (errorOrCloseEvent) => {
          // eslint-disable-next-line no-console
          console.error('WS error or close event:', errorOrCloseEvent);
        },
        on: {
          error: (received) => {
            // eslint-disable-next-line no-console
            console.error('WS `error`:', received);
          },
          closed: (received) => {
            // eslint-disable-next-line no-console
            console.warn('WS `closed`:', received);
          },
          message: () => {
            getUserOperationsHistory(wallet, null).then((response) => {});
          },
        },
      });

      // NOTE: call to `subscribe` is needed, even if supplied lambda
      // will do nothing
      subscription.subscribe((x: any) => {
        // eslint-disable-next-line no-console
        console.log('New update:', x);
        const { body } = x.notifications.writeOp.op.issuedClaim.content;
        if (
          (body &&
            body?.details &&
            body?.details?.CreateCustomToken &&
            body?.details?.CreateCustomToken?.token_name) ||
          (body && body?.extra_fields)
        ) {
          let myTokenname: string = '';
          if (body?.details?.CreateCustomToken)
            myTokenname = body.details.CreateCustomToken
              .token_name as unknown as string;
          else if (body?.extra_fields)
            myTokenname = body?.extra_fields?.find(
              (field: any) => field.name === TokenFields.NAME,
            ).content as unknown as string;

          dispatch(
            fieldModalMessage({
              text: `${myTokenname} is created! Please be advised that data retrieval is in progress and may take a few minutes.`,
              type: OperationType.CREATE_CUSTOM_TOKEN,
              tokenName: myTokenname,
            }),
          );
        }
      });
    }
    return () => {
      if (subscription?.unsubscribe) {
        subscription.unsubscribe();
      }
    };
  }, [wallet, dispatch]);

  useEffect(() => {
    if ('Notification' in window && Notification.permission === 'granted') {
      handleNotifications();
    } else if (
      'Notification' in window &&
      Notification.permission !== 'denied'
    ) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          handleNotifications();
        }
      });
    }
  }, [wallet, handleNotifications]);
};
