import React, { useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { Switch } from '../../components/ControlButtons/Switch';
import { GradientBox, SpaceBetweenBox } from '../../components/Containers/Box';
import { CommonSettingsPage } from './CommonSettings';

export const ElrondPage = () => {
  const [override, setOverride] = useState(true);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minHeight="100vh"
      padding="0px 20px"
    >
      <GradientBox className="mb-5">
        <Typography variant="h2">Elrond Mainnet</Typography>
        <SpaceBetweenBox sx={{ height: '64px', alignItems: 'center' }}>
          <Typography alignSelf="center" fontSize="14px">
            Deploy bridge to elrond mainnet?
          </Typography>
          <Switch />
        </SpaceBetweenBox>
        <Divider />
        <SpaceBetweenBox sx={{ marginTop: '15px' }}>
          <Typography alignSelf="center" fontSize="14px">
            Override common settings?
          </Typography>
          <Switch
            checked={override}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOverride(e.target.checked)
            }
          />
        </SpaceBetweenBox>
      </GradientBox>
      {override ? undefined : <CommonSettingsPage />}
    </Box>
  );
};
