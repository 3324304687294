const FRAGMENT_LENGTH = 4;

export default (hash: string) => {
  if (!hash) {
    return hash;
  }

  const start = (hash.startsWith('0x') ? 2 : 0) + FRAGMENT_LENGTH;
  const end = FRAGMENT_LENGTH;

  if (hash.length <= start + end) {
    return hash;
  }

  return `${hash.slice(0, start)}…${hash.slice(-end)}`;
};
