import { Box, Typography } from '@mui/material';
import { bcPriceChartInfoText, bcAmountChartInfoText } from 'texts';
import { GradientBox } from '../../../components/Containers/Box';
import { InfoIcon } from '../../../components/Icons/Icons';
import { Quadratic } from '../liquidityTypes';
import { PriceChart } from './PriceChart';
import { AmountChart } from './AmountChart';

type Props = {
  value: Quadratic;
  yTokenName: string;
};

export const PriceGraphsSection = (props: Props) => {
  return (
    <Box className="mt-5 w-full">
      <GradientBox className="mt-5">
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="h2">Price Chart</Typography>
          <InfoIcon title={bcPriceChartInfoText} />
        </Box>
        <PriceChart
          value={{
            buy: props.value.buy,
            sell: props.value.sell,
            x0: props.value.x0,
            domain: props.value.domain,
            kMax: props.value.kMax,
          }}
          yTokenName={props.yTokenName}
        />
      </GradientBox>

      <GradientBox className="mt-5">
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="h2">Amount Chart</Typography>
          <InfoIcon title={bcAmountChartInfoText} />
        </Box>
        <Box height="25vh">
          <AmountChart
            value={{
              buy: props.value.buy,
              sell: props.value.sell,
              x0: props.value.x0,
              domain: props.value.domain,
              kMax: props.value.kMax,
            }}
            yTokenName={props.yTokenName}
          />
        </Box>
      </GradientBox>
    </Box>
  );
};
