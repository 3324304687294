import { ChartProps } from 'react-chartjs-2';
import stringToColor from 'string-to-color';
import hexToRgba from 'hex-to-rgba';

import trimHash from 'utils/trimHash';
import { Row } from './types';

const backgroundColor = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)',
];

const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
];

const generateColors = (strings: string[], alpha = 1) => {
  return strings.map((str) => hexToRgba(stringToColor(str), alpha));
};

export const mapRowsToPieChartData = (
  rows: Row[],
): ChartProps<'pie', number[], string>['data'] => {
  return {
    labels: rows.map((row) => trimHash(row.address)),
    datasets: [
      {
        data: rows.map((row) => row.percentage),
        /* use predefined colors list and generate additional colors if not sufficient */
        backgroundColor:
          rows.length > backgroundColor.length
            ? [
                ...backgroundColor,
                ...generateColors(
                  rows
                    .slice(backgroundColor.length, rows.length)
                    .map((row) => row.address),
                  0.2,
                ),
              ]
            : backgroundColor,
        borderColor:
          rows.length > borderColor.length
            ? [
                ...borderColor,
                ...generateColors(
                  rows
                    .slice(borderColor.length, rows.length)
                    .map((row) => row.address),
                ),
              ]
            : borderColor,
        borderWidth: 1,
      },
    ],
  };
};

export function convertToCSV(data: any) {
  const headers = Object.keys(data[0]); // get the headers from the first object in the data array

  const rows = data.map((row: any) =>
    headers.map((header) => row[header]).join(','),
  ); // convert each row to a CSV string
  return [headers.join(','), ...rows].join('\n'); // combine the headers and rows into a single CSV string
}
