import { size } from 'lodash-es';
import { ChartData } from 'chart.js';
import { Box, CircularProgress } from '@mui/material';
import { LineChart } from '../../../components/LineChart';
import { useAmountChartTraces } from '../../../components/LineChart/lineChartUtils';
import { Quadratic } from '../liquidityTypes';

type Props = {
  value: Quadratic;
  yTokenName: string;
};

export const AmountChart = (props: Props) => {
  const traces = useAmountChartTraces({
    domain: props.value.domain,
    buy: props.value.buy,
    sell: props.value.sell,
    x0: props.value.x0,
  }) as ChartData<'line'>;

  return size(traces) ? (
    <LineChart
      xAxisTitle="CWEB"
      yAxisTitle="Token"
      tooltipTitle="CWEB"
      tooltipLabelSuffix={props.yTokenName}
      data={traces}
    />
  ) : (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
      mt={3}
      mb={3}
    >
      <CircularProgress />
    </Box>
  );
};
