import {
  get_user_created_custom_tokens as getUserCreatedCustomTokens,
  Wallet,
} from '@coinweb/cweb-wallet-library';

import { Token } from 'types/Token';
import { GetTokenInformation } from './useTokenInformation';

// eslint-disable-next-line
export const fetchTokenInformation = async (
  wallet: Wallet,
  prevTokens: Token[] | undefined,
): Promise<Token[]> => {
  try {
    const tokens = await getUserCreatedCustomTokens(wallet);

    const info: (Token | undefined)[] = await Promise.all(
      tokens.map(async (token) => {
        if (prevTokens) {
          const index = prevTokens.findIndex((t) => t.hashId === token);

          if (index !== -1) {
            // eslint-disable-next-line
            return await GetTokenInformation(wallet, token, prevTokens[index]);
          }
          let tokenInfo: Token | undefined;
          tokenInfo = await GetTokenInformation(wallet, token, undefined);
          while (!tokenInfo) {
            // eslint-disable-next-line
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
            // eslint-disable-next-line
            tokenInfo = await GetTokenInformation(wallet, token, undefined);
          }
          return tokenInfo;
        }

        return GetTokenInformation(wallet, token, undefined);
      }),
    );

    // Filter out undefined values and map to ensure correct type
    const filteredInfo: Token[] = info.filter(
      (x) => x !== undefined,
    ) as Token[];

    return filteredInfo;
  } catch (error) {
    return [];
  }
};
