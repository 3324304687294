import { lazy, useState, ChangeEvent } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { saveAs } from 'file-saver';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SearchIcon from '@mui/icons-material/Search';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { REACT_APP_MODE } from 'conf';
import { useNavigateIssuance } from 'hooks/useNavigateIssuance';
import commonStyles from 'styles/commonStyles.module.scss';
import { SuspenseWrapper } from 'components/SuspenseWrapper';
import { AnalyticsTable } from './Table';
import { useRows } from './hooks';
import { mapRowsToPieChartData, convertToCSV } from './utils';
import { GradientBox } from '../../components/Containers/Box';
import { BondingCurveChart } from './BondingCurveChart';
import { Row } from './types';

const PieChart = lazy(() => import('../../components/PieChart/PieChart'));

export const AnalyticsPage = () => {
  const [searchValue, setSearchValue] = useState('');
  useNavigateIssuance();
  const rows: Row[] = useRows();

  const filteredRows = searchValue
    ? rows.filter((row) => row.address.includes(searchValue))
    : rows;

  const filename = 'data.csv';

  const handleSearchChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(ev.target.value);
  };

  const handleDownload = () => {
    const csvData = convertToCSV(filteredRows); // convert data to CSV format
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' }); // create a blob with CSV data
    saveAs(blob, filename); // use file-saver library to save the blob as a file
  };
  return (
    <Box>
      <GradientBox>
        <Box p={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h2">Holders</Typography>

            <Box
              ml="auto"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box mr={2} ml={1}>
                <OutlinedInput
                  value={searchValue}
                  onChange={handleSearchChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <SearchIcon className={commonStyles.mutedText} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              {REACT_APP_MODE === 'pro' || REACT_APP_MODE === 'devnet' ? (
                <IconButton>
                  <CloudDownloadIcon
                    onClick={handleDownload}
                    className={commonStyles.mutedText}
                  />
                </IconButton>
              ) : null}
              <Link
                sx={{ display: 'inline-flex', ml: 2 }}
                underline="none"
                className="cursor-pointer"
              >
                <OpenInNewIcon className={commonStyles.mutedText} />
                <Typography
                  ml={1}
                  className={commonStyles.mutedText}
                  fontWeight={500}
                >
                  Explorer
                </Typography>
              </Link>
            </Box>
          </Box>

          <Box mt={2}>
            <AnalyticsTable rows={filteredRows} />
          </Box>
        </Box>
      </GradientBox>

      <SuspenseWrapper>
        <GradientBox>
          <Box marginLeft="auto" marginRight="auto" mt={2} width="30rem">
            <PieChart data={mapRowsToPieChartData(filteredRows)} />
          </Box>
        </GradientBox>

        <BondingCurveChart />
      </SuspenseWrapper>
    </Box>
  );
};
