import BigNumber from 'bignumber.js';
import { NotificationType } from 'utils/notifications';
import { OperationType } from 'types/operationType';

const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
const IS_STBL = 'IS_STBL';
const TOKEN_NAME = 'TOKEN_NAME';
const TOKEN_SYMBOL = 'TOKEN_SYMBOL';
const TOKEN_DECIMAL = 'TOKEN_DECIMAL';
const TOKEN_HASH_ID = 'TOKEN_HASH_ID';
const TOKEN_DESCRIPTION = 'TOKEN_DESCRIPTION';
const TOKEN_INITIAL_SUPPLY = 'TOKEN_INITIAL_SUPPLY';
const TOKEN_MAX_SUPPLY = 'TOKEN_MAX_SUPPLY';
const FUNGIBLE_CURRENT_IMAGE = 'FUNGIBLE_CURRENT_IMAGE';
const MODAL_MESSAGE = 'MODAL_MESSAGE';
const TOKEN_NOTIFICATION = 'TOKEN_NOTIFICATION';
const TOKEN_CREATION = 'TOKEN_CREATION';

type InitialState = {
  sidebarOpen: boolean;
  isSTBL: boolean;
  tokenName: string;
  tokenSymbol: string;
  tokenDescription: string;
  tokenDecimal: BigNumber;
  tokenHashId: string | undefined;
  tokenInitialSupply: null | BigNumber;
  tokenMaxSupply: null | BigNumber;
  fungibleCurrentImg: string | undefined;
  modalMessage:
    | { text: string; type: OperationType; tokenName: string }
    | undefined;
  tokenNotifications: NotificationType[];
  tokenCreation: boolean;
};

const initialState: InitialState = {
  sidebarOpen: true,
  isSTBL: false,
  tokenName: '',
  tokenSymbol: '',
  tokenDecimal: BigNumber(18),
  tokenInitialSupply: null,
  tokenDescription: '',
  tokenMaxSupply: null,
  tokenHashId: undefined,
  fungibleCurrentImg: undefined,
  modalMessage: undefined,
  tokenNotifications: [],
  tokenCreation: false,
};
// eslint-disable-next-line
const tokenReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SIDEBAR_OPEN:
      return {
        ...state,
        sidebarOpen: action.sidebarOpen,
      };
    case IS_STBL:
      return {
        ...state,
        isSTBL: action.isSTBL,
      };
    case TOKEN_NAME:
      return {
        ...state,
        tokenName: action.tokenName,
      };
    case TOKEN_SYMBOL:
      return {
        ...state,
        tokenSymbol: action.tokenSymbol,
      };
    case TOKEN_DECIMAL:
      return {
        ...state,
        tokenDecimal: action.tokenDecimal,
      };
    case TOKEN_DESCRIPTION:
      return {
        ...state,
        tokenDescription: action.tokenDescription,
      };
    case TOKEN_HASH_ID:
      return {
        ...state,
        tokenHashId: action.tokenHashId,
      };
    case TOKEN_INITIAL_SUPPLY:
      return {
        ...state,
        tokenInitialSupply: action.tokenInitialSupply,
      };
    case TOKEN_MAX_SUPPLY:
      return {
        ...state,
        tokenMaxSupply: action.tokenMaxSupply,
      };
    case FUNGIBLE_CURRENT_IMAGE:
      return {
        ...state,
        fungibleCurrentImg: action.fungibleCurrentImg,
      };
    case MODAL_MESSAGE:
      return {
        ...state,
        modalMessage: action.modalMessage,
      };
    case TOKEN_NOTIFICATION:
      return {
        ...state,
        tokenNotifications: action.tokenNotifications,
      };
    case TOKEN_CREATION:
      return {
        ...state,
        tokenCreation: action.tokenCreation,
      };
    default:
      return state;
  }
};

export const fieldSidebarOpen = (sidebarOpen: boolean) => {
  return { type: SIDEBAR_OPEN, sidebarOpen };
};
export const fieldIsSTBL = (isSTBL: boolean) => {
  return { type: IS_STBL, isSTBL };
};
export const fieldTokenName = (tokenName: string) => {
  return { type: TOKEN_NAME, tokenName };
};
export const fieldTokenSymbol = (tokenSymbol: string) => {
  return { type: TOKEN_SYMBOL, tokenSymbol };
};
export const fieldTokenDecimal = (tokenDecimal: BigNumber) => {
  return { type: TOKEN_DECIMAL, tokenDecimal };
};
export const fieldTokenHashId = (tokenHashId: string | undefined) => {
  return { type: TOKEN_HASH_ID, tokenHashId };
};
export const fieldTokenInitialSupply = (
  tokenInitialSupply: BigNumber | null,
) => {
  return { type: TOKEN_INITIAL_SUPPLY, tokenInitialSupply };
};
export const fieldTokenMaxSupply = (tokenMaxSupply: BigNumber | null) => {
  return { type: TOKEN_MAX_SUPPLY, tokenMaxSupply };
};
// eslint-disable-next-line
export const fieldTokenDescription = (tokenDescription: string) => {
  return { type: TOKEN_DESCRIPTION, tokenDescription };
};
export const fieldFungibleTokenImg = (
  fungibleCurrentImg: string | undefined,
) => {
  return { type: FUNGIBLE_CURRENT_IMAGE, fungibleCurrentImg };
};
// eslint-disable-next-line
export const fieldModalMessage = (
  modalMessage:
    | { text: string; type: OperationType; tokenName: string }
    | undefined,
) => {
  return { type: MODAL_MESSAGE, modalMessage };
};
// eslint-disable-next-line
export const fieldTokenNotifications = (
  tokenNotifications: NotificationType[],
) => {
  return { type: TOKEN_NOTIFICATION, tokenNotifications };
};
// eslint-disable-next-line
export const fieldTokenCreation = (tokenCreation: boolean) => {
  return { type: TOKEN_CREATION, tokenCreation };
};

export default tokenReducer;
