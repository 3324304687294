import { useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

const limitTypes = {
  AMOUNT: 'amount',
  TIME: 'time',
};

export const SpendingLimitPage = () => {
  const [limitType, setLimitType] = useState(limitTypes.AMOUNT);

  const handleLimitTypeChange = (ev) => {
    setLimitType(ev.target.value);
  };

  return (
    <Paper elevation={0}>
      <Box pt={2} pb={6} pl={2} pr={2}>
        <Typography variant="h6" textAlign="center">
          Spending limit
        </Typography>
        <Typography variant="h3" mt={2}>
          You can set rules for specific beneficiaries to access funds from this
          Safe without having to collect all signatures.
        </Typography>

        <Box display="flex" flexDirection="column">
          <FormControl sx={{ mt: 2, width: '30%' }}>
            <InputLabel sx={{ marginTop: '-5px' }}>
              Select Beneficiary
            </InputLabel>
            <Select label="Select beneficiary">
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
            <Typography variant="h5" sx={{ marginTop: '10px' }}>
              Define beneficiary that will be able to use the allowance. The
              beneficiary does not have to be an owner of this Safe.
            </Typography>
          </FormControl>

          <FormControl sx={{ mt: 2, width: '30%' }}>
            <InputLabel sx={{ marginTop: '-5px' }}>Select Asset</InputLabel>
            <Select label="Select Asset">
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
            <Typography variant="h5" sx={{ marginTop: '10px' }}>
              Select an asset stored in your Safe. You can set a spending limit
              for any asset stored in your Safe.
            </Typography>
          </FormControl>

          <FormControl>
            <FormLabel>
              <Typography variant="h2" sx={{ marginTop: '20px' }}>
                Select a limit type
              </Typography>
            </FormLabel>
            <RadioGroup value={limitType} onChange={handleLimitTypeChange}>
              <FormControlLabel
                value={limitTypes.AMOUNT}
                control={<Radio />}
                label="Amount-based limit"
              />
              <FormControlLabel
                value={limitTypes.TIME}
                control={<Radio />}
                label="Time-based limit"
              />
            </RadioGroup>
            <Typography variant="h5" sx={{ marginBottom: '10px' }}>
              You can choose to set a one-time spending limit or to have it
              automatically refill after a defined time-period
            </Typography>
          </FormControl>

          <FormControl sx={{ mt: 2, width: '30%' }}>
            <FormLabel sx={{ marginTop: '-5px' }}>
              <Typography variant="h2" sx={{ marginBottom: '10px' }}>
                Select a limit amount
              </Typography>
            </FormLabel>
            <TextField
              label="Limit amount"
              placeholder="Limit amount"
              type="number"
              sx={{
                '.MuiInputLabel-root': {
                  marginTop: '-5px',
                },
              }}
            />
          </FormControl>

          <FormControl sx={{ mt: 2, width: '30%' }}>
            <InputLabel sx={{ marginTop: '-5px' }}>
              Refill time period (optional)
            </InputLabel>
            <Select label="Refill time period (optional)">
              <MenuItem value={1}>1 day</MenuItem>
              <MenuItem value={2}>1 week</MenuItem>
              <MenuItem value={3}>1 month</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Paper>
  );
};
