import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Routes, Route } from 'react-router-dom';
import { DAO } from 'modules/dao/components/DAO';
import { Tokenization } from 'modules/tokenization/components/Tokenization';
import { routePaths } from 'routePaths';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path={routePaths.DAO.DAO_ROOT} element={<DAO />} />
        <Route path="*" element={<Tokenization />} />
      </Routes>
    </QueryClientProvider>
  );
};

export default App;
