import { Suspense } from 'react';
import { Box, CircularProgress } from '@mui/material';

type Props = {
  children: JSX.Element | JSX.Element[];
  fallback?: JSX.Element;
};

export const SuspenseWrapper = (props: Props) => {
  return (
    <Suspense
      fallback={
        props.fallback || (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={3}
          >
            <CircularProgress />
          </Box>
        )
      }
    >
      {props.children}
    </Suspense>
  );
};
