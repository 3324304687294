import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { tokenDetailsInfoText, whiteListerInfoText } from 'texts';
import CircularProgress from '@mui/material/CircularProgress';
import BigNumber from 'bignumber.js';
import { REACT_APP_MODE } from 'conf';
import { TokenFields, TokenTypeEnums } from 'enums';
import { TokenHashId } from '@coinweb/cweb-wallet-library';
import { useQuery } from '@tanstack/react-query';
import DefaultIconLogo from 'images/defaultTokenIcon.svg';
import { useCurrentToken } from 'hooks/useCurrentToken';
import {
  getWhiteListed,
  decodeL1s,
  decodeProperties,
} from './BasicIssuanceUtils';
import {
  BeigeButton,
  BlueButton,
} from '../../components/ControlButtons/Button';
import {
  PreviewDetails,
  PreviewEditibilitySection,
  PreviewPropertiesSection,
  PreviewTokenFeePaymentSection,
  PreviewL1Section,
  PreviewInputBox,
  PreviewDescriptionBox,
  BondingCurveStateType,
  BondingCurveSection,
} from './Sections';
import { PreviewWrapper, CenteredBox } from '../../components/Containers/Box';
import { editibilityTypes } from './options';
import { InfoIcon } from '../../components/Icons/Icons';
import styles from './BasicIssuance.module.scss';

// eslint-disable-next-line import/no-unused-modules
export type BasicIssuancePreviewProps = {
  tokenName: string;
  tokenSymbol: string;
  tokenHashId?: TokenHashId;
  editibilityType: 0 | 1 | undefined;
  description: string | null;
  tokenType: TokenTypeEnums;
  propertyStates: boolean[];
  l1States: boolean[];
  fee: number | undefined;
  issued: boolean;
  setPreview: (state: boolean) => void;
  setCreation: (state: boolean) => void;
  handleCreate: () => void;
  bondingCurve?: BondingCurveStateType;
  pubkey?: string;
  baseImg: string | undefined;
  ipfsAddPin: string | undefined;
  isSTBL: boolean;
};

export function BasicIssuancePreview(props: BasicIssuancePreviewProps) {
  const {
    tokenName,
    tokenSymbol,
    tokenHashId,
    editibilityType,
    description,
    tokenType,
    propertyStates,
    l1States,
    fee,
    issued,
    bondingCurve,
    pubkey,
    setPreview,
    setCreation,
    handleCreate,
    baseImg,
    ipfsAddPin,
    isSTBL,
  } = props;

  const [whitelisted, setWhitelisted] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const [token] = useCurrentToken();

  const { tokenDecimal, tokenInitialSupply, tokenMaxSupply } = useSelector(
    (state: any) => state.token,
  );

  useEffect(() => {
    if (issued && token) {
      if (
        token?.extraField(TokenFields.NAME) === tokenName &&
        token?.extraField(TokenFields.CODE) === tokenSymbol &&
        token?.extraField(TokenFields.ICON) === ipfsAddPin &&
        token?.extraField(TokenFields.EXPONENT) === tokenDecimal.toFixed() &&
        (token?.extraField(TokenFields.PROPERTY) ===
          decodeProperties(propertyStates, tokenType) ||
          (token?.extraField(TokenFields.PROPERTY) === undefined &&
            decodeProperties(propertyStates, tokenType) === '')) &&
        (token?.extraField(TokenFields.L1_TOKEN_TYPE) ===
          decodeL1s(l1States, tokenType) ||
          (token?.extraField(TokenFields.L1_TOKEN_TYPE) === undefined &&
            decodeProperties(l1States, tokenType) === '')) &&
        (token?.extraField(TokenFields.MAXIMUM_SUPPLY) ===
          tokenMaxSupply
            ?.multipliedBy(BigNumber(10).exponentiatedBy(tokenDecimal))
            ?.toFixed() ||
          (token?.extraField(TokenFields.MAXIMUM_SUPPLY) === undefined &&
            tokenMaxSupply?.isNaN())) &&
        token?.extraField(TokenFields.DESCRIPTION) === description &&
        (token?.extraField(TokenFields.FEE) === fee?.toFixed() ||
          (token?.extraField(TokenFields.FEE) === '' &&
            fee?.toFixed() === undefined)) &&
        (token?.extraField(TokenFields.TOKEN_TYPE) === tokenType ||
          (token?.extraField(TokenFields.TOKEN_TYPE) === undefined &&
            tokenType === TokenTypeEnums.FUNGIBLE)) &&
        (token?.extraField(TokenFields.EDITIBILITY_TYPE) ===
          editibilityType?.toFixed() ||
          (token?.extraField(TokenFields.EDITIBILITY_TYPE) === '' &&
            editibilityType === undefined)) &&
        new BigNumber(
          token.protocolFields.initial_token_supply as unknown as string,
        )
          .dividedBy(BigNumber(10).exponentiatedBy(tokenDecimal))
          .toFixed() === tokenInitialSupply?.toFixed()
      )
        setChanged(false);
      else setChanged(true);
    }
  }, [
    tokenDecimal,
    description,
    editibilityType,
    fee,
    tokenInitialSupply,
    ipfsAddPin,
    issued,
    l1States,
    tokenMaxSupply,
    propertyStates,
    token,
    tokenName,
    tokenSymbol,
    tokenType,
  ]);

  const handleEdit = () => {
    setPreview(false);
    setCreation(false);
  };

  const { isLoading } = useQuery({
    queryKey: ['tokenHash', tokenHashId],
    queryFn: () => getWhiteListed(tokenHashId),
    enabled: !!tokenHashId,
    onSuccess: (whitelistData: any) => {
      if (whitelistData.getTokenWhitelist.length === 0) setWhitelisted(false);
      else setWhitelisted(true);
    },
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minHeight="100%"
      padding="0px 20px"
      sx={{
        color: '#9794d8',
      }}
    >
      <PreviewWrapper>
        <CenteredBox>
          <Typography
            fontWeight={600}
            sx={{
              width: 'fit-content',
              fontSize: '16px',
              marginLeft: 'auto !important',
              marginRight: 'auto  !important',
            }}
            align="center"
          >
            Preview
          </Typography>

          {baseImg && baseImg !== 'loading' && (
            <img
              className={styles.previewImg}
              src={baseImg}
              alt="token_image"
              width={100}
            />
          )}
          {baseImg === 'loading' && (
            <CircularProgress
              sx={{ color: '#fff', marginTop: '28px' }}
              size={100}
            />
          )}

          {!baseImg && (
            <img
              className={styles.previewImg}
              src={DefaultIconLogo}
              alt="token_image"
              width={100}
            />
          )}

          <Box display="flex">
            <Typography
              fontWeight={600}
              sx={{
                width: 'fit-content',
                fontSize: '18px',
                margin: '20px auto 30px auto',
              }}
              align="center"
            >
              {tokenName} - {tokenSymbol}
            </Typography>
          </Box>
        </CenteredBox>
        {!isSTBL && (
          <PreviewEditibilitySection
            editibility={
              editibilityType !== undefined
                ? editibilityTypes[editibilityType].label
                : ''
            }
          />
        )}
        {token && (
          <PreviewDetails>
            <Typography variant="h2">
              Whitelister Status
              <InfoIcon title={whiteListerInfoText} />
            </Typography>
            {isLoading ? (
              <CenteredBox>
                <CircularProgress
                  sx={{
                    color: '#fff',
                    margin: '28px auto 0px auto',
                    width: '100%',
                  }}
                  size={50}
                />
              </CenteredBox>
            ) : (
              <PreviewInputBox
                label="WhiteListed"
                value={whitelisted ? 'Yes' : 'No'}
              />
            )}
          </PreviewDetails>
        )}

        <PreviewDetails>
          <>
            <Typography variant="h2">
              Token Details
              <InfoIcon title={tokenDetailsInfoText} />
            </Typography>
            <PreviewInputBox label="Token Name" value={tokenName} />
            <PreviewInputBox label="Token Symbol" value={tokenSymbol} />
            {tokenHashId && (
              <PreviewInputBox
                label="Token Hash Id"
                value={
                  <Tooltip
                    title={tokenHashId}
                    placement="top"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#fff',
                          borderRadius: '2px',
                          boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.2)',
                          padding: ' 8px 15px',
                          color: '#000',
                        },
                      },
                      arrow: {
                        sx: {
                          color: '#fff',
                          boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.2)',
                        },
                      },
                    }}
                  >
                    <span style={{ overflowWrap: 'anywhere' }}>
                      {tokenHashId}
                    </span>
                  </Tooltip>
                }
              />
            )}

            {tokenInitialSupply !== null &&
              String(tokenInitialSupply) !== 'NaN' && (
                <PreviewInputBox
                  label="Initial Supply"
                  value={tokenInitialSupply.toFixed()}
                />
              )}
            {tokenMaxSupply !== null && String(tokenMaxSupply) !== 'NaN' && (
              <PreviewInputBox
                label="Maximum Supply"
                value={String(tokenMaxSupply)}
              />
            )}
            {!!tokenDecimal && (
              <PreviewInputBox label="Decimals" value={String(tokenDecimal)} />
            )}
            {!!description && (
              <PreviewDescriptionBox label="Description" value={description} />
            )}
          </>
        </PreviewDetails>
        {bondingCurve ? (
          <BondingCurveSection
            pubkey={pubkey}
            params={bondingCurve}
            yTokenName={tokenName}
          />
        ) : undefined}
        {(REACT_APP_MODE === 'pro' || REACT_APP_MODE === 'devnet') && !isSTBL && (
          <>
            <PreviewPropertiesSection
              tokenType={tokenType}
              propertyStates={propertyStates}
            />
            <PreviewL1Section tokenType={tokenType} l1States={l1States} />
          </>
        )}
        {!isSTBL && <PreviewTokenFeePaymentSection fee={fee} />}

        <CenteredBox width="100%" className="mt-5 flex">
          {!isSTBL && (
            <BeigeButton
              variant="outlined"
              className="mr-5"
              onClick={handleEdit}
            >
              Edit Token
            </BeigeButton>
          )}
          {/* {!issued && !isSTBL && !changed && (
            <BlueButton
              variant="outlined"
              className="mr-5"
              onClick={handleCreate}
            >
              Create Token
            </BlueButton>
          )} */}
          {!isSTBL && ((issued && changed) || (!issued && !changed)) && (
            <BlueButton variant="outlined" onClick={handleCreate}>
              Broadcast Changes
            </BlueButton>
          )}
        </CenteredBox>
      </PreviewWrapper>
    </Box>
  );
}
