import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { request } from 'graphql-request';
import BigNumber from 'bignumber.js';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, FormHelperText } from '@mui/material';
import {
  TokenUiCommand,
  ExtraFields,
  Field,
  force_refresh as forceRefresh,
} from '@coinweb/cweb-wallet-library';
import { useMutation } from '@tanstack/react-query';
import { tokenDetailsInfoText, tokenImageInfoText } from 'texts';
import {
  REACT_APP_IPFS_API_ENDPOINT,
  REACT_APP_PAY_BUTTON,
  REACT_APP_MODE,
} from 'conf';
import {
  fieldTokenName,
  fieldTokenSymbol,
  fieldTokenDecimal,
  fieldTokenInitialSupply,
  fieldTokenMaxSupply,
  fieldFungibleTokenImg,
  fieldTokenDescription,
} from 'redux/reducers/tokenReducer';
import { createIpfsAddPinQuery } from 'queries/ipfsQuery';
import convertBase64 from 'utils/convertBase64';
import intToHexString from 'utils/intToHexString';
import editibilityStrtoNumber from 'utils/editibilityStrtoNumber';
import { NumericInput } from 'components/NumericInput';
import { TokenFields, TokenTypeEnums } from 'enums';
import { useWalletFromParams } from 'hooks/useWalletFromParams';
import { useCurrentToken } from 'hooks/useCurrentToken';
import { usePrevious } from 'hooks/usePrevious';
import { OperationType } from 'types/operationType';
import {
  createTokenJson,
  createTokenCommand,
  updateTokenJson,
  updateTokenCommand,
  decodeProperties,
  decodeL1s,
  handleTokenNameErrorText,
  handleTokenSymbolErrorText,
  handleInitialSupplyErrorText,
  handleMaximumSupplyErrorText,
} from './BasicIssuanceUtils';
import {
  InputTokenization,
  InputDescription,
} from '../../components/Input/Input';
import {
  BeigeButton,
  BlueButton,
} from '../../components/ControlButtons/Button';
import {
  EditibilitySection,
  DAOSection,
  TokenImage,
  TokenDetails,
  PropertiesSection,
  L1Section,
  TokenFeePaymentSection,
  ShardSection,
  GridContainerBox,
  ImagePreviewBox,
  ImageUploadSection,
  SubtokenSection,
  CreationSection,
  BondingCurveStateType,
  LoaderSection,
} from './Sections';
import { CenteredBox } from '../../components/Containers/Box';
import { properties, L1Tokens, tokenFees, uploadTypes } from './options';
import { InfoIcon } from '../../components/Icons/Icons';
import { BasicIssuancePreview } from './BasicIssuancePreview';
import { findQuadraticX0 } from '../Liquidity/liquidityUtils';

const Index = {
  TOKEN_NAME: 0,
  TOKEN_SYMBOL: 1,
  INITIAL_SUPPLY: 2,
  MAXIMUM_SUPPLY: 3,
  DECIMALS: 4,
  MAX_SUPPLY_LESS_THAN_INITIAL_SUPPLY: 5,
  TOKEN_ICON: 6,
};

type BasicIssuancePageProps = {
  onBeWarning: (value: boolean) => void;
};
// eslint-disable-next-line import/no-unused-modules
export const BasicIssuancePage = (props: BasicIssuancePageProps) => {
  const { onBeWarning } = props;

  const dispatch = useDispatch();

  const [wallet, pubKey] = useWalletFromParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [token] = useCurrentToken();
  const previousToken = usePrevious(token);

  const {
    sidebarOpen,
    isSTBL,
    tokenName,
    tokenSymbol,
    tokenDecimal,
    tokenInitialSupply,
    tokenMaxSupply,
    fungibleCurrentImg,
    tokenCreation,
    tokenDescription,
  } = useSelector((state: any) => state.token);

  const [loading, setLoading] = useState<boolean>(false);

  const [tokenType, setTokenType] = useState<TokenTypeEnums>(
    TokenTypeEnums.FUNGIBLE,
  );

  const [editibilityType, setEditibilityType] = useState<0 | 1 | undefined>(
    undefined,
  );

  const [uploadType, setUploadType] = useState<0 | 1>(uploadTypes[0].value);
  const [dao, setDAO] = useState<string>('');

  const [propertyStates, setPropertyStates] = useState<boolean[]>(
    new Array(properties[tokenType]?.length).fill(false),
  );

  const [l1States, setL1States] = useState<boolean[]>(
    new Array(L1Tokens[tokenType]?.length).fill(false),
  );

  const [fee, setFees] = useState<number | undefined>(undefined);
  const [shard, setShard] = useState<string>('');
  const [preview, setPreview] = useState<boolean>(token !== undefined);
  const [creation, setCreation] = useState<boolean>(false);
  const [qrSize, setQrSize] = useState<number | undefined>(undefined);
  const previousDecimals = usePrevious(tokenDecimal);

  // errorArray is to display errorTexts at numericInput
  // first 5 boolean is to determine if required token details inputs are empty.
  // last index to determine if max supply is less than   initial supply
  const [fillError, setFillError] = useState<boolean[]>(
    new Array(7).fill(false),
  );

  const [subtoken, setSubtoken] = useState<0 | 1>(0);
  const [tokenQrData, setTokenQrData] = useState<string>('');
  const [tokenCommand, setTokenCommand] = useState<TokenUiCommand>();
  const payButtonAvailibility: boolean = REACT_APP_PAY_BUTTON === 'true';

  const [bondingCurveData, setBondingCurveData] =
    useState<BondingCurveStateType>();

  const [ipfsAddPin, setIpfsAddPin] = useState<string | undefined>();
  // TODO: implement math.ceil

  const supplyLimit = new BigNumber(2)
    .exponentiatedBy(256)
    .multipliedBy(BigNumber(10).exponentiatedBy(tokenDecimal));

  const handleCreation = useCallback(
    (cr: boolean) => {
      setCreation(cr);
      if (wallet && cr) {
        forceRefresh(wallet);
      }
    },
    [wallet],
  );

  useEffect(() => {
    const str = location.pathname;
    if (str.includes('multi')) setTokenType(TokenTypeEnums.MULTI);
    else if (str.includes('nonfungible'))
      setTokenType(TokenTypeEnums.NONFUNGIBLE);
    else setTokenType(TokenTypeEnums.FUNGIBLE);
  }, [location.pathname, navigate]);

  const prevDecimals = useRef<BigNumber | undefined>(undefined);

  useEffect(() => {
    if (
      wallet &&
      token &&
      previousDecimals &&
      !previousDecimals.isNaN() &&
      !previousDecimals?.isEqualTo(tokenDecimal)
    ) {
      const exponentStr = token?.extraField(TokenFields.EXPONENT);
      const exponent = exponentStr ? BigNumber(exponentStr) : BigNumber(18);

      const newMaxSupply = tokenMaxSupply?.dividedBy(
        BigNumber(10).exponentiatedBy(
          tokenDecimal.minus(prevDecimals.current ?? exponent),
        ),
      );
      if (
        tokenMaxSupply !== null &&
        newMaxSupply &&
        !newMaxSupply.isEqualTo(tokenMaxSupply)
      ) {
        dispatch(fieldTokenMaxSupply(newMaxSupply));
      }
      if (tokenInitialSupply !== null) {
        dispatch(
          fieldTokenInitialSupply(
            new BigNumber(
              token.protocolFields.initial_token_supply as unknown as string,
            ).dividedBy(BigNumber(10).exponentiatedBy(tokenDecimal)),
          ),
        );
      }
      prevDecimals.current = tokenDecimal;
    } else if (
      wallet &&
      token &&
      tokenDecimal &&
      previousDecimals &&
      previousDecimals.isNaN() &&
      !BigNumber(tokenDecimal).isNaN()
    ) {
      dispatch(
        fieldTokenInitialSupply(
          new BigNumber(
            token.protocolFields.initial_token_supply as unknown as string,
          ).dividedBy(BigNumber(10).exponentiatedBy(tokenDecimal)),
        ),
      );
      const max: string | undefined = token?.extraField(
        TokenFields.MAXIMUM_SUPPLY,
      );
      dispatch(
        fieldTokenMaxSupply(
          new BigNumber(Number(max)).dividedBy(
            BigNumber(10).exponentiatedBy(tokenDecimal),
          ),
        ) ?? null,
      );
      prevDecimals.current = tokenDecimal;
    }
  }, [
    tokenDecimal,
    dispatch,
    token,
    wallet,
    tokenMaxSupply,
    tokenInitialSupply,
    previousDecimals,
  ]);
  useEffect(() => {
    if (!token || token === null) {
      if (REACT_APP_MODE !== 'devnet') setTokenType(TokenTypeEnums.FUNGIBLE);
      setPropertyStates(new Array(properties[tokenType]?.length).fill(false));
      setL1States(new Array(L1Tokens[tokenType]?.length).fill(false));
      dispatch(fieldTokenInitialSupply(null));
      dispatch(fieldTokenMaxSupply(null));
      dispatch(fieldTokenDecimal(BigNumber(18)));
      dispatch(fieldTokenName(''));
      dispatch(fieldTokenSymbol(''));
      dispatch(fieldTokenDescription(''));
      setFees(tokenFees[0].value);
      setShard('');
      setBondingCurveData(undefined);
      dispatch(fieldFungibleTokenImg(undefined));
    }
  }, [token, tokenType, dispatch]);

  useEffect(() => {
    if (previousToken && token && previousToken.hashId === token.hashId) {
      return;
    }
    if (wallet && token) {
      setPreview(true);
      if (isSTBL) {
        dispatch(fieldTokenInitialSupply(BigNumber(0)));
        dispatch(fieldTokenDecimal(BigNumber(18)));
      } else {
        setShard('elrond');
        const exponentStr = token?.extraField(TokenFields.EXPONENT);
        const exponent = exponentStr ? BigNumber(exponentStr) : BigNumber(18);
        dispatch(fieldTokenDecimal(exponent));

        dispatch(
          fieldTokenInitialSupply(
            new BigNumber(
              token.protocolFields.initial_token_supply as unknown as string,
            ).dividedBy(BigNumber(10).exponentiatedBy(exponent)),
          ),
        );
        const desc: string | undefined = token?.extraField(
          TokenFields.DESCRIPTION,
        );
        dispatch(fieldTokenDescription(desc ?? ''));

        const max: string | undefined = token?.extraField(
          TokenFields.MAXIMUM_SUPPLY,
        );
        dispatch(
          fieldTokenMaxSupply(
            new BigNumber(Number(max)).dividedBy(
              BigNumber(10).exponentiatedBy(exponent),
            ),
          ) ?? null,
        );
        const extraFees: string | undefined = token?.extraField(
          TokenFields.FEE,
        );
        setFees(extraFees && extraFees !== '' ? Number(extraFees) : undefined);
        const Editibility: string | undefined = token?.extraField(
          TokenFields.EDITIBILITY_TYPE,
        );
        setEditibilityType(editibilityStrtoNumber(Editibility));
        const extraTokenType: TokenTypeEnums = token?.extraField(
          TokenFields.TOKEN_TYPE,
        ) as TokenTypeEnums;
        setTokenType(extraTokenType ?? TokenTypeEnums.FUNGIBLE);
        const icon: string | undefined = token?.extraField(TokenFields.ICON);
        setIpfsAddPin(icon);

        const extraProperties: string | undefined = token?.extraField(
          TokenFields.PROPERTY,
        );

        const newPropertyStates = new Array(
          properties[extraTokenType]?.length,
        ).fill(false);

        properties[extraTokenType]?.forEach((prop, index) => {
          if (extraProperties?.includes(prop.value)) {
            newPropertyStates[index] = true;
          }
        });
        setPropertyStates(newPropertyStates);

        const extraL1s: string | undefined = token?.extraField(
          TokenFields.L1_TOKEN_TYPE,
        );

        const newL1States = new Array(L1Tokens[extraTokenType]?.length).fill(
          false,
        );

        L1Tokens[extraTokenType]?.forEach((l1, index) => {
          if (extraL1s?.includes(l1.value)) {
            newL1States[index] = true;
          }
        });
        setL1States(newL1States);
        if (token?.bondingCurve) {
          const newBuy = token.bondingCurve.buy.poly;
          const newSell = token.bondingCurve.sell.poly;
          const newDomain = token.bondingCurve.domain;
          const newProfit = token.bondingCurve.profit;
          const newSupply = token.bondingCurve.supply;

          const newX0 = findQuadraticX0(newSell[2], newSell[1], newSell[0]);

          setBondingCurveData({
            supply: newSupply,
            profit: newProfit,
            quadratic: {
              buy: {
                a: newBuy[2],
                b: newBuy[1],
                c: newBuy[0],
              },
              sell: {
                a: newSell[2],
                b: newSell[1],
                c: newSell[0],
              },
              x0: newX0,
              domain: newDomain,
            },
          });
        } else {
          setBondingCurveData(undefined);
        }
      }
    } else {
      setPreview(false);
      handleCreation(false);
      dispatch(fieldTokenInitialSupply(null));
      setShard('');
      dispatch(fieldFungibleTokenImg(undefined));
    }
  }, [wallet, token, previousToken, isSTBL, dispatch, handleCreation]);

  const centeredBoxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (creation) {
      const centeredBox = centeredBoxRef.current;

      if (centeredBox) {
        const { width, height } = centeredBox.getBoundingClientRect();
        if (Math.min(height, width) < 400)
          setQrSize(Math.min(height, width) - 80);
        else setQrSize(((Math.min(height, width) - 180) * 3) / 4);
      }
    }
  }, [creation, sidebarOpen]);

  const handlePreview = () => {
    const newArr = [...fillError];
    if (tokenName.length === 0) newArr[Index.TOKEN_NAME] = true;
    else newArr[Index.TOKEN_NAME] = false;
    if (tokenSymbol.length === 0) newArr[Index.TOKEN_SYMBOL] = true;
    else newArr[Index.TOKEN_SYMBOL] = false;
    if (tokenInitialSupply === null || tokenInitialSupply.isNaN())
      newArr[Index.INITIAL_SUPPLY] = true;
    else newArr[Index.INITIAL_SUPPLY] = false;
    if (tokenMaxSupply === null || tokenMaxSupply.isNaN())
      newArr[Index.MAXIMUM_SUPPLY] = true;
    else newArr[Index.MAXIMUM_SUPPLY] = false;
    if (tokenDecimal.isNaN()) newArr[Index.DECIMALS] = true;
    else newArr[Index.DECIMALS] = false;
    if (
      tokenInitialSupply &&
      tokenMaxSupply &&
      tokenMaxSupply.isLessThan(tokenInitialSupply)
    ) {
      dispatch(fieldTokenMaxSupply(tokenInitialSupply));
      newArr[Index.MAX_SUPPLY_LESS_THAN_INITIAL_SUPPLY] = true;
    } else newArr[Index.MAX_SUPPLY_LESS_THAN_INITIAL_SUPPLY] = false;
    if (!token && !fungibleCurrentImg) {
      newArr[Index.TOKEN_ICON] = true;
    } else newArr[Index.TOKEN_ICON] = false;
    setFillError(newArr);
    if (newArr.every((v) => v === false)) setPreview(true);
  };

  const handleProperties = (value: boolean, index: number) => {
    const newPropertyStates = [...propertyStates];
    newPropertyStates[index] = value;
    setPropertyStates(newPropertyStates);
  };

  const handleL1Tokens = (value: boolean, index: number) => {
    const newL1States = [...l1States];
    newL1States[index] = value;
    setL1States(newL1States);
  };

  const mutation = useMutation(
    async (variables: { arg: string | ArrayBuffer; creation: Boolean }) =>
      request(REACT_APP_IPFS_API_ENDPOINT, createIpfsAddPinQuery, {
        input: variables.arg,
      }),
    {
      onSuccess: (mutationData: any) => {
        setIpfsAddPin(mutationData.ipfsAddPin as string);
        onBeWarning(false);
      },
      onError: () => {
        onBeWarning(true);
      },
    },
  );

  const uploadImage = async (e: any) => {
    if (uploadType === 0) {
      const files = Array.prototype.slice.call(e.target.files);
      await Promise.all(
        files.map(async (file: File) => {
          await convertBase64(file).then((res) => {
            if (typeof res === 'string') {
              dispatch(fieldFungibleTokenImg(res));
            }
          });
        }),
      );
    } else {
      dispatch(fieldFungibleTokenImg(undefined));
    }
  };

  const handleIssuanceQR = (
    update: boolean,
    json: string,
    tokenCMD: TokenUiCommand | undefined,
  ) => {
    setTokenQrData(json);
    if (tokenCMD) {
      setTokenCommand(tokenCMD);
    }
    setPreview(false);
    handleCreation(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCreate = () => {
    const hexInitialSupply = intToHexString(tokenInitialSupply, 64);

    const extraFields = [
      {
        name: TokenFields.EXPONENT,
        content: tokenDecimal.toFixed(),
      } as Field,
      { name: TokenFields.NAME, content: tokenName || '' } as Field,
      {
        name: TokenFields.CODE,
        content: tokenSymbol || '',
      } as Field,
      {
        name: TokenFields.EDITIBILITY_TYPE,
        content: editibilityType ? String(editibilityType) : '',
      } as Field,
      {
        name: TokenFields.TOKEN_TYPE,
        content: tokenType,
      } as Field,
      { name: TokenFields.FEE, content: fee ? String(fee) : '' },
      {
        name: TokenFields.DESCRIPTION,
        content: tokenDescription ?? '',
      } as Field,
      {
        name: TokenFields.MAXIMUM_SUPPLY,
        content: tokenMaxSupply
          ? tokenMaxSupply
              .multipliedBy(BigNumber(10).exponentiatedBy(tokenDecimal))
              .toFixed()
          : '',
      } as Field,
      {
        name: TokenFields.L1_TOKEN_TYPE,
        content: decodeL1s(l1States, tokenType),
      } as Field,
      {
        name: TokenFields.PROPERTY,
        content: decodeProperties(propertyStates, tokenType),
      } as Field,
    ] as ExtraFields;

    if (!token) {
      // token is at creation state
      if (fungibleCurrentImg) {
        // with image
        mutation.mutate(
          { arg: fungibleCurrentImg, creation: true },
          {
            onSuccess: (data: any) => {
              if (typeof data === 'object') {
                extraFields.push({
                  name: TokenFields.ICON,
                  content: data.ipfsAddPin as string,
                } as Field);
                setLoading(false);
                const creationJson = createTokenJson(
                  pubKey,
                  hexInitialSupply,
                  tokenDecimal,
                  extraFields,
                );
                let creationCmd;
                if (payButtonAvailibility) {
                  creationCmd = createTokenCommand(
                    pubKey,
                    hexInitialSupply,
                    tokenDecimal,
                    extraFields,
                  );
                }
                handleIssuanceQR(false, creationJson, creationCmd);
              }
            },
          },
        );
      } else {
        const creationJson = createTokenJson(
          pubKey,
          hexInitialSupply,
          tokenDecimal,
          extraFields,
        );
        let creationCmd;
        if (payButtonAvailibility) {
          creationCmd = createTokenCommand(
            pubKey,
            hexInitialSupply,
            tokenDecimal,
            extraFields,
          );
        }
        handleIssuanceQR(false, creationJson, creationCmd);
      }
    } else if (
      fungibleCurrentImg === token.getBaseIcon() // no update at icon
    ) {
      extraFields.push({
        name: TokenFields.ICON,
        content: ipfsAddPin,
      } as Field);
      const updateJson = updateTokenJson(token.hashId, extraFields);
      setTokenQrData(updateJson);
      let updateTokenCmd;
      if (payButtonAvailibility) {
        updateTokenCmd = updateTokenCommand(token.hashId, extraFields);
        setTokenCommand(updateTokenCmd);
      }
      handleIssuanceQR(true, updateJson, updateTokenCmd);
    } else if (
      fungibleCurrentImg &&
      fungibleCurrentImg !== token.getBaseIcon()
    ) {
      mutation.mutate(
        { arg: fungibleCurrentImg, creation: false },
        {
          onSuccess: (data: any) => {
            if (typeof data === 'object') {
              extraFields.push({
                name: TokenFields.ICON,
                content: data.ipfsAddPin as string,
              } as Field);
              setLoading(false);
              const updateJson = updateTokenJson(token.hashId, extraFields);
              setTokenQrData(updateJson);
              let updateTokenCmd;
              if (payButtonAvailibility) {
                updateTokenCmd = updateTokenCommand(token.hashId, extraFields);
                setTokenCommand(updateTokenCmd);
              }
              handleIssuanceQR(true, updateJson, updateTokenCmd);
            }
          },
        },
      );

      if (mutation.isLoading) {
        setLoading(true);
      }
    }
  };

  if (preview && loading)
    return (
      <CenteredBox>
        <LoaderSection
          tokenName={tokenName}
          verb="create"
          onClose={setPreview}
        />
      </CenteredBox>
    );
  if (preview)
    return (
      <BasicIssuancePreview
        isSTBL={isSTBL}
        tokenName={tokenName}
        tokenSymbol={tokenSymbol}
        tokenHashId={token?.hashId}
        editibilityType={editibilityType}
        // tokenInitialSupply={tokenInitialSupply}
        // tokenMaxSupply={tokenMaxSupply}
        // tokenDecimal={tokenDecimal}
        description={tokenDescription}
        tokenType={tokenType}
        propertyStates={propertyStates}
        l1States={l1States}
        fee={fee}
        issued={!!token || tokenCreation}
        setPreview={setPreview}
        setCreation={handleCreation}
        handleCreate={handleCreate}
        bondingCurve={bondingCurveData}
        pubkey={pubKey}
        baseImg={fungibleCurrentImg}
        ipfsAddPin={ipfsAddPin}
      />
    );

  if (creation)
    return (
      <CenteredBox
        ref={centeredBoxRef}
        sx={{ height: '100vh' }}
        className="your-centered-box-class"
      >
        <CreationSection
          command={tokenCommand}
          tokenName={tokenName}
          verb={token ? 'update' : 'create'}
          data={tokenQrData ?? ''}
          onClose={setPreview}
          button={payButtonAvailibility}
          size={qrSize}
          operationType={
            token
              ? OperationType.CUSTOM_TOKEN_UPDATED
              : OperationType.CREATE_CUSTOM_TOKEN
          }
        />
      </CenteredBox>
    );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minHeight="100vh"
      padding="0px 20px"
    >
      {(REACT_APP_MODE === 'pro' || REACT_APP_MODE === 'devnet') && (
        <>
          <GridContainerBox>
            <EditibilitySection
              editibilityType={editibilityType}
              onEditibilityChange={setEditibilityType}
            />
            <DAOSection dao={dao} onDAOChange={setDAO} />
          </GridContainerBox>
          <PropertiesSection
            tokenType={tokenType}
            propertyStates={propertyStates}
            handleProperties={handleProperties}
          />
          <L1Section
            tokenType={tokenType}
            l1States={l1States}
            handleL1Tokens={handleL1Tokens}
          />
        </>
      )}

      {tokenType === TokenTypeEnums.MULTI ? (
        <>
          <TokenImage>
            <Typography variant="h2">
              Token Image <InfoIcon title={tokenImageInfoText} />
            </Typography>

            <ImageUploadSection
              uploadType={uploadType}
              onUploadTypeChange={setUploadType}
            />
            {!Number(uploadType) ? (
              <CenteredBox>
                <ImagePreviewBox baseImg={fungibleCurrentImg} />{' '}
              </CenteredBox>
            ) : (
              <InputTokenization placeholder="URL" variant="outlined" />
            )}
            <CenteredBox>
              <BeigeButton variant="outlined" component="label">
                {fungibleCurrentImg === undefined || fungibleCurrentImg === null
                  ? 'Upload Image'
                  : 'Change Image'}
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={(e) => {
                    uploadImage(e);
                  }}
                  accept="image/jpg, image/jpeg, image/png, image/webp "
                />
              </BeigeButton>
              <Typography
                fontSize={12}
                fontWeight={500}
                sx={{ marginTop: '20px', color: '#adadd0' }}
              >
                JPEG, PNG, WEBP, Max 30mb.
              </Typography>
            </CenteredBox>
          </TokenImage>
          <SubtokenSection subtoken={subtoken} onSubtokenChange={setSubtoken} />
        </>
      ) : (
        <GridContainerBox>
          <TokenImage>
            <Typography variant="h2">
              Token Image <InfoIcon title={tokenImageInfoText} />
            </Typography>

            {tokenType === TokenTypeEnums.NONFUNGIBLE ? (
              <ImageUploadSection
                uploadType={uploadType}
                onUploadTypeChange={setUploadType}
              />
            ) : undefined}

            {!Number(uploadType) ? (
              <CenteredBox>
                <ImagePreviewBox baseImg={fungibleCurrentImg} />
              </CenteredBox>
            ) : (
              <InputTokenization placeholder="URL" variant="outlined" />
            )}
            <CenteredBox>
              <BeigeButton variant="outlined" component="label">
                Upload Image
                <input
                  type="file"
                  hidden
                  multiple={tokenType !== TokenTypeEnums.FUNGIBLE}
                  onChange={(e) => {
                    uploadImage(e);
                  }}
                  accept="image/jpg, image/jpeg, image/png, image/webp "
                />
              </BeigeButton>
              <Typography
                fontSize={12}
                fontWeight={500}
                sx={{ marginTop: '20px', color: '#adadd0' }}
              >
                JPEG, PNG, WEBP, Max 30mb.
              </Typography>
              {fillError[6] && (
                <FormHelperText
                  error
                  sx={{ fontSize: 16, textAlign: 'center' }}
                >
                  Please upload your token image.
                </FormHelperText>
              )}
            </CenteredBox>
          </TokenImage>

          <TokenDetails>
            <Typography variant="h2">
              Token Details
              <InfoIcon title={tokenDetailsInfoText} />
            </Typography>
            <InputTokenization
              error={tokenName.length >= 41 || fillError[0]}
              inputProps={{ maxLength: 41 }}
              placeholder="Token Name"
              label="Token Name *"
              variant="outlined"
              sx={{ marginTop: '20px' }}
              value={tokenName}
              onChange={(e) => {
                if (e.target.value.length > 40) {
                  handleTokenSymbolErrorText(fillError[1], e.target.value);
                  dispatch(fieldTokenName(e.target.value));
                  setTimeout(() => {
                    dispatch(fieldTokenName(e.target.value.slice(0, 40)));
                  }, 1000);
                } else dispatch(fieldTokenName(e.target.value));
              }}
              helperText={handleTokenNameErrorText(fillError[0], tokenName)}
            />
            {tokenType === TokenTypeEnums.FUNGIBLE ? (
              <>
                <InputTokenization
                  error={tokenSymbol.length > 10 || fillError[1]}
                  inputProps={{ maxLength: 11 }}
                  helperText={handleTokenSymbolErrorText(
                    fillError[1],
                    tokenSymbol,
                  )}
                  label="Token Symbol *"
                  placeholder="Token Symbol"
                  variant="outlined"
                  sx={{ marginTop: '20px' }}
                  value={tokenSymbol}
                  onChange={(e) => {
                    if (e.target.value.length > 10) {
                      handleTokenSymbolErrorText(fillError[1], e.target.value);
                      dispatch(fieldTokenSymbol(e.target.value));
                      setTimeout(() => {
                        dispatch(fieldTokenSymbol(e.target.value.slice(0, 10)));
                      }, 1000);
                    } else dispatch(fieldTokenSymbol(e.target.value));
                  }}
                />
                <NumericInput
                  containerSx={{ marginTop: '10px' }}
                  label="Initial Supply *"
                  sx={{ width: '100%', marginTop: '10px' }}
                  placeholder="Initial Supply"
                  value={tokenInitialSupply}
                  maxValue={supplyLimit}
                  allowNegative={false}
                  allowPositive
                  onChange={fieldTokenInitialSupply}
                  error={fillError[2]}
                  errorText={handleInitialSupplyErrorText(
                    fillError[2],
                    supplyLimit,
                  )}
                  infoText="Initial supply can not be changed after creation."
                  disabled={!!token || tokenCreation}
                  dispatch
                />
                <NumericInput
                  error={fillError[3]}
                  label="Maximum Supply *"
                  sx={{ width: '100%', marginTop: '10px' }}
                  placeholder="Maximum Supply"
                  value={tokenMaxSupply}
                  onChange={fieldTokenMaxSupply}
                  allowNegative={false}
                  allowPositive
                  maxValue={supplyLimit}
                  minError={fillError[5]}
                  inputLabelSx={{ marginTop: '20px' }}
                  errorText={handleMaximumSupplyErrorText(
                    fillError[3],
                    supplyLimit,
                  )}
                  dispatch
                  containerSx={{ marginTop: '10px' }}
                  minErrorText="Maximum supply can not be less than initial supply."
                />
                <NumericInput
                  containerSx={{ marginTop: '10px' }}
                  error={fillError[4]}
                  label="Decimals *"
                  sx={{ width: '100%', marginTop: '10px' }}
                  placeholder="Decimals"
                  value={tokenDecimal}
                  allowNegative={false}
                  dispatch
                  allowPositive
                  helperText="18 decimals recommended."
                  maxValue={BigNumber(40)}
                  inputLabelSx={{ marginTop: '10px' }}
                  errorText={
                    fillError[4]
                      ? 'Please fill out Decimals field.'
                      : 'Decimals exceeds the limit of 40.'
                  }
                  onChange={fieldTokenDecimal}
                />
                <InputDescription
                  error={!!tokenDescription && tokenDescription?.length > 200}
                  inputProps={{ maxLength: 201 }}
                  helperText={
                    !!tokenDescription && tokenDescription?.length > 200
                      ? 'Description exceeds character limit of 200.'
                      : undefined
                  }
                  label="Description"
                  sx={{ marginTop: '20px' }}
                  placeholder="Enter a brief description of the token. Max 200 character."
                  variant="outlined"
                  multiline
                  minRows={3}
                  value={tokenDescription}
                  onChange={(e) => {
                    if (e.target.value.length > 200) {
                      dispatch(fieldTokenDescription(e.target.value));
                      setTimeout(() => {
                        dispatch(
                          fieldTokenDescription(e.target.value.slice(0, 200)),
                        );
                      }, 1000);
                    } else dispatch(fieldTokenDescription(e.target.value));
                  }}
                />
              </>
            ) : (
              <>
                <NumericInput
                  label="Initial Supply"
                  sx={{ width: '100%', marginTop: '10px' }}
                  placeholder="1"
                  dispatch
                  allowPositive
                  value={tokenInitialSupply}
                  onChange={fieldTokenInitialSupply}
                />
                <Typography variant="h2" className="mt-5">
                  Properties
                </Typography>
                <GridContainerBox>
                  <InputTokenization
                    placeholder="Ex: Size"
                    variant="outlined"
                  />
                  <InputTokenization placeholder="Ex: M " variant="outlined" />
                  <InputTokenization
                    sx={{ width: '100%', marginTop: '-10px' }}
                    placeholder="Ex: Hair color"
                    variant="outlined"
                  />
                  <InputTokenization
                    sx={{ width: '100%', marginTop: '-10px' }}
                    placeholder="Ex: Black"
                    variant="outlined"
                  />
                  <InputTokenization
                    sx={{ width: '100%', marginTop: '-10px' }}
                    placeholder="Ex: Age"
                    variant="outlined"
                  />
                  <InputTokenization
                    sx={{ width: '100%', marginTop: '-10px' }}
                    placeholder="Ex: 10"
                    variant="outlined"
                  />
                </GridContainerBox>
                <InputDescription
                  label="Description"
                  placeholder="1"
                  variant="outlined"
                  multiline
                  minRows={3}
                  value={tokenDescription}
                  onChange={(e) =>
                    dispatch(fieldTokenDescription(e.target.value))
                  }
                />
              </>
            )}
          </TokenDetails>
        </GridContainerBox>
      )}

      {(REACT_APP_MODE === 'pro' || REACT_APP_MODE === 'devnet') && (
        <>
          <TokenFeePaymentSection fee={fee} onFeesChange={setFees} />
          <ShardSection shard={shard} onShardChange={setShard} />
        </>
      )}
      <div className="mt-5 w-full text-center">
        <BlueButton type="submit" variant="outlined" onClick={handlePreview}>
          Preview Token
        </BlueButton>
      </div>
    </Box>
  );
};
