import { arrayOf, bool, node, shape, string } from 'prop-types';

const itemShape = {
  disabled: bool,
  text: string.isRequired,
  url: string.isRequired,
  icon: node,
};

itemShape.items = arrayOf(shape(itemShape));

export const SidebarItemType = shape(itemShape);
