import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import commonStyles from 'styles/commonStyles.module.scss';
import { routePaths } from 'routePaths';
import { useSelector } from 'react-redux';
import variables from 'styles/variables.module.scss';
import { useIsMediumScreen, useIsMobile } from 'hooks/useMediaQuery';
import styles from './TokenizationHeader.module.scss';
import { IssuedToken } from './IssuedToken';

export const TokenizationHeader = () => {
  const isMobile = useIsMobile();
  const isMediumScreen = useIsMediumScreen();
  const { tokenNotifications } = useSelector((state: any) => state.token);
  const navigate = useNavigate();

  const redirectToNotifications = () =>
    navigate(routePaths.TOKENIZATION.NOTIFICATIONS);

  return (
    <AppBar
      position="fixed"
      sx={{
        paddingLeft: variables.sidebarWidth,
        width: isMobile ? '-webkit-fill-available' : '100%',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      elevation={0}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isMobile ? 'flex-end' : 'space-between'}
        className={styles.header}
        pl={isMobile ? 0 : 5}
        pr={isMobile ? 0 : 5}
        width="-webkit-fill-available"
      >
        <Box mr={2}>
          <Typography fontSize="16px">Welcome!</Typography>
          <Typography className={styles.header_text}>
            Let’s create tokens
          </Typography>
        </Box>

        <Box display="flex">
          {isMediumScreen ? <IssuedToken /> : null}

          {/* {isMobile || REACT_APP_MODE === 'retail' ? null : (
            <SelectNetwork disableUnderline defaultValue={0} variant="standard">
              <MenuItem value={0}>Betanet</MenuItem>
            </SelectNetwork>
          )} */}
          <Tooltip title="View notifications">
            <IconButton
              onClick={redirectToNotifications}
              sx={{ color: 'white' }}
            >
              <Badge
                badgeContent={
                  tokenNotifications?.filter((item: any) => !item.read).length
                }
                sx={{ color: '#00e1d4' }}
              >
                <NotificationsIcon className={commonStyles.mutedText} />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </AppBar>
  );
};
