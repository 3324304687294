import { Typography, Box } from '@mui/material';
import DefaultIconLogo from 'images/defaultTokenIcon.svg';
import { CenteredBox } from '../../../components/Containers/Box';
import styles from '../Liquidity.module.scss';

type Props = {
  tokenName: string;
  tokenSymbol: string;
  baseImg: string | undefined;
};

export const PreviewLiquidityTokenSection = (props: Props) => {
  return (
    <CenteredBox>
      <Typography
        fontWeight={600}
        sx={{
          width: 'fit-content',
          fontSize: '16px',
          marginLeft: 'auto !important',
          marginRight: 'auto  !important',
        }}
        align="center"
      >
        Preview Bonding Curve
      </Typography>

      <img
        className={styles.previewImg}
        src={props.baseImg ? props.baseImg : DefaultIconLogo}
        alt="token_image"
        width={100}
      />

      <Box display="flex">
        <Typography
          fontWeight={600}
          sx={{
            width: 'fit-content',
            fontSize: '18px',
            margin: '20px auto 30px auto',
          }}
          align="center"
        >
          {props.tokenName} - {props.tokenSymbol}
        </Typography>
      </Box>
    </CenteredBox>
  );
};
