import {
  LinearProgress,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Table,
  TableBody,
} from '@mui/material';

import { formatNumberWithSeparators } from 'utils/formatters';
import styles from './Table.module.scss';
import { Row } from './types';

type Props = {
  rows: Row[];
};

export const AnalyticsTable = (props: Props) => {
  return (
    <TableContainer className={styles.tableContainer}>
      <Table sx={{ minWidth: 650, overflowX: 'scroll' }}>
        <TableHead>
          <TableRow>
            <TableCell className={styles.tableCellHead}>Rank</TableCell>
            <TableCell className={styles.tableCellHead} align="left">
              Address
            </TableCell>
            <TableCell className={styles.tableCellHead} align="left">
              Quantity
            </TableCell>
            <TableCell className={styles.tableCellHead} align="left">
              Percentage
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.rank}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className={styles.tableCell}>{row.rank}</TableCell>
              <TableCell className={styles.tableCell} align="left">
                {row.address}
              </TableCell>
              <TableCell className={styles.tableCell} align="left">
                {formatNumberWithSeparators(Number(row.quantity))}
              </TableCell>
              <TableCell className={styles.tableCell} align="left">
                {row.percentage}%{' '}
                <LinearProgress
                  variant="determinate"
                  value={row.percentage}
                  classes={{ root: styles.progressBar, bar: styles.bar }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
