import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {
  issuingModelSetupInfotext,
  bondingCurveInfotext,
  liquidityPoolInfotext,
  bcPriceSetupComingInfoText,
} from 'texts';
import { pricingTypes } from '../liquidityTypes';
import {
  BpCheckedIconRadio,
  BpIconRadio,
} from '../../../components/ControlButtons/Radio';
import { GradientBox } from '../../../components/Containers/Box';
import { InfoIcon } from '../../../components/Icons/Icons';

type Props = {
  value: pricingTypes;
  onChange: FormControlLabelProps['onChange'];
  mode: string;
};

export const PricingSetupSection = (props: Props) => {
  return (
    <GradientBox>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant="h2">Issuing Model Setup</Typography>
        <InfoIcon title={issuingModelSetupInfotext} />
      </Box>

      <FormControl>
        <RadioGroup value={props.value}>
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              value={pricingTypes.BONDING_CURVE}
              control={
                <Radio
                  icon={<BpIconRadio />}
                  checkedIcon={<BpCheckedIconRadio />}
                />
              }
              label={
                <Typography variant="body2">
                  Bonding Curve
                  <InfoIcon title={bondingCurveInfotext} />
                </Typography>
              }
              onChange={props.onChange}
            />
          </Box>
          {props.mode === 'pro' ? (
            <FormControlLabel
              value={pricingTypes.LIQUIDITY_POOL}
              control={
                <Radio
                  icon={<BpIconRadio />}
                  checkedIcon={<BpCheckedIconRadio />}
                />
              }
              label={
                <Typography variant="body2">
                  Liquidity pool <InfoIcon title={liquidityPoolInfotext} />
                </Typography>
              }
              onChange={props.onChange}
            />
          ) : (
            <FormControlLabel
              disabled
              control={
                <Radio
                  icon={<BpIconRadio />}
                  checkedIcon={<BpCheckedIconRadio />}
                />
              }
              label={
                <Typography variant="body2">
                  Coming <InfoIcon title={bcPriceSetupComingInfoText} />
                </Typography>
              }
              onChange={props.onChange}
            />
          )}
        </RadioGroup>
      </FormControl>
    </GradientBox>
  );
};
