import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Owner } from 'types/DAO';
import { CopyButton } from 'components/CopyButton';
import commonStyles from 'styles/commonStyles.module.scss';

type Props = {
  index: number;
  owners: Owner[];
  setOwners: (value: Owner[]) => void;
};

const ModalComponent = (props: Props) => {
  const { index, owners, setOwners } = props;
  const [name, setName] = useState<string>(owners[index].name);

  const modal = useModal();
  useEffect(() => {
    setName(owners[index].name);
  }, [index, owners]);

  const handleSave = () => {
    const updatedOwners = [...owners];
    updatedOwners[index] = {
      ...updatedOwners[index],
      name,
    };
    setOwners(updatedOwners);
    modal.hide();
  };

  return (
    <Modal open={modal.visible} onClose={modal.hide}>
      <Paper
        className={commonStyles.modalContent}
        sx={{ width: '40%', maxWidth: '30rem' }}
      >
        <Typography variant="h6" mb={1}>
          Edit owner name
        </Typography>
        <IconButton className="absolute top-2 right-2" onClick={modal.hide}>
          <CloseIcon />
        </IconButton>
        <TextField
          fullWidth
          // defaultValue={owners[index].name}
          label="Owner name"
          placeholder="Owner name"
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <Box display="flex" mt={3}>
          <TextField
            disabled
            fullWidth
            defaultValue={owners[index].address}
            label="Address"
            placeholder="Address"
            variant="standard"
          />
          <CopyButton text={owners[index].address} hint="Copy address" />
        </Box>

        <Box display="flex" justifyContent="center" mt={3} mb={2}>
          <Button
            variant="contained"
            color="inherit"
            sx={{ mr: 3 }}
            onClick={modal.hide}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

ModalComponent.displayName = 'OwnersModal';

export const OwnersModal = NiceModal.create(ModalComponent);
