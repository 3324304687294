import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, FormControlLabelProps } from '@mui/material';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import {
  ExtraFields,
  TokenUiCommand,
  force_refresh as forceRefresh,
} from '@coinweb/cweb-wallet-library';
import WarningModal from 'components/Modals/WarningModal';
import { REACT_APP_MODE, REACT_APP_PAY_BUTTON } from 'conf';
import { useSetState } from 'hooks/useSetState';
import { useWalletFromParams } from 'hooks/useWalletFromParams';
import { useCurrentToken } from 'hooks/useCurrentToken';
import { BondingCurveFields } from 'enums';
import { bondingCurveWarningText } from 'texts';
import { usePrevious } from 'hooks/usePrevious';
import { useNavigateIssuance } from 'hooks/useNavigateIssuance';
import { useIsMediumScreen, useIsMobile } from 'hooks/useMediaQuery';
import { OperationType } from 'types/operationType';
import {
  handleQuadraticCheck,
  createBondingCurveCommand,
  createBondingCurveJson,
} from './liquidityUtils';
import { PoolSetupSection } from './sections/PoolSetupSection';
import { PriceTypeSection } from './sections/PriceTypeSection';
import { CreationSection } from '../BasicIssuance/Sections';
import {
  chainTypes,
  flowTypes,
  priceTypes,
  pricingTypes,
  Quadratic,
} from './liquidityTypes';
import { ChainPairsSection } from './sections/ChainPairsSection';
import { LiquiditySetupSection } from './sections/LiquiditySetupSection';
import { PriceSettingsSection } from './sections/PriceSettingsSection';
import { PriceParameterSummarySection } from './sections/PriceParameterSummarySection';
import { PriceFormulaSummarySection } from './sections/PriceFormulaSummarySection';
import { PriceGraphsSection } from './sections/PriceGraphsSection';
import { PricingSetupSection } from './sections/PricingSetupSection';
import { FlowTypeSection } from './sections/FlowTypeSection';
import { BlueButton } from '../../components/ControlButtons/Button';
import { CenteredBox } from '../../components/Containers/Box';
import { LiquidityPreview } from './LiquidityPreview';

export const LiquidityPage = () => {
  const { tokenSymbol, tokenDecimal } = useSelector(
    (state: any) => state.token,
  );

  const isMobile = useIsMobile();
  const isMediumScreen = useIsMediumScreen();
  const [wallet] = useWalletFromParams();
  const [token] = useCurrentToken();
  const previousToken = usePrevious(token);
  const componentRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const payButtonAvailability = REACT_APP_PAY_BUTTON === 'true';

  const decimalPrice = BigNumber(18).minus(tokenDecimal);
  useNavigateIssuance();
  const outerDivRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useSetState({
    flowType: flowTypes.ON_CHAIN,
    priceType: priceTypes.FIXED,
    pricingType: pricingTypes.BONDING_CURVE,
    creation: false,
  });

  const [chains, setChains] = useState<chainTypes[]>([
    { chain1: 1, chain2: 1 },
  ]);

  const [extraBondingFields, setExtraBondingFields] = useState<ExtraFields>([]);

  const [quadratic, setQuadratic] = useState<Quadratic>(
    token?.bondingCurve
      ? {
          buy: {
            a: BigNumber(token.bondingCurve.buy.poly[2]),
            b: BigNumber(token.bondingCurve.buy.poly[1]),
            c: BigNumber(token.bondingCurve.buy.poly[0]),
          },
          sell: {
            a: BigNumber(token.bondingCurve.sell.poly[2]),
            b: BigNumber(token.bondingCurve.sell.poly[1]),
            c: BigNumber(token.bondingCurve.sell.poly[0]),
          },
          x0: token.bondingCurve.supply,
          domain: BigNumber(token.bondingCurve.domain),
          kMax: BigNumber(0),
        }
      : {
          buy: {
            a: BigNumber(0),
            b: BigNumber(-1),
            c: BigNumber(0),
          },
          sell: {
            a: BigNumber(0),
            b: BigNumber(-1),
            c: BigNumber(0),
          },
          x0: BigNumber(0),
          domain: BigNumber(7680000000).multipliedBy(
            BigNumber(10).exponentiatedBy(18),
          ),
          kMax: BigNumber(0),
        },
  );

  const [creationData, setCreationData] = useState<string>('');

  const [bondingCurveCommand, setBondingCurveCommand] =
    useState<TokenUiCommand>();

  const [preview, setPreview] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);

  const [premint, setPremint] = useState<BigNumber>(BigNumber(0));
  const [qrSize, setQrSize] = useState<number | undefined>(undefined);

  const handleExtraFieldsChange = useCallback(
    (name: BondingCurveFields, content: string) => {
      if (extraBondingFields.filter((e) => e.name === name).length > 0) {
        setExtraBondingFields((prevState) => {
          const newState = prevState.map((obj) => {
            if (obj.name === name) {
              return { ...obj, content };
            }
            return obj;
          });
          return newState;
        });
      } else {
        setExtraBondingFields((prevState) => [
          ...prevState,
          {
            name,
            content,
          },
        ]);
      }
    },
    [extraBondingFields],
  );

  useEffect(() => {
    const outerDiv = outerDivRef.current;

    if (outerDiv) {
      const { width, height } = outerDiv.getBoundingClientRect();
      if (Math.min(height, width) < 400)
        setQrSize(Math.min(height, width) - 80);
      else setQrSize(Math.min(height, width) - 180);
    }
  }, [outerDivRef, state.creation]);

  useEffect(() => {
    if (!token?.bondingCurve && location.state?.edit === undefined)
      setPreview(false);
    else setPreview(!location.state?.edit);
  }, [location.state?.edit, token?.bondingCurve]);

  useEffect(() => {
    if (previousToken && token && previousToken.hashId === token.hashId) {
      return;
    }
    if (wallet && token) {
      setPremint(
        BigNumber(
          token.protocolFields.initial_token_supply as unknown as string,
        ).dividedBy(BigNumber(10).exponentiatedBy(decimalPrice)),
      );
      if (token.bondingCurve) {
        const newBuy = token.bondingCurve.buy.poly;
        const newSell = token.bondingCurve.sell.poly;

        setQuadratic({
          buy: {
            a: newBuy[2],
            b: newBuy[1],
            c: newBuy[0],
          },
          sell: {
            a: newSell[2],
            b: newSell[1],
            c: newSell[0],
          },
          x0: token.bondingCurve.supply,
          domain: token.bondingCurve.domain,
          kMax: BigNumber(0),
        });

        const newCurveType = token?.bondingCurveExtraField(
          BondingCurveFields.TYPE,
        );
        setState({
          priceType: newCurveType
            ? (newCurveType as priceTypes)
            : priceTypes.FIXED,
        });
        const newPrice = token?.bondingCurveExtraField(
          BondingCurveFields.PRICE,
        );

        const newFee = token?.bondingCurveExtraField(BondingCurveFields.FEE);
        setExtraBondingFields([
          {
            name: BondingCurveFields.TYPE,
            content: newCurveType ?? priceTypes.FIXED,
          },
          {
            name: BondingCurveFields.PRICE,
            content:
              newPrice ?? String(BigNumber(10).exponentiatedBy(decimalPrice)),
          },
          {
            name: BondingCurveFields.FEE,
            content: newFee ?? String(0.02),
          },
        ]);
      } else {
        setQuadratic({
          buy: {
            a: BigNumber(0),
            b: BigNumber(-1),
            c: BigNumber(0),
          },
          sell: {
            a: BigNumber(0),
            b: BigNumber(-1),
            c: BigNumber(0),
          },
          x0: BigNumber(0),
          domain: BigNumber(7680000000).multipliedBy(
            BigNumber(10).exponentiatedBy(18),
          ),
          kMax: BigNumber(0),
        });
        setExtraBondingFields([]);
      }
    } else if (!token && wallet) {
      setQuadratic({
        buy: {
          a: BigNumber(0),
          b: BigNumber(-1),
          c: BigNumber(0),
        },
        sell: {
          a: BigNumber(0),
          b: BigNumber(-1),
          c: BigNumber(0),
        },
        x0: BigNumber(0),
        domain: BigNumber(7680000000).multipliedBy(
          BigNumber(10).exponentiatedBy(18),
        ),
        kMax: BigNumber(0),
      });
    }
  }, [
    token,
    wallet,
    token?.bondingCurve,
    setState,
    decimalPrice,
    previousToken,
    tokenDecimal,
  ]);

  const handleFlowTypeChange: FormControlLabelProps['onChange'] = (ev) =>
    setState({ flowType: (ev.target as HTMLInputElement).value as flowTypes });

  const handlePricingTypeChange: FormControlLabelProps['onChange'] = (ev) =>
    setState({
      pricingType: (ev.target as HTMLInputElement).value as pricingTypes,
    });

  const handlePriceTypeChange: FormControlLabelProps['onChange'] = (ev) => {
    setState({
      priceType: (ev.target as HTMLInputElement).value as priceTypes,
    });
    handleExtraFieldsChange(
      BondingCurveFields.TYPE,
      (ev.target as HTMLInputElement).value as unknown as string,
    );
  };

  const handleCreationChange = useCallback(
    (value: boolean) => {
      setState({ creation: value });
      if (value && wallet) {
        forceRefresh(wallet);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    [wallet, setState],
  );

  const handleAddChainPair = () => {
    setChains((prevState) => {
      const newChain = { chain1: 1, chain2: 1 };
      return [...prevState, newChain];
    });
  };

  const handleRemoveChainPair = (removeIndex: number) =>
    setChains(chains.filter((_, index) => index !== removeIndex));

  const handleChangeChainPair1 = (value: number, index: number) => {
    const newState = chains.map((obj, id) => {
      if (id === index) {
        return { ...obj, chain1: value };
      }

      return obj;
    });

    setChains(newState);
  };

  const handleChangeChainPair2 = (value: number, index: number) => {
    const newState = chains.map((obj, id) => {
      if (id === index) {
        return { ...obj, chain2: value };
      }

      return obj;
    });

    setChains(newState);
  };

  const handleCreate = () => {
    setCreationData(
      createBondingCurveJson(
        token ? token.protocolFields.hash_id : '',
        quadratic,
        extraBondingFields,
        decimalPrice,
        state.priceType,
      ),
    );
    setBondingCurveCommand(
      createBondingCurveCommand(
        token ? token.protocolFields.hash_id : '',
        quadratic,
        extraBondingFields,
        decimalPrice,
        state.priceType,
      ),
    );
    setState({ creation: true });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPreview(false);
  };

  if (preview)
    return (
      <LiquidityPreview
        tokenHash={token?.hashId}
        flowType={state.flowType}
        pricingType={state.pricingType}
        priceType={state.priceType}
        priceParams={quadratic}
        setPreview={setPreview}
        setCreation={handleCreationChange}
        handleCreate={handleCreate}
      />
    );

  if (state.creation)
    return (
      <CenteredBox ref={outerDivRef} sx={{ height: '100vh' }}>
        <CreationSection
          verb="create"
          tokenName="bonding curve."
          data={creationData ?? ''}
          onClose={setPreview}
          command={bondingCurveCommand}
          button={payButtonAvailability}
          size={qrSize}
          operationType={OperationType.CUSTOM_TOKEN_LIQUIDITY_UPDATED}
        />
      </CenteredBox>
    );

  return (
    <Box
      ref={componentRef}
      display="flex"
      flexDirection="column"
      width="100%"
      padding="0px 20px"
    >
      {REACT_APP_MODE === 'pro' && (
        <FlowTypeSection
          value={state.flowType}
          onChange={handleFlowTypeChange}
        />
      )}
      {state.flowType === flowTypes.ON_CHAIN ? (
        <Box mt={0}>
          <Box mb={3}>
            <PricingSetupSection
              value={state.pricingType}
              onChange={handlePricingTypeChange}
              mode={REACT_APP_MODE}
            />
          </Box>

          {state.pricingType === pricingTypes.LIQUIDITY_POOL ? (
            <Box mb={3}>
              <LiquiditySetupSection
                disabled={state.pricingType === pricingTypes.LIQUIDITY_POOL}
              />
            </Box>
          ) : null}

          <Box mb={3}>
            <PriceTypeSection
              disabled={state.pricingType === pricingTypes.LIQUIDITY_POOL}
              value={state.priceType}
              onChange={handlePriceTypeChange}
            />
          </Box>
          {pricingTypes.BONDING_CURVE && (
            <>
              <Box
                display={isMobile ? 'block' : 'grid'}
                className={
                  isMediumScreen
                    ? 'w-full grid-cols-2 gap-5'
                    : 'w-full grid-rows-2 gap-5'
                }
              >
                <PriceSettingsSection
                  previousToken={previousToken}
                  token={token}
                  priceType={state.priceType}
                  value={quadratic}
                  decimals={decimalPrice}
                  onChange={setQuadratic}
                  premint={premint}
                  extraFields={extraBondingFields}
                  onExtraFieldsChange={handleExtraFieldsChange}
                />
                <PriceParameterSummarySection
                  priceType={state.priceType}
                  quadratic={quadratic}
                  tokenSymbol={tokenSymbol}
                  decimals={tokenDecimal.toFixed()}
                />
              </Box>

              <PriceFormulaSummarySection value={quadratic} />
              <PriceGraphsSection value={quadratic} yTokenName={tokenSymbol} />
            </>
          )}
          {state.pricingType === pricingTypes.LIQUIDITY_POOL && (
            <Box mb={0}>
              <ChainPairsSection
                disabled={state.pricingType === pricingTypes.LIQUIDITY_POOL}
                values={chains}
                onAdd={handleAddChainPair}
                onRemove={handleRemoveChainPair}
                onChain1Change={handleChangeChainPair1}
                onChain2Change={handleChangeChainPair2}
              />
            </Box>
          )}

          <Box className=" w-full text-center">
            {state.pricingType === pricingTypes.LIQUIDITY_POOL ? (
              <BlueButton variant="outlined">Preview Liquidity Pool</BlueButton>
            ) : (
              <BlueButton
                variant="outlined"
                onClick={() => {
                  if (handleQuadraticCheck(quadratic))
                    navigate('.', {
                      state: {
                        edit: false,
                      },
                    });
                  else {
                    setWarningModal(true);
                  }
                }}
              >
                Preview Bonding Curve
              </BlueButton>
            )}
          </Box>
        </Box>
      ) : (
        <PoolSetupSection
          disabled={state.pricingType === pricingTypes.LIQUIDITY_POOL}
        />
      )}
      <WarningModal
        open={warningModal}
        warningMessage={bondingCurveWarningText}
        onClose={setWarningModal}
      />
    </Box>
  );
};
