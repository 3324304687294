import {
  OnChainQuery,
  OnChainQueryResult,
  query_tokenization_block as queryTokenizationBlock,
  TokenHashId,
  TokenQuery,
  TokenQueryResult,
  Wallet,
} from '@coinweb/cweb-wallet-library';
import { Token } from 'types/Token';
// TODO: Move some of these out into separate files when needed
function isTokenQueryResult(
  value: OnChainQueryResult,
): value is { TokenQueryResult: any } {
  return 'TokenQueryResult' in value;
}

function isProtocolFields(
  value: TokenQueryResult,
): value is { ProtocolFields: any } {
  return 'ProtocolFields' in value;
}

function isLastExtraFields(
  value: TokenQueryResult,
): value is { LastExtraFields: any } {
  return 'LastExtraFields' in value;
}

function isBondingCurve(
  value: TokenQueryResult,
): value is { BondingCurve: any } {
  return 'BondingCurve' in value;
}

function isBondingCurveExtraFields(
  value: TokenQueryResult,
): value is { BondingCurveExtraFields: any } {
  return 'BondingCurveExtraFields' in value;
}

// function isBondingCurveFunds(
//   value: TokenQueryResult,
// ): value is { BondingCurveFunds: any } {
//   return 'BondingCurveFunds' in value;
// }

function isOk(value: any): value is { Ok: any } {
  return 'Ok' in value;
}

function getIfOk<L>(value: { Ok: L } | { Err: any }): L | null {
  return 'Ok' in value ? value.Ok : null;
}

export async function GetTokenInformation(
  wallet: Wallet,
  hashId: TokenHashId,
  prevToken: Token | undefined,
): Promise<Token | undefined> {
  const queries: OnChainQuery[] = [
    {
      TokenQuery: [hashId, TokenQuery.ProtocolFields],
    },
    {
      TokenQuery: [hashId, TokenQuery.LastExtraFields],
    },
    {
      TokenQuery: [hashId, TokenQuery.BondingCurve],
    },
    {
      TokenQuery: [hashId, TokenQuery.BondingCurveExtraFields],
    },
    // {
    //   TokenQuery: [hashId, TokenQuery.BondingCurveFunds],
    // },
  ];

  const onChainQueryResults: OnChainQueryResult[] =
    await queryTokenizationBlock(wallet, queries);

  const tokenResults = onChainQueryResults.flatMap((x) =>
    isTokenQueryResult(x) ? [x.TokenQueryResult] : [],
  );

  const token =
    // response shape checks
    !isProtocolFields(tokenResults[0]) ||
    !isLastExtraFields(tokenResults[1]) ||
    !isBondingCurve(tokenResults[2]) ||
    !isBondingCurveExtraFields(tokenResults[3]) ||
    // !isBondingCurveFunds(tokenResults[3]) ||
    // Ok checks
    !isOk(tokenResults[0].ProtocolFields) ||
    !isOk(tokenResults[1].LastExtraFields)
      ? undefined
      : new Token(
          tokenResults[0].ProtocolFields.Ok,
          tokenResults[1].LastExtraFields.Ok,
          getIfOk(tokenResults[2].BondingCurve),
          getIfOk(tokenResults[3].BondingCurveExtraFields),
          null, // getIfOk(tokenResults[3].BondingCurveFunds),
        );

  if (prevToken && token && prevToken.isEqual(token)) {
    return prevToken;
  }

  return token;
}
