// eslint-disable-next-line import/no-unused-modules
export default function editibilityStrtoNumber(
  myStr: string | undefined,
): 0 | 1 | undefined {
  if (myStr === undefined) {
    return undefined;
  }

  if (myStr === '0') return 0;
  if (myStr === '1') return 1;
  return undefined;
}
