import PropTypes from 'prop-types';
import { Box, IconButton, Modal, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import commonStyles from 'styles/commonStyles.module.scss';

const ModalComponent = (props) => {
  const modal = useModal();

  return (
    <Modal open={modal.visible} onClose={modal.hide}>
      <Paper className={commonStyles.modalContent}>
        <IconButton className="absolute top-2 right-2" onClick={modal.hide}>
          <CloseIcon />
        </IconButton>
        <Box width="30rem" p={5}>
          <Typography textAlign="center">{props.message}</Typography>
        </Box>
      </Paper>
    </Modal>
  );
};

ModalComponent.propTypes = {
  message: PropTypes.string,
};

ModalComponent.displayName = 'AddressBookModal';

export const AddressBookModal = NiceModal.create(ModalComponent);
