import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props = {
  disabled?: boolean;
  hint?: string;
  label?: string;
  text: string | number;
  sx?: object;
};

export const CopyButton = (props: Props) => {
  return (
    <CopyToClipboard text={String(props.text)}>
      <Tooltip title={props.hint || ''}>
        {props.label ? (
          <Button sx={props.sx} disabled={props.disabled} color="secondary">
            <ContentCopyIcon className="marginRight5" />
            {props.label}
          </Button>
        ) : (
          <IconButton sx={props.sx} disabled={props.disabled}>
            <ContentCopyIcon />
          </IconButton>
        )}
      </Tooltip>
    </CopyToClipboard>
  );
};
