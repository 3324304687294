import cx from 'classnames';
import { Dayjs } from 'dayjs';
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import styles from './DatePicker.module.scss';

type Props = {
  className?: string;
  disabled?: boolean;
  inputFormat?: string;
  date: Dayjs | null;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: Dayjs | null) => void;
};

export const DatePicker = ({
  className,
  disabled,
  inputFormat,
  date,
  onChange,
  ...props
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        className={cx(styles.datePicker, className)}
        disabled={disabled}
        inputFormat={inputFormat}
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          OpenPickerIcon: () => (
            <CalendarMonthRoundedIcon
              className={cx(styles.datePickerIcon, {
                [styles.datePickerIconDisabled]: disabled,
              })}
            />
          ),
        }}
        value={date}
        onChange={onChange}
        renderInput={(params) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...params} />
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </LocalizationProvider>
  );
};

DatePicker.defaultProps = {
  inputFormat: 'DD MMM YYYY',
};
