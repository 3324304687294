import { Paper, Stack, Typography } from '@mui/material';

import { OwnersTable } from './OwnersTable';

export const OwnersPage = () => {
  return (
    <Paper elevation={0}>
      <Stack pt={2} pb={6} pl={2} pr={2} spacing={3}>
        <Typography variant="h6" textAlign="center">
          Manage safe owners
        </Typography>
        <Typography variant="body1" mb={1}>
          Add, remove and replace owners or rename existing owners.
        </Typography>
        <OwnersTable />
      </Stack>
    </Paper>
  );
};
