import Typography from '@mui/material/Typography';
import { flowTypes } from '../liquidityTypes';
import {
  GradientBox,
  SpaceBetweenBox,
} from '../../../components/Containers/Box';

type Props = {
  value: flowTypes;
};

export const PreviewFlowTypeSection = (props: Props) => {
  return (
    <GradientBox>
      <SpaceBetweenBox>
        <Typography variant="h2">Flow Type </Typography>
        <Typography variant="h4" fontWeight={600}>
          {props.value}
        </Typography>
      </SpaceBetweenBox>
    </GradientBox>
  );
};
