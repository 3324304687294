import { useState } from 'react';
import { noop } from 'lodash-es';
import dayjs, { Dayjs } from 'dayjs';
import {
  Box,
  FormControl,
  FormControlLabel,
  Divider,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@mui/material';
import { DatePicker } from 'components/DatePicker';
import { NumericInput } from 'components/NumericInput';
import { TimePicker } from 'components/TimePicker';

import {
  BpCheckedIconRadio,
  BpIconRadio,
} from '../components/ControlButtons/Radio';
import { BeigeButton, BlueButton } from '../components/ControlButtons/Button';
import { GradientBox, SpaceBetweenBox } from '../components/Containers/Box';
import { InputTokenization } from '../components/Input/Input';

const amountTypes = {
  FIXED: 'fixed',
  BY_ADDRESS: 'by_address',
};

// const amountSubtypes = {
//   TOTAL: 'total',
//   PER_ADDRESS: 'per_address',
// };

const fundingTypes = {
  MINTING: 'minting',
  WALLET: 'wallet',
};

export const BatchSendPage = () => {
  const [utc] = useState(() => {
    return -new Date().getTimezoneOffset() / 60;
  });

  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const [time, setTime] = useState<Dayjs | null>(dayjs());
  const [nowSelected, setNowSelected] = useState(true);
  const [amountType, setAmountType] = useState(amountTypes.FIXED);
  // const [amountSubtype, setAmountSubtype] = useState(amountSubtypes.TOTAL);
  const [fundingType, setFundingType] = useState(fundingTypes.MINTING);

  const handleDateChange = (newDate: Dayjs | null) => {
    setNowSelected(false);
    setDate(newDate);
  };

  const handleTimeChange = (newTime: Dayjs | null) => {
    setNowSelected(false);
    setTime(newTime);
  };

  const handleAmountTypeChange: RadioGroupProps['onChange'] = (ev, value) => {
    setAmountType(value);
  };

  // const handleAmountSubtypeChange: RadioGroupProps['onChange'] = (
  //   ev,
  //   value,
  // ) => {
  //   setAmountSubtype(value);
  // };

  const handleFundingTypeChange: RadioGroupProps['onChange'] = (ev, value) => {
    setFundingType(value);
  };

  const handleNowSelect = () => {
    setNowSelected(true);
    setDate(null);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minHeight="100vh"
      padding="0px 20px"
    >
      <GradientBox>
        <Typography variant="h2">Batch send</Typography>
        <Typography fontWeight={600} mt={5} variant="body2">
          Recipients
        </Typography>
        <SpaceBetweenBox
          sx={{ height: '64px', alignItems: 'center', marginBottom: '20px' }}
        >
          <InputTokenization
            placeholder="Address"
            variant="outlined"
            sx={{ maxWidth: '450px' }}
          />
          <Box display="flex" width="100%" justifyContent="end">
            <BeigeButton
              disabled
              variant="contained"
              sx={{ marginRight: '20px' }}
            >
              Upload CSV
            </BeigeButton>
            <BeigeButton disabled variant="contained">
              All token holders
            </BeigeButton>
          </Box>
        </SpaceBetweenBox>
        <Divider />

        <Box mt="20px" mb="20px">
          <Typography fontWeight={600} variant="body2">
            Fund through
          </Typography>

          <FormControl>
            <RadioGroup value={fundingType} onChange={handleFundingTypeChange}>
              <FormControlLabel
                value={fundingTypes.MINTING}
                control={
                  <Radio
                    disabled
                    icon={<BpIconRadio />}
                    checkedIcon={<BpCheckedIconRadio />}
                  />
                }
                label={<Typography variant="body2">Minting</Typography>}
              />
              <FormControlLabel
                value={fundingTypes.WALLET}
                control={
                  <Radio
                    disabled
                    icon={<BpIconRadio />}
                    checkedIcon={<BpCheckedIconRadio />}
                  />
                }
                label={<Typography variant="body2">My own wallet</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Divider />
        <Box mt="20px" width="100%">
          <Typography variant="h4">Amount</Typography>
          <FormControl sx={{ width: '100%', marginBottom: '20px' }}>
            <RadioGroup value={amountType} onChange={handleAmountTypeChange}>
              <SpaceBetweenBox sx={{ width: '100%' }}>
                <FormControlLabel
                  value={amountTypes.FIXED}
                  control={
                    <Radio
                      disabled
                      icon={<BpIconRadio />}
                      checkedIcon={<BpCheckedIconRadio />}
                    />
                  }
                  label={
                    <Typography variant="body2">Fixed per address</Typography>
                  }
                />

                <Box mt={1}>
                  <NumericInput disabled onChange={noop} dispatch={false} />
                </Box>
              </SpaceBetweenBox>

              <SpaceBetweenBox sx={{ width: '100%' }}>
                <FormControlLabel
                  value={amountTypes.BY_ADDRESS}
                  control={
                    <Radio
                      disabled
                      icon={<BpIconRadio />}
                      checkedIcon={<BpCheckedIconRadio />}
                    />
                  }
                  label={
                    <Typography variant="body2">By address holding</Typography>
                  }
                />
                <Box mt={1}>
                  <NumericInput disabled onChange={noop} dispatch={false} />
                </Box>
              </SpaceBetweenBox>
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider />

        <Box mt="20px" mb="20px">
          <Typography fontWeight={600} variant="body2">
            Schedule Send
          </Typography>
          <SpaceBetweenBox sx={{ width: '100%' }}>
            <FormControl>
              <RadioGroup
                value={fundingType}
                onChange={handleFundingTypeChange}
              >
                <FormControlLabel
                  checked={nowSelected}
                  onChange={handleNowSelect}
                  control={
                    <Radio
                      disabled
                      icon={<BpIconRadio />}
                      checkedIcon={<BpCheckedIconRadio />}
                    />
                  }
                  label={<Typography variant="body2">Send now</Typography>}
                />
                <FormControlLabel
                  checked={!nowSelected}
                  control={
                    <Radio
                      disabled
                      icon={<BpIconRadio />}
                      checkedIcon={<BpCheckedIconRadio />}
                    />
                  }
                  label={<Typography variant="body2">Date</Typography>}
                />
              </RadioGroup>
            </FormControl>
            <Box display="inline-flex" mr={2} alignItems="end">
              <DatePicker
                disabled
                // disabled={nowSelected}
                date={date}
                onChange={handleDateChange}
                className="mr-5"
              />

              <TimePicker
                disabled
                // disabled={nowSelected}
                inputFormat="H:m"
                time={time}
                onChange={handleTimeChange}
              />
              <Box
                border="solid 1px #fff"
                borderRadius="12px"
                display="inline-block"
                pl={2}
                pr={2}
                ml={2}
                mb="10px"
                sx={{ minWidth: '75px' }}
              >
                <Typography variant="h4">UTC {utc}</Typography>
              </Box>
            </Box>
          </SpaceBetweenBox>
        </Box>
      </GradientBox>

      <Box display="flex" flexDirection="column" alignItems="center">
        <BlueButton disabled variant="outlined">
          Send
        </BlueButton>
      </Box>
    </Box>
  );
};
