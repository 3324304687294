import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {
  fixedPriceInfoText,
  fixedPegPriceInfoText,
  smartPriceInfoText,
  smartPegPriceInfoText,
  quadraticPriceInfoText,
  hyperbolicPriceInfoText,
  drawPriceInfoText,
} from 'texts';
import {
  BpCheckedIconRadio,
  BpIconRadio,
} from '../../../components/ControlButtons/Radio';
import { priceTypes } from '../liquidityTypes';
import { GradientBox } from '../../../components/Containers/Box';
import { InfoIcon } from '../../../components/Icons/Icons';

type Props = {
  disabled: boolean;
  value: priceTypes;
  onChange: FormControlLabelProps['onChange'];
};

export const PriceTypeSection = (props: Props) => {
  return (
    <GradientBox>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant="h2">Curve Type</Typography>
      </Box>

      <FormControl>
        <RadioGroup value={props.value}>
          <FormControlLabel
            value={priceTypes.FIXED}
            control={
              <Radio
                disabled={props.disabled}
                icon={<BpIconRadio />}
                checkedIcon={<BpCheckedIconRadio />}
              />
            }
            label={
              <Typography variant="body2">
                Fixed (pegged) Price <InfoIcon title={fixedPriceInfoText} />
              </Typography>
            }
            onChange={props.onChange}
          />
          <Typography display="block" variant="h4" fontSize="12px" mb={2}>
            {fixedPriceInfoText}
          </Typography>

          <FormControlLabel
            value={priceTypes.FIXED_NAKED}
            control={
              <Radio
                disabled={props.disabled}
                icon={<BpIconRadio />}
                checkedIcon={<BpCheckedIconRadio />}
              />
            }
            label={
              <Typography variant="body2">
                Price Peg with Naked Tokens
                <InfoIcon title={fixedPegPriceInfoText} />
              </Typography>
            }
            onChange={props.onChange}
          />
          <Typography display="block" variant="h4" fontSize="12px" mb={2}>
            {fixedPegPriceInfoText}
          </Typography>

          <FormControlLabel
            value={priceTypes.SMART}
            control={
              <Radio
                disabled={props.disabled}
                icon={<BpIconRadio />}
                checkedIcon={<BpCheckedIconRadio />}
              />
            }
            label={
              <Typography variant="body2">
                Smart Variable Price
                <InfoIcon title={smartPriceInfoText} />
              </Typography>
            }
            onChange={props.onChange}
          />
          <Typography display="block" variant="h4" fontSize="12px" mb={2}>
            {smartPriceInfoText}
          </Typography>

          <FormControlLabel
            value={priceTypes.SMART_NAKED}
            control={
              <Radio
                disabled={props.disabled}
                icon={<BpIconRadio />}
                checkedIcon={<BpCheckedIconRadio />}
              />
            }
            label={
              <Typography variant="body2">
                Smart Variable Price with Naked Tokens
                <InfoIcon title={smartPegPriceInfoText} />
              </Typography>
            }
            onChange={props.onChange}
          />
          <Typography display="block" variant="h4" fontSize="12px" mb={2}>
            {smartPegPriceInfoText}
          </Typography>

          <FormControlLabel
            value={priceTypes.QUADRATIC}
            control={
              <Radio
                disabled={props.disabled}
                icon={<BpIconRadio />}
                checkedIcon={<BpCheckedIconRadio />}
              />
            }
            label={
              <Typography variant="body2">
                Quadratic Amount Function
                <InfoIcon title={quadraticPriceInfoText} />
              </Typography>
            }
            onChange={props.onChange}
          />
          <Typography display="block" variant="h4" fontSize="12px" mb={2}>
            {quadraticPriceInfoText}
          </Typography>
          <FormControlLabel
            disabled
            value={priceTypes.HYPERBOLIC}
            control={
              <Radio
                disabled={props.disabled}
                // @ts-ignore TODO
                icon={<BpIconRadio disabled />}
                checkedIcon={<BpCheckedIconRadio />}
              />
            }
            label={
              <Typography variant="body2">
                Hyperbolic Price Function
                <InfoIcon title={hyperbolicPriceInfoText} />
              </Typography>
            }
            onChange={props.onChange}
          />
          <Typography display="block" variant="h4" fontSize="12px" mb={2}>
            {hyperbolicPriceInfoText}
          </Typography>
          <FormControlLabel
            disabled
            value={priceTypes.CUSTOM}
            control={
              <Radio
                disabled={props.disabled}
                // @ts-ignore TODO
                icon={<BpIconRadio disabled />}
                checkedIcon={<BpCheckedIconRadio />}
              />
            }
            label={
              <Typography variant="body2">
                Draw your function
                <InfoIcon title={drawPriceInfoText} />
              </Typography>
            }
            onChange={props.onChange}
          />
          <Typography display="block" variant="h4" fontSize="12px" mb={2}>
            {drawPriceInfoText}
          </Typography>
        </RadioGroup>
      </FormControl>
    </GradientBox>
  );
};
