import { useState } from 'react';
import {
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  Table,
  TableBody,
  IconButton,
  Collapse,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  BpCheckedIconCheck,
  BpIconCheck,
} from '../../components/ControlButtons/Check';
import { SelectTokenization } from '../../components/Input/Select';

const createData = (
  address: string,
  selectedTime: string,
  selectedQuantity: string,
  bridges: number[],
) => ({
  address,
  selectedTime,
  selectedQuantity,
  bridges,
});

const initialRows = [
  createData('0x0c48dfb3faafbcecf21f0d1f4e75e1fe6e731ad6', '0', '0', [0]),
  createData('0x0c48dfb3faafbcecf21f0d1f4e75e1fe6e731ad7', '1', '2', [1]),
  createData('0x0c48dfb3faafbcecf21f0d1f4e75e1fe6e731ad8', '2', '1', [0, 1]),
];

type RowProps = {
  row: {
    address: string;
    selectedTime: string;
    selectedQuantity: string;
    bridges: number[];
  };
  onDelete: () => void;
};
const bridgeOptions = [
  {
    value: 0,
    label: 'Ethereum Mainnet',
  },
  {
    value: 1,
    label: 'BNB Mainnet',
  },
  {
    value: 2,
    label: 'Elrond',
  },
  {
    value: 3,
    label: 'Polygon',
  },
];

const Row = (props: RowProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={props.row.address}
        sx={{
          '& > *': { borderBottom: 'unset' },
        }}
      >
        <TableCell
          sx={{
            width: '5% !important',
            paddingRight: '0px',
            borderBottom: 'none !important',
          }}
        >
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: open ? '#00e1d4' : '#adadd0',
            borderBottom: 'none !important',
          }}
        >
          {props.row.address}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            borderBottom: 'none !important',
          }}
        >
          <IconButton disabled onClick={props.onDelete}>
            <ClearIcon sx={{ fontSize: '16px !important' }} />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse
            sx={{ width: '100%' }}
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box
              padding="20px"
              display="grid"
              sx={{
                gridTemplateColumns: '1fr 1fr 4fr !important',
                columnGap: '10px',
              }}
            >
              <Box>
                <Typography>Time</Typography>
                <SelectTokenization
                  value={props.row.selectedTime}
                  sx={{ width: '100%', marginTop: '10px' }}
                >
                  <MenuItem value="0" key="0">
                    Always
                  </MenuItem>
                  <MenuItem value="1" key="1">
                    1 day
                  </MenuItem>
                  <MenuItem value="2" key="2">
                    1 week
                  </MenuItem>
                </SelectTokenization>
              </Box>
              <Box>
                <Typography>Quantity</Typography>
                <SelectTokenization
                  value={props.row.selectedTime}
                  sx={{ width: '100%', marginTop: '10px' }}
                >
                  <MenuItem value="0" key="0">
                    All
                  </MenuItem>
                  <MenuItem value="1" key="1">
                    50,000
                  </MenuItem>
                  <MenuItem value="2" key="2">
                    100,000
                  </MenuItem>
                </SelectTokenization>
              </Box>
              <Box>
                <Typography>Bridge</Typography>

                <FormControl
                  sx={{
                    width: '100%',
                    display: '-webkit-inline-box',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                  }}
                >
                  {bridgeOptions.map((item, index) => (
                    <FormControlLabel
                      sx={{ marginRight: '50px' }}
                      value={item.value}
                      control={
                        <Checkbox
                          checked={
                            props.row.bridges &&
                            props.row.bridges.includes(item.value)
                          }
                          checkedIcon={<BpCheckedIconCheck />}
                          icon={<BpIconCheck />}
                        />
                      }
                      label={
                        <Typography fontSize={14} fontWeight={500}>
                          {item.label}
                        </Typography>
                      }
                      key="eth"
                    />
                  ))}
                </FormControl>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const TransfersTable = () => {
  const [rows, setRows] = useState(initialRows);

  const handleDeleteRow = (rowIndex: number) => {
    setRows(rows.filter((_, index) => index !== rowIndex));
  };

  return (
    <TableContainer component={Paper} sx={{ backgroundColor: 'transparent' }}>
      <Table>
        <TableBody>
          {rows.map((row, index) => (
            <Row
              key={row.address}
              row={row}
              onDelete={() => handleDeleteRow(index)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
