import { Wallet } from '@coinweb/cweb-wallet-library';
import { useWallet } from './useWallet';
import { usePubKeyParam } from './usePubKeyParam';

// Returns a wallet using the pubKey extracted from the router's
// parameters.
export const useWalletFromParams = (): [Wallet | undefined, string] => {
  const pubKey = usePubKeyParam();
  return [useWallet(pubKey), pubKey];
};
