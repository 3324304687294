import BigNumber from 'bignumber.js';

function eFix(number: string) {
  const formattedValue = String(number).toLowerCase();
  const valueHasExponentIndex = formattedValue.indexOf('e');
  if (valueHasExponentIndex !== -1) {
    const enNumber = new Intl.NumberFormat('en', {
      notation: 'compact',
    }).format(Number(number));
    return enNumber.toString();
  }
  return number;
}

function getNewRound(
  number: BigNumber,
  lenNumber: number,
  decRound: number,
  percentRound: number,
) {
  if (lenNumber < decRound) return decRound;
  const newNumber: number = Number(number.toFixed(lenNumber));
  const percent100: number = 100;
  const percent = (newNumber / number.toNumber()) * percent100 - percent100;
  if (Math.abs(percent) <= percentRound) {
    // eslint-disable-next-line no-param-reassign
    lenNumber = getNewRound(
      BigNumber(newNumber),
      lenNumber - 1,
      decRound,
      percentRound,
    );
  } else {
    // eslint-disable-next-line no-param-reassign
    lenNumber += 1;
  }
  return lenNumber;
}

export default function round(
  number: BigNumber,
  decRound: number,
  percentRound: number = 1,
): BigNumber {
  if (number.isEqualTo(0)) return number;
  const doubleNumber = number.toFixed().split('.');
  if (doubleNumber.length < 2) return number;
  const lenNumber = doubleNumber[1].length;

  const roundNumber = getNewRound(number, lenNumber, decRound, percentRound);
  if (roundNumber > lenNumber) {
    return BigNumber(eFix(number.toFixed()));
  }
  const newNumber: string = number
    .toString()
    .substr(0, doubleNumber[0].length + roundNumber + 1);

  return BigNumber(eFix(newNumber));
}
