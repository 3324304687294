import { Polynomial } from '@coinweb/cweb-wallet-library';
import BigNumber from 'bignumber.js';

export default function calculateFixedBCparameter(
  initialCweb: BigNumber,
  poly: Polynomial,
) {
  const a = BigNumber(poly[2]);
  const b = BigNumber(poly[1]);
  const c = BigNumber(poly[0]);

  const result = c
    .minus(
      a
        .times(initialCweb.times(initialCweb))
        .plus(b.times(initialCweb))
        .plus(c),
    )
    .toFixed();
  return [result, b.toFixed(), a.toFixed()] as Polynomial;
}
