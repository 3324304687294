import { useMemo } from 'react';
import { sortBy, uniq } from 'lodash-es';
import BigNumber from 'bignumber.js';
import { useScreen } from 'usehooks-ts';
import { curveFunctionNumeric } from 'utils/curveFunctionNumeric';
import { ChartData } from 'chart.js';
import { useSelector } from 'react-redux';

const calculateEquation = ({
  step,
  xmin,
  xmax,
  tokenDecimal,
  calculate,
}: {
  step: BigNumber;
  xmin: BigNumber;
  xmax: BigNumber;
  tokenDecimal: BigNumber | any;
  calculate: (x: BigNumber) => BigNumber;
}) => {
  const v = [];

  let xt: BigNumber;
  for (xt = xmin; xt.lte(xmax); xt = xt.plus(step)) {
    v.push({
      primary: xt.dividedBy(BigNumber(10).exponentiatedBy(18)).toNumber(),
      secondary: calculate(xt)
        .dividedBy(BigNumber(10).exponentiatedBy(tokenDecimal))
        .toNumber(),
    });
  }

  return v;
};

const calculatePriceEquation = ({
  step,
  xmin,
  xmax,
  calculate,
}: {
  step: BigNumber;
  xmin: BigNumber;
  xmax: BigNumber;
  calculate: (x: BigNumber) => BigNumber;
}) => {
  const v = [];

  let xt: BigNumber;
  for (xt = xmin; xt.lte(xmax); xt = xt.plus(step)) {
    v.push({
      primary: xt.toNumber(),
      secondary: calculate(xt).toNumber(),
    });
  }

  return v;
};

const calculateQuadraticPriceEquation = ({
  params,
  step = BigNumber(1),
  xmin = BigNumber(0),
  xmax = BigNumber(1000),
}: {
  step?: BigNumber;
  xmin?: BigNumber;
  xmax?: BigNumber;
  params: Array<{
    label: string;
    a: BigNumber;
    b: BigNumber;
  }>;
}) => {
  return params
    .map(({ label, ...values }) => {
      return {
        label: `Your ${label} Price`,
        data: calculatePriceEquation({
          xmin,
          xmax,
          step,
          calculate: (x) =>
            BigNumber(-1).dividedBy(
              values.a.multipliedBy(2).multipliedBy(x).plus(values.b),
            ),
        }),
      };
    })
    .filter(({ data }) =>
      data.every(({ secondary }) => Math.abs(secondary) !== Infinity),
    );
};

// TODO use some color utility like in pie chart
const colors = [
  {
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
  },
  {
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
  },
  {
    borderColor: 'rgb(95,255,0)',
    backgroundColor: 'rgba(95,255,0,0.5)',
  },
];

export const useAmountChartTraces = ({
  domain,
  buy,
  sell,
  x0,
}: {
  domain: BigNumber;
  x0: BigNumber;
  buy: {
    a: BigNumber;
    b: BigNumber;
    c: BigNumber;
  };
  sell: {
    a: BigNumber;
    b: BigNumber;
    c: BigNumber;
  };
}) => {
  const screen = useScreen();
  const stepsCount = screen ? Math.round(screen.width / 100) : 10;
  const { tokenDecimal } = useSelector((state: any) => state.token);

  return useMemo(() => {
    if (!domain) {
      return [];
    }

    const xmax = domain.multipliedBy(0.99);

    const bounds = {
      xmin: BigNumber(0),
      xmax,
      step: xmax.dividedBy(stepsCount),
      tokenDecimal,
    };

    const buyData = calculateEquation({
      ...bounds,
      calculate: curveFunctionNumeric({
        poly: [BigNumber(buy.c), BigNumber(buy.b), BigNumber(buy.a)],
      }),
    });

    const sellData = calculateEquation({
      ...bounds,
      calculate: curveFunctionNumeric({
        poly: [BigNumber(sell.c), BigNumber(sell.b), BigNumber(sell.a)],
      }),
    });

    const ys = buyData.concat(sellData).map((pair) => pair.secondary);
    const y1 = BigNumber.minimum(...ys);
    const y2 = BigNumber.maximum(...ys);

    return {
      labels: sortBy(
        uniq(
          [...buyData, ...sellData]
            .map((item) => item.primary)
            .concat(x0.toNumber()),
        ),
      ),
      datasets: [
        {
          label: 'Buy',
          data: buyData.map((item) => ({
            x: item.primary,
            y: item.secondary,
          })),
          ...colors[0],
        },
        {
          label: 'Sell',
          data: sellData.map((item) => ({
            x: item.primary,
            y: item.secondary,
          })),
          ...colors[1],
        },
        {
          label: 'Initial amount',
          data: [
            {
              x: x0.toNumber(),
              y: y1.toNumber(),
            },
            {
              x: x0.toNumber(),
              y: y2.toNumber(),
            },
          ],
          ...colors[2],
        },
      ],
    };
  }, [buy, domain, sell, stepsCount, x0, tokenDecimal]);
};

export const usePriceChartTraces = (params: any) => {
  const data = calculateQuadraticPriceEquation({
    params,
  });

  const labels = data[0].data.map((item) => Math.round(item.primary));

  return {
    labels,
    datasets: data.map((item, index) => ({
      label: item.label,
      data: item.data.map((value) => ({
        x: value.primary,
        y: value.secondary,
      })),
      ...colors[index],
    })),
  } as ChartData<'line'>;
};
