import BigNumber from 'bignumber.js';

export default function intToHexString(d: BigNumber | null, padding: number) {
  let hex = d?.toString(16).toUpperCase();

  while (hex && hex.length < padding) {
    hex = `0${hex}`;
  }
  return `0x${hex}`;
}
