import { Box } from '@mui/material';
import { ChartData } from 'chart.js';
import { usePriceChartTraces } from '../../../components/LineChart/lineChartUtils';
import { Quadratic } from '../liquidityTypes';
import { LineChart } from '../../../components/LineChart';

type Props = {
  value: Quadratic;
  yTokenName: string;
};

export const PriceChart = (props: Props) => {
  const traces = usePriceChartTraces([
    {
      label: 'Sell',
      a: props.value.sell.a,
      b: props.value.sell.b,
    },
    {
      label: 'Buy',
      a: props.value.buy.a,
      b: props.value.buy.b,
    },
  ]) as ChartData<'line'>;

  return (
    <Box display="flex" alignItems="center" height="25vh">
      <LineChart
        xAxisTitle="CWEB"
        yAxisTitle="Price"
        tooltipTitle="CWEB"
        tooltipLabelSuffix={props.yTokenName}
        data={traces}
      />
    </Box>
  );
};
