export enum OperationType {
  RECEIVED = 'Received',
  SENT = 'Sent',
  CREATE_CUSTOM_TOKEN = 'CreateCustomToken',
  CUSTOM_TOKEN_FAILURE = 'CustomTokenFailure',
  CUSTOM_TOKEN_SUCCESS = 'CustomTokenSuccess',
  CUSTOM_TOKEN_LIQUIDITY_UPDATED = 'CustomTokenLiquidityUpdated',
  CUSTOM_TOKEN_UPDATED = 'CustomTokenUpdated',
  STORE_DATA = 'StoreData',
}
