import {
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import addressBookData from 'mocks/daoAddressBook.json';
import transactionData from 'mocks/daoTransactionHistory.json';
import { displayHash } from 'utils/displayHash';
import { timestampToStr } from 'utils/timestampToStr';
import { revealAddress } from 'utils/revealAddress';
import styles from './styles.module.scss';
import { TableContainer, TableCell, Tooltip } from '../components/Table/Table';

const addressDict = revealAddress(addressBookData, transactionData);

export const HistoryPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minHeight="100vh"
      padding="20px"
      sx={{ color: '#9794d8' }}
    >
      <Box className="background_gradient_color rounded-md" padding="20px">
        <Typography className={styles.headerPage}>History</Typography>
        <TableContainer component={Paper}>
          <Table aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Tx Id</TableCell>
                <TableCell align="left">Interacted with</TableCell>
                <TableCell align="left">Time</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionData.map((row) => (
                <TableRow key={row.name}>
                  <Tooltip title={String(row.txId)}>
                    <TableCell align="left" component="th" scope="row">
                      {displayHash(row.txId)}
                    </TableCell>
                  </Tooltip>
                  <Tooltip title={String(row.interactWith)}>
                    <TableCell align="left">
                      {addressDict[row.interactWith]
                        ? addressDict[row.interactWith]
                        : displayHash(row.interactWith)}
                    </TableCell>
                  </Tooltip>
                  <TableCell align="left">{timestampToStr(row.time)}</TableCell>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
