import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RepeatIcon from '@mui/icons-material/Repeat';
import NiceModal from '@ebay/nice-modal-react';
import addressBookData from 'mocks/daoAddressBook.json';
import { AddressBookModal } from './AddressBookModal';

const AddressTable = () => {
  const handleLink = (bridge) => {
    NiceModal.show(AddressBookModal, {
      message: `Create transaction to link MyDAO to ${bridge}`,
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addressBookData.map((row) => (
            <TableRow
              key={row.txId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.txId}</TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="right">{row.type}</TableCell>
              <TableCell align="right">
                {row.linked ? (
                  <Tooltip title="Linked">
                    <IconButton disableRipple>
                      <RepeatIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Not linked yet. Click here to link.">
                    <IconButton onClick={() => handleLink(row.name)}>
                      <RepeatIcon color="disabled" />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const AddressBookPage = () => {
  return (
    <Paper elevation={0}>
      <Box pt={2} pb={6} pl={2} pr={2}>
        <Box display="flex" mb={1}>
          <Typography variant="h6" textAlign="right" flex={1}>
            Address book
          </Typography>
          <Box alignSelf="flex-end" flex={1} textAlign="right">
            <Tooltip title="Add entry">
              <Button>
                <AddIcon />
                Add
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <AddressTable />
      </Box>
    </Paper>
  );
};
