import BigNumber from 'bignumber.js';

export enum flowTypes {
  ON_CHAIN = 'ON_CHAIN',
  OFF_CHAIN = 'OFF_CHAIN',
}

export enum pricingTypes {
  BONDING_CURVE = 'BONDING_CURVE',
  LIQUIDITY_POOL = 'LIQUIDITY_POOL',
}

export enum priceTypes {
  FIXED = 'FIXED',
  FIXED_NAKED = 'FIXED_NAKED',
  SMART = 'SMART',
  SMART_NAKED = 'SMART_NAKED',
  QUADRATIC = 'QUADRATIC',
  HYPERBOLIC = 'HYPERBOLIC',
  CUSTOM = 'CUSTOM',
}

export type chainTypes = {
  chain1: number;
  chain2: number;
};

export const chainOptions = [
  { value: 1, label: 'Chain #1' },
  { value: 2, label: 'Chain #2' },
  { value: 3, label: 'Chain #3' },
];
export type Quadratic = {
  buy: {
    a: BigNumber;
    b: BigNumber;
    c: BigNumber;
  };
  sell: {
    a: BigNumber;
    b: BigNumber;
    c: BigNumber;
  };
  x0: BigNumber;
  domain: BigNumber;
  kMax?: BigNumber;
};
