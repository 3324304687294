import { styled } from '@mui/material/styles';
import { REACT_APP_DEVNET } from 'conf';

export const BpIconCheck = styled('span')(() => ({
  borderRadius: 3,
  width: 25,
  height: 25,
  backgroundImage:
    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%237b7a92'/%3E%3C/svg%3E\")",
  backgroundColor: REACT_APP_DEVNET === 'true' ? '#0f3e43' : '#1a1946',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: REACT_APP_DEVNET === 'true' ? '#0f3e43' : '#1a1946',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

export const BpCheckedIconCheck = styled(BpIconCheck)({
  backgroundColor: REACT_APP_DEVNET === 'true' ? '#0f3e43' : '#1a1946',
  '&:before': {
    display: 'block',
    width: 25,
    height: 25,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%2300e1d4'/%3E%3C/svg%3E\")",
    content: '""',
  },
});
