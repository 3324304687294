import { useState, useEffect, useRef } from 'react';
import {
  WithdrawCweb,
  get_token_balance as getTokenBalance,
} from '@coinweb/cweb-wallet-library';
import BigNumber from 'bignumber.js';
import { Box, Typography } from '@mui/material';
import { useWalletFromParams } from 'hooks/useWalletFromParams';
import { NumericInput } from 'components/NumericInput';
import { CreationSection } from '../../BasicIssuance/Sections';
import { GradientBox } from '../../../components/Containers/Box';
import { InfoIcon } from '../../../components/Icons/Icons';
import {
  BlueButton,
  BeigeButton,
} from '../../../components/ControlButtons/Button';

type Props = {
  hash: string;
};

export const WithdrawBCSurplusSection = (props: Props) => {
  const [amount, setAmount] = useState<BigNumber>(BigNumber(0));
  const [balance, setBalance] = useState<BigInt>(BigInt(0));
  const [data, setData] = useState<string>('');
  const [view, setView] = useState<boolean>(true);
  const outerDivRef = useRef<HTMLDivElement>(null);
  const [qrSize, setQrSize] = useState<number | undefined>(undefined);

  const [wallet] = useWalletFromParams();
  useEffect(() => {
    const outerDiv = outerDivRef.current;
    if (outerDiv) {
      const { width, height } = outerDiv.getBoundingClientRect();
      if (Math.min(height, width) < 400)
        setQrSize(Math.min(height, width) - 80);
      else setQrSize(Math.min(height, width) - 180);
    }
  }, [outerDivRef, view]);
  const handleBalance = async () => {
    if (wallet) {
      try {
        await getTokenBalance(wallet, null).then((res) => {
          setBalance(res);
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error', e);
      }
    }
  };

  useEffect(() => {
    if (wallet) handleBalance();
  });

  const handleWithdraw = async () => {
    if (wallet) {
      try {
        const WithdrawCwebUi = {
          token: props.hash,
          cweb_amount: BigInt(amount.toString()),
        } as WithdrawCweb;
        setData(JSON.stringify({ TokenV1: { WithdrawCwebUi } }));
        setView(false);
        window.scrollTo({ top: 150, behavior: 'smooth' });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error', e);
      }
    }
  };
  return (
    <Box className="mt-5 w-full">
      {view ? (
        <GradientBox className="mt-5">
          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant={balance === BigInt(0) ? 'h3' : 'h2'}>
              Withdraw CWEB Surplus
            </Typography>
            <InfoIcon
              title={
                balance === BigInt(0)
                  ? 'You don not have any CWEB to withdraw.'
                  : `Cweb balance to withdraw  ${balance.toString()}`
              }
            />
          </Box>
          <Box display="flex" alignItems="start" mb={1}>
            <NumericInput
              inputLabelSx={{ marginTop: '-7px' }}
              increment={1}
              allowDecimals
              allowPositive
              allowNegative={false}
              sx={{ width: '100% ' }}
              value={amount}
              onChange={setAmount}
              maxValue={BigNumber(balance.toString())}
              disabled={balance === BigInt(0)}
              errorText={`Cweb amount to withdraw exceeds cweb balance ${balance.toString()}`}
              disabledText="You do not have any CWEB surplus."
              dispatch={false}
            />
            {balance === BigInt(0) ? (
              <BeigeButton
                disabled
                type="submit"
                sx={{
                  margin: '0px 0px 0px 10px',
                }}
                variant="outlined"
                onClick={handleWithdraw}
              >
                WITHDRAW{' '}
              </BeigeButton>
            ) : (
              <BlueButton
                type="submit"
                sx={{
                  margin: '0px 0px 0px 10px',
                }}
                variant="outlined"
                onClick={handleWithdraw}
              >
                WITHDRAW
              </BlueButton>
            )}
          </Box>
        </GradientBox>
      ) : (
        <Box ref={outerDivRef} sx={{ width: 'auto', height: '100vh' }}>
          <CreationSection
            verb="withdraw"
            tokenName="cweb surplus."
            data={data}
            onClose={setView}
            button={false}
            size={qrSize}
            operationType={undefined}
          />
        </Box>
      )}
    </Box>
  );
};
