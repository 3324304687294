import { styled } from '@mui/material/styles';
import { REACT_APP_DEVNET } from 'conf';

export const BpIconRadio = styled('span')((props) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  // @ts-ignore
  backgroundColor: props.disabled ? '#59575726' : 'rgb(255,255,255,0.15)',
  '&:before': {
    borderRadius: '50%',
    // @ts-ignore
    backgroundColor: props.disabled ? '#59575726' : 'rgb(255,255,255,0.15)',
    display: 'block',
    width: 20,
    height: 20,
    // @ts-ignore
    backgroundImage:
      REACT_APP_DEVNET === 'true'
        ? 'radial-gradient(#0f3e43,#0f3e43 28%,transparent 32%)'
        : 'radial-gradient(#1a1946,#1a1946 28%,transparent 32%)',
    content: '""',
  },
}));

export const BpCheckedIconRadio = styled(BpIconRadio)(() => ({
  backgroundColor: 'rgb(255, 255, 255, 0.15)',
  opacity: 1,
  '&:before': {
    backgroundColor: 'rgb(255, 255, 255, 0.15)',
    borderRadius: '50%',
    opacity: 1,
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#00e1d4,#00e1d4 28%,transparent 32%)',
    content: '""',
  },
}));
