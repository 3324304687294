export const timestampToStr = (time) => {
  if (time) {
    const date = new Date(time * 1000);

    return `  ${date.getUTCFullYear()}/${
      date.getUTCMonth() + 1
    }/${date.getUTCDate()}
  ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}
  `;
  }

  return 'no time data';
};
