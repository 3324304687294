import { useState } from 'react';
import {
  Typography,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Table,
  TableBody,
  Box,
} from '@mui/material';
import { priceParameterSummaryInfoText } from 'texts';
import { useIsMobile } from 'hooks/useMediaQuery';
import round from 'utils/round';
import {
  GradientBox,
  SpaceBetweenBox,
} from '../../../components/Containers/Box';
import { priceTypes, Quadratic } from '../liquidityTypes';
import {
  displayAbuyValue,
  displayBbuyValue,
  displayCbuyValue,
  displayAsellValue,
  displayBsellValue,
  displayCsellValue,
  displayDomainValue,
  displayX0Value,
} from '../liquidityUtils';
import styles from '../Liquidity.module.scss';
import { InfoIcon } from '../../../components/Icons/Icons';

type Props = {
  priceType: priceTypes;
  quadratic: Quadratic;
  decimals?: string;
  tokenSymbol?: string;
};

// eslint-disable-next-line import/no-unused-modules
export const PriceParameterSummarySection = (props: Props) => {
  const isMobile = useIsMobile();
  const { priceType, quadratic, decimals, tokenSymbol } = props;

  const [disableEllipsis, setDisableEllipsis] = useState<boolean[]>(
    new Array(9).fill(false),
  );

  const data = [
    {
      parameter: 'a',
      suffix: 'buy',
      value: round(quadratic.buy.a, 5).toFixed(),
      expression: displayAbuyValue(priceType),
    },
    {
      parameter: 'b',
      suffix: 'buy',
      value: round(quadratic.buy.b, 5).toFixed(),
      expression: displayBbuyValue(priceType),
    },
    {
      parameter: 'c',
      suffix: 'buy',
      value: round(quadratic.buy.c, 5).toFixed(),
      expression: displayCbuyValue(priceType),
    },
    {
      parameter: 'a',
      suffix: 'sell',
      value: round(quadratic.sell.a, 5).toFixed(),
      expression: displayAsellValue(priceType),
    },
    {
      parameter: 'b',
      suffix: 'sell',
      value: round(quadratic.sell.b, 5).toFixed(),
      expression: displayBsellValue(priceType),
    },
    {
      parameter: 'c',
      suffix: 'sell',
      value: round(quadratic.sell.c, 5).toFixed(),
      expression: displayCsellValue(priceType),
    },
    {
      parameter: 'domain',
      value: round(quadratic.domain, 5).toFixed(),
      expression: displayDomainValue(priceType),
    },
    {
      parameter: 'x',
      suffix: '0',
      value: round(quadratic.x0, 5).toFixed(),
      expression: displayX0Value(priceType),
    },
  ];

  const toggleEllipsis = (event: any, index: number) => {
    const element = event.target;
    const isAtBeginning = element.scrollLeft === 0;
    const updatedScrollStates = [...disableEllipsis];

    if (isAtBeginning) {
      updatedScrollStates[index] = false;
      setDisableEllipsis(updatedScrollStates);
    } else {
      updatedScrollStates[index] = true;
      setDisableEllipsis(updatedScrollStates);
    }
  };
  return (
    <GradientBox sx={isMobile ? { width: '100%' } : { mt: 0, width: '100%' }}>
      <SpaceBetweenBox sx={{ marginBottom: '10px' }}>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h2" sx={{ width: 'max-content' }}>
            Parameter Summary
          </Typography>
          <InfoIcon title={priceParameterSummaryInfoText} />
        </Box>
        {decimals && tokenSymbol ? (
          <Typography
            variant="h5"
            sx={{ textAlign: 'end', width: 'fit-content' }}
          >
            {tokenSymbol} has {decimals} decimals.
          </Typography>
        ) : (
          ''
        )}
      </SpaceBetweenBox>
      {isMobile ? (
        <>
          {data.map((item, index) => {
            return (
              <Box key={item.parameter + item.suffix} mb={3}>
                <Box display="flex" alignItems="center" mb="0.3rem">
                  <Typography variant="h4">Parameter:</Typography>
                  <Typography variant="h4" className="ml-auto">
                    {item.parameter}
                    <span className="sub">{item.suffix}</span>
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb="0.3rem">
                  <Typography variant="h4" sx={{ marginRight: '5px' }}>
                    Value:
                  </Typography>
                  <Typography
                    onScroll={(e) => toggleEllipsis(e, index)}
                    variant="h2"
                    className="text-sm ml-auto"
                    sx={
                      disableEllipsis[index]
                        ? {
                            overflow: 'auto',
                            textOverflow: 'initial',
                          }
                        : {
                            overflow: 'scroll',
                            textOverflow: 'ellipsis',
                          }
                    }
                  >
                    {item.value}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb="0.3rem">
                  <Typography variant="h4" sx={{ marginRight: '5px' }}>
                    Expression:
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{ wordWrap: 'break-word', overflowWrap: 'anywhere' }}
                    className="ml-auto"
                  >
                    {item.expression}
                  </Typography>
                </Box>
              </Box>
            );
          })}
          {priceType === priceTypes.SMART ||
          priceType === priceTypes.SMART_NAKED ? (
            <Box>
              <Box display="flex" alignItems="center" mb="0.3rem">
                <Typography variant="h4">Parameter:</Typography>
                <Typography variant="h4" className="ml-auto">
                  k<span className="sub">max</span>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb="0.3rem">
                <Typography variant="h4">Value:</Typography>
                <Typography
                  onScroll={(e) => toggleEllipsis(e, 8)}
                  variant="h2"
                  className="text-sm ml-auto"
                  sx={
                    disableEllipsis[8]
                      ? {
                          overflow: 'auto',
                          textOverflow: 'initial',
                        }
                      : {
                          overflow: 'scroll',
                          textOverflow: 'ellipsis',
                        }
                  }
                >
                  {quadratic.kMax?.toFixed()}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="h4">Expression:</Typography>
                <Box ml="auto">
                  <Typography variant="h4">
                    1/( (price <span className="super_dot"> . </span> ( 1- fee))
                    <span className="super">2</span>{' '}
                    <span className="super_dot"> . </span> 2{' '}
                    <span className="super_dot"> . </span>c
                    <span className="sub">sell</span>)
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : null}
        </>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 100 }}>
            <TableHead>
              <TableRow>
                <TableCell className={styles.th} align="left">
                  Parameter
                </TableCell>
                <TableCell className={styles.th} align="left">
                  Value
                </TableCell>
                <TableCell className={styles.th}>Expression</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => {
                return (
                  <TableRow key={item.parameter + item.suffix}>
                    <TableCell
                      className={styles.th}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      <Typography variant="h4" className=" h-5">
                        {item.parameter}
                        <span className="sub">{item.suffix}</span>
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.th_scroll} align="left">
                      <Typography
                        variant="h2"
                        className="mt-2 h-5 text-sm contents"
                        onScroll={(e) => toggleEllipsis(e, index)}
                        sx={
                          disableEllipsis[index]
                            ? {
                                maxWidth: '200px',
                                overflow: 'auto ',
                                textOverflow: 'initial',
                              }
                            : {
                                maxWidth: '200px',
                                overflow: 'scroll',
                                textOverflow: 'ellipsis ',
                              }
                        }
                      >
                        {item.value}
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.th} align="left">
                      <Typography variant="h4" className="h-5 contents">
                        {item.expression}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}

              {priceType === priceTypes.SMART ||
              priceType === priceTypes.SMART_NAKED ? (
                <TableRow>
                  <TableCell
                    className={styles.th}
                    component="th"
                    scope="row"
                    align="left"
                  >
                    <Typography variant="h4" className="mt-2 h-5">
                      k<span className="sub">max</span>
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.th} align="left">
                    <Typography
                      onScroll={(e) => toggleEllipsis(e, 8)}
                      variant="h2"
                      className="mt-2 h-5 text-sm contents"
                      sx={
                        disableEllipsis[8]
                          ? {
                              maxWidth: '200px',
                              overflow: 'auto !important',
                              textOverflow: 'initial !important',
                            }
                          : {
                              maxWidth: '200px',
                              overflow: 'scroll !important',
                              textOverflow: 'ellipsis !important',
                            }
                      }
                    >
                      {quadratic.kMax ? round(quadratic.kMax, 5).toFixed() : ''}
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.th} align="left">
                    <Typography variant="h4" className="mt-2 contents">
                      1/( (price <span className="super_dot"> . </span> ( 1-
                      fee))
                      <span className="super">2</span>{' '}
                      <span className="super_dot"> . </span> 2{' '}
                      <span className="super_dot"> . </span>c
                      <span className="sub">sell</span>)
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </GradientBox>
  );
};
