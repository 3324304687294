import { Box, Typography } from '@mui/material';
import {
  poolSetupInfotext,
  cwebBalanceInfotext,
  liquidityPoolBalanceInfotext,
} from 'texts';
import { useIsMobile } from 'hooks/useMediaQuery';
import { GradientBox } from '../../../components/Containers/Box';
import { InfoIcon } from '../../../components/Icons/Icons';
import { InputTokenization } from '../../../components/Input/Input';

type Props = {
  disabled: boolean;
};

export const PoolSetupSection = (props: Props) => {
  const isMobile = useIsMobile();

  return (
    <GradientBox sx={{ marginBottom: '20px' }}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        flex={1}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          pt={1}
          pb={2}
          mb={1}
        >
          <Typography variant="h2">Pool Setup</Typography>
          <InfoIcon title={poolSetupInfotext} />
        </Box>

        <Box mb={3} width={isMobile ? '100%' : 'auto'}>
          <Typography fontSize={14} fontWeight={500} variant="body2">
            Cweb Balance <InfoIcon title={cwebBalanceInfotext} />
          </Typography>

          <InputTokenization
            disabled={props.disabled}
            placeholder="Insert Amount"
            variant="outlined"
            sx={{ width: isMobile ? '100%' : 500 }}
            type="number"
          />
        </Box>

        <Box width={isMobile ? '100%' : 'auto'}>
          <Typography fontSize={14} fontWeight={500} variant="body2">
            Liquidity Pool Balance
            <InfoIcon title={liquidityPoolBalanceInfotext} />
          </Typography>
          <InputTokenization
            disabled={props.disabled}
            placeholder="Insert Amount"
            variant="outlined"
            sx={{ width: isMobile ? '100%' : 500 }}
            type="number"
          />
        </Box>

        <Box display="flex" mt={1} alignItems="center">
          <Typography display="block" variant="h4" fontSize="12px">
            Note: On-chain liquidity enables gas fee abstraction
          </Typography>
        </Box>
      </Box>
    </GradientBox>
  );
};
