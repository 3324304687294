import { useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { usePrevious } from 'hooks/usePrevious';
import { Box, Typography } from '@mui/material';
import { NumericInput } from 'components/NumericInput';
import { Slider } from 'components/Slider/Slider';
import { BondingCurveFields } from 'enums';
import { ExtraFields } from '@coinweb/cweb-wallet-library';
import { Token } from 'types/Token';
import { useIsMobile } from 'hooks/useMediaQuery';
import { GradientBox } from '../../../components/Containers/Box';
import { priceTypes, Quadratic } from '../liquidityTypes';
import {
  findSMARTX0,
  findSMARTA,
  findSMARTBuyC,
  findFIXEDX0,
  findFIXEDBuyB,
  findFIXEDBuyC,
  findSellB,
  findSMARTBuyB,
  findKMax,
  findQuadraticDomain,
  findQuadraticX0,
  findQuadraticBuyC,
  findSMARTSellB,
  handlePriceMinimalUnits,
} from '../liquidityUtils';

const MAX_AMOUNT_OF_CWEB = BigNumber(7680000000);

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 100,
    label: '100%',
  },
];

type Props = {
  previousToken: Token | null | undefined;
  token: Token | null | undefined;
  priceType: priceTypes;
  value: Quadratic;
  decimals: BigNumber;
  onChange: (value: Quadratic) => void;
  premint: BigNumber;
  extraFields: ExtraFields;
  onExtraFieldsChange: (name: BondingCurveFields, content: string) => void;
};

enum Params {
  A = 'a',
  B = 'b',
  C = 'c',
}

const calculateValue = (el: number) => {
  return 2 ** el < 0 ? 2 ** el : el;
};

export const PriceSettingsSection = (props: Props) => {
  const {
    previousToken,
    token,
    priceType,
    value,
    decimals,
    onChange,
    premint,
    extraFields,
    onExtraFieldsChange,
  } = props;

  const isMobile = useIsMobile();
  const previousPriceType = usePrevious(priceType);
  const [fee, setFee] = useState<BigNumber>(BigNumber(0.02));

  const [price, setPrice] = useState<BigNumber>(
    BigNumber(10).exponentiatedBy(decimals),
  );

  const [nakedTokens, setNakedTokens] = useState<BigNumber>(BigNumber(0));

  const [k, setK] = useState<BigNumber>(BigNumber(0));
  const priceMinimal = handlePriceMinimalUnits(price, decimals);

  useEffect(() => {
    if (previousPriceType !== priceType && priceType === priceTypes.QUADRATIC) {
      onChange(
        token?.bondingCurve
          ? {
              buy: {
                a: BigNumber(token.bondingCurve.buy.poly[2]),
                b: BigNumber(token.bondingCurve.buy.poly[1]),
                c: BigNumber(token.bondingCurve.buy.poly[0]),
              },
              sell: {
                a: BigNumber(token.bondingCurve.sell.poly[2]),
                b: BigNumber(token.bondingCurve.sell.poly[1]),
                c: BigNumber(token.bondingCurve.sell.poly[0]),
              },
              x0: token.bondingCurve.supply,
              domain: BigNumber(token.bondingCurve.domain),
              kMax: BigNumber(0),
            }
          : {
              buy: {
                a: BigNumber(0),
                b: BigNumber(-1),
                c: BigNumber(0),
              },
              sell: {
                a: BigNumber(0),
                b: BigNumber(-1),
                c: BigNumber(0),
              },
              x0: findQuadraticX0(BigNumber(0), BigNumber(-1), BigNumber(0)),
              domain: findQuadraticDomain(
                BigNumber(0),
                BigNumber(-1),
                BigNumber(0),
                BigNumber(-1),
                decimals,
              ),
              kMax: BigNumber(0),
            },
      );
    }
  }, [priceType, previousPriceType, onChange, token?.bondingCurve, decimals]);

  const nakedMarks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: premint.toNumber(),
      label: premint.toFixed(),
    },
  ];

  const handleBuyParamChange = (newValue: BigNumber, name?: string) => {
    if (name) {
      onChange({
        sell: value.sell,
        buy: {
          ...value.buy,
          [name]: newValue,
        },
        x0: value.x0,
        domain: value.domain,
        kMax: value.kMax,
      } as unknown as Quadratic);
    }
  };

  const handleSellParamChange = (newValue: BigNumber, name?: string) => {
    if (name) {
      onChange({
        buy: value.buy,
        sell: {
          ...value.sell,
          [name]: newValue,
        },
        x0: value.x0,
        domain: value.domain,
        kMax: BigNumber(0),
      } as unknown as Quadratic);
    }
  };

  const handleFeeChange = (event: Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      setFee(BigNumber(newValue));
    }
    onExtraFieldsChange(BondingCurveFields.FEE, String(newValue));
  };

  const handleFeeBigNumChange = (newValue: BigNumber) => {
    setFee(BigNumber(newValue));
    onExtraFieldsChange(BondingCurveFields.FEE, newValue.toString());
  };

  const handleNakedTokenChange = (
    event: Event,
    newValue: number | number[],
  ) => {
    if (!Array.isArray(newValue)) {
      setNakedTokens(BigNumber(newValue));
    }
    onExtraFieldsChange(BondingCurveFields.NAKED, String(newValue));
  };

  const handleNakedTokenBigNumChange = (newValue: BigNumber) => {
    setNakedTokens(BigNumber(newValue));
    onExtraFieldsChange(BondingCurveFields.NAKED, newValue.toString());
  };

  const handlePriceChange = (newValue: BigNumber) => {
    setPrice(newValue);
    onExtraFieldsChange(
      BondingCurveFields.PRICE,
      String(handlePriceMinimalUnits(newValue, decimals)),
    );
  };

  const handleKChange = (newValue: BigNumber) => {
    setK(newValue);
    onExtraFieldsChange(BondingCurveFields.K, newValue.toString());
  };

  useEffect(() => {
    if (
      priceType === priceTypes.FIXED ||
      priceType === priceTypes.FIXED_NAKED
    ) {
      const newX0 = findFIXEDX0(
        priceMinimal,
        fee,
        priceType === priceTypes.FIXED ? premint : premint.minus(nakedTokens),
      );

      const bBuy = findFIXEDBuyB(priceMinimal, fee);
      const cBuy = findFIXEDBuyC(newX0, bBuy);
      const bSell = findSellB(priceMinimal, fee);

      onChange({
        buy: {
          [Params.A]: BigNumber(0),
          [Params.B]: bBuy,
          [Params.C]: cBuy,
        },
        sell: {
          [Params.A]: BigNumber(0),
          [Params.B]: bSell,
          [Params.C]:
            priceType === priceTypes.FIXED
              ? premint
              : premint.minus(nakedTokens),
        },
        x0: newX0,
        domain: MAX_AMOUNT_OF_CWEB.multipliedBy(
          BigNumber(10).exponentiatedBy(decimals),
        ),
        kMax: BigNumber(0),
      } as unknown as Quadratic);
    }

    if (
      priceType === priceTypes.SMART ||
      priceType === priceTypes.SMART_NAKED
    ) {
      const a = findSMARTA(k);

      const newX0 = findSMARTX0(
        a,
        priceType === priceTypes.SMART ? premint : premint.minus(nakedTokens),
        priceMinimal,
        fee,
      );

      const bBuy = findSMARTBuyB(priceMinimal, fee, newX0, a);
      const bSell = findSMARTSellB(priceMinimal, fee, newX0, a);

      const newKMax = findKMax(
        priceType === priceTypes.SMART ? premint : premint.minus(nakedTokens),
        priceMinimal,
        fee,
      );

      const cBuy = findSMARTBuyC(a, newX0, bBuy);
      const newK = BigNumber.min(k, newKMax);
      setK(newK);
      onExtraFieldsChange(BondingCurveFields.K, newK.toString());

      onChange({
        buy: {
          [Params.A]: a,
          [Params.B]: bBuy,
          [Params.C]: cBuy,
        },
        sell: {
          [Params.A]: a,
          [Params.B]: bSell,
          [Params.C]:
            priceType === priceTypes.SMART
              ? premint
              : premint.minus(nakedTokens),
        },
        x0: newX0,
        domain: findQuadraticDomain(a, bBuy, a, bSell, decimals),
        kMax: newKMax,
      });
    }
    if (
      priceType === priceTypes.QUADRATIC &&
      previousPriceType === priceTypes.QUADRATIC
    ) {
      const aBuy = value.buy.a;
      const bBuy = value.buy.b;
      const aSell = value.sell.a;
      const bSell = value.sell.b;
      const cSell = value.sell.c;
      const newX0 = findQuadraticX0(aSell, bSell, cSell);
      const cBuy = findQuadraticBuyC(aBuy, aSell, bBuy, bSell, cSell, newX0);
      const newDomain = findQuadraticDomain(aBuy, bBuy, aSell, bSell, decimals);
      onChange({
        buy: {
          [Params.A]: aBuy,
          [Params.B]: bBuy,
          [Params.C]: cBuy,
        },
        sell: {
          [Params.A]: aSell,
          [Params.B]: bSell,
          [Params.C]: cSell,
        },
        x0: newX0,
        domain: newDomain,
        kMax: BigNumber(0),
      });
    }
  }, [
    onExtraFieldsChange,
    decimals,
    priceMinimal,
    nakedTokens,
    k,
    fee,
    onChange,
    premint,
    priceType,
    previousPriceType,
    value.buy.a,
    value.buy.b,
    value.buy.c,
    value.domain,
    value.sell.a,
    value.sell.b,
    value.sell.c,
    value.x0,
    previousToken,
    token,
  ]);

  useEffect(() => {
    const newFee = extraFields.find(
      (field) => field.name === BondingCurveFields.FEE,
    )?.content;

    const newPrice = extraFields.find(
      (field) => field.name === BondingCurveFields.PRICE,
    )?.content;

    const newNaked = extraFields.find(
      (field) => field.name === BondingCurveFields.NAKED,
    )?.content;

    const newK = extraFields.find(
      (field) => field.name === BondingCurveFields.K,
    )?.content;

    setFee(newFee ? BigNumber(newFee) : BigNumber(0.02));
    setPrice(
      newPrice
        ? BigNumber(newPrice).multipliedBy(
            BigNumber(10).exponentiatedBy(decimals),
          )
        : BigNumber(10).exponentiatedBy(decimals),
    );
    setNakedTokens(newNaked ? BigNumber(newNaked) : BigNumber(0));
    setK(newK ? BigNumber(newK) : BigNumber(0));
  }, [decimals, extraFields]);

  return (
    <GradientBox sx={isMobile ? { width: '100%' } : { mt: 0, width: '100%' }}>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant="h2">Curve Settings</Typography>
      </Box>

      {(priceType === priceTypes.FIXED ||
        priceType === priceTypes.FIXED_NAKED ||
        priceType === priceTypes.SMART ||
        priceType === priceTypes.SMART_NAKED) && (
        <Box className="w-full  mt-5">
          <Typography mb="10px">Price (CWEB)</Typography>
          <NumericInput
            inputLabelSx={{ marginTop: '-7px' }}
            increment={0.0001}
            allowDecimals
            allowNegative={false}
            allowPositive
            sx={{ width: '100% ' }}
            value={price}
            onChange={handlePriceChange}
            dispatch={false}
          />
          <Box className="mt-5">
            <Typography mb={3}>Fee (%)</Typography>

            <Slider
              value={fee.toNumber()}
              onChange={handleFeeChange}
              aria-labelledby="input-slider"
              marks={marks}
              min={0}
              step={0.1}
              max={100}
              scale={calculateValue}
              valueLabelDisplay="on"
              sx={{ width: '97%' }}
            />
            <NumericInput
              suffix="%"
              inputLabelSx={{ marginTop: '-7px' }}
              increment={0.1}
              allowDecimals
              allowNegative={false}
              allowPositive
              sx={{ width: '100% ' }}
              value={fee}
              maxValue={BigNumber(100)}
              onChange={handleFeeBigNumChange}
              dispatch={false}
            />
          </Box>

          {(priceType === priceTypes.FIXED_NAKED ||
            priceType === priceTypes.SMART_NAKED) && (
            <Box className="w-full  mt-5">
              <Typography mb="10px">Naked Token</Typography>
              <Slider
                value={nakedTokens.toNumber()}
                onChange={handleNakedTokenChange}
                aria-labelledby="input-slider"
                min={0}
                step={1}
                max={premint.toNumber()}
                marks={nakedMarks}
                scale={calculateValue}
                valueLabelDisplay="on"
                sx={{
                  width: '97%',
                  '& .MuiSlider-markLabel': {
                    position: 'sticky !important',
                    lineHeight: '3.43',
                  },
                }}
              />
              <NumericInput
                inputLabelSx={{ marginTop: '-7px' }}
                increment={1}
                allowDecimals
                allowPositive
                allowNegative={false}
                sx={{ width: '100% ' }}
                value={nakedTokens}
                maxValue={premint}
                onChange={handleNakedTokenBigNumChange}
                dispatch={false}
              />
            </Box>
          )}

          {(priceType === priceTypes.SMART ||
            priceType === priceTypes.SMART_NAKED) && (
            <Box className="w-full  mt-5">
              <Typography mb="10px">Token per CWEB acceleration</Typography>
              <NumericInput
                allowNegative={false}
                allowDecimals
                allowPositive
                inputLabelSx={{ marginTop: '-7px' }}
                sx={{ width: '100%' }}
                value={k}
                onChange={handleKChange}
                increment={0.0001}
                maxValue={value.kMax ?? undefined}
                dispatch={false}
              />
              <Typography variant="h4" className="mt-1">
                k<span className="sub">max</span> {value.kMax?.toFixed()}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {(priceType === priceTypes.QUADRATIC ||
        priceType === priceTypes.HYPERBOLIC) && (
        <Box className="w-full">
          <>
            <Typography className=" mb-3">Buy</Typography>
            <Box
              display={isMobile ? 'block' : 'grid'}
              className="w-full grid-cols-3 gap-5 "
            >
              <NumericInput
                allowNegative
                increment={0.00001}
                allowDecimals
                allowPositive={false}
                inputLabelSx={{ marginTop: '-7px' }}
                name={Params.A}
                label="a"
                sx={{ width: '100%' }}
                value={value.buy.a}
                onChange={handleBuyParamChange}
                dispatch={false}
              />
              <NumericInput
                allowNegative
                allowPositive={false}
                increment={0.01}
                allowDecimals
                inputLabelSx={{ marginTop: '-7px' }}
                name={Params.B}
                label="b"
                sx={{ width: '100%' }}
                onChange={handleBuyParamChange}
                value={value.buy.b}
                dispatch={false}
              />
            </Box>
          </>
          <>
            <Typography className="mt-5 mb-3">Sell</Typography>
            <Box
              display={isMobile ? 'block' : 'grid'}
              className="w-full  grid-cols-3 gap-5 "
            >
              <NumericInput
                allowNegative
                allowPositive={false}
                increment={0.00001}
                allowDecimals
                inputLabelSx={{ marginTop: '-7px' }}
                name={Params.A}
                label="a"
                sx={{ width: '100%' }}
                onChange={handleSellParamChange}
                value={value.sell.a}
                dispatch={false}
              />
              <NumericInput
                allowNegative
                allowPositive={false}
                increment={0.01}
                allowDecimals
                inputLabelSx={{ marginTop: '-7px' }}
                name={Params.B}
                label="b"
                sx={{ width: '100%' }}
                onChange={handleSellParamChange}
                value={value.sell.b}
                dispatch={false}
              />
              {priceType === priceTypes.QUADRATIC && (
                <NumericInput
                  allowNegative
                  allowDecimals
                  allowPositive
                  inputLabelSx={{ marginTop: '-7px' }}
                  name={Params.C}
                  label="c"
                  sx={{ width: '100%' }}
                  onChange={handleSellParamChange}
                  value={value.sell.c}
                  dispatch={false}
                />
              )}
            </Box>
          </>
        </Box>
      )}
    </GradientBox>
  );
};
