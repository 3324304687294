import {
  Autocomplete,
  CircularProgress,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useQueries } from '@tanstack/react-query';
import useShallowCompareEffect from 'hooks/useShallowCompareEffect';
import commonStyles from 'styles/commonStyles.module.scss';
import {
  fieldFungibleTokenImg,
  fieldTokenCreation,
} from 'redux/reducers/tokenReducer';
import trimHash from 'utils/trimHash';
import downIcon from 'images/icons/icon-dropdown-2.svg';
import { useWalletFromParams } from 'hooks/useWalletFromParams';
import { fetchTokenInformation } from 'hooks/useUserCreatedCustomTokens';
import { usePrevious } from 'hooks/usePrevious';
import { useCurrentToken } from 'hooks/useCurrentToken';
import DefaultIconLogo from 'images/defaultTokenIcon.svg';
import { TokenFields } from 'enums';
import { Token } from 'types/Token';
import { findNewTokenIndex } from 'utils/findNewTokenIndex';
import {
  getIpfs,
  convertBaseImg,
} from '../../pages/BasicIssuance/BasicIssuanceUtils';
import styles from './TokenizationHeader.module.scss';

type Option = {
  index: number;
  label: string;
  icon?: string;
  hash: string;
  token: Token;
  hasBondingCurve: boolean;
  iconBase?: string;
};

const tokenInfo2Option = (token: Token, index: number): Option => ({
  index,
  label: token.extraField(TokenFields.CODE) || '',
  icon:
    token.hashId ===
    '0xc9895090994839f5bbb111fbe86179325375f41e01ab7f3b9c20ebdb0830371b'
      ? 'bafkreier7mirvue5ae4n65uzbldniziaxsligaxfgr62oyoulygpbr2n2y'
      : token.extraField(TokenFields.ICON),
  hash: token.hashId,
  token,
  hasBondingCurve: !!token.bondingCurve,
  iconBase: 'loading',
});
export const IssuedToken = () => {
  const [wallet] = useWalletFromParams();
  const [currentToken, setCurrentToken] = useCurrentToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fungibleCurrentImg, modalMessage, tokenSymbol } = useSelector(
    (state: any) => state.token,
  );

  const [open, setOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tokens, setTokens] = useState<Token[] | undefined>(undefined);
  const [options, setOptions] = useState((tokens ?? []).map(tokenInfo2Option));
  const previousTokens = usePrevious(tokens);
  const previousFungibleCurrentImg = usePrevious(fungibleCurrentImg);

  const index = tokens?.findIndex(
    (tokenObj) => tokenObj.hashId === currentToken?.hashId,
  );

  useEffect(() => {
    const tokenHash = searchParams.get('tokenHash');
    if (tokenHash && tokens && tokens.length > 0) {
      const resHash = tokens.filter((token) => token.hashId === tokenHash);
      setCurrentToken(resHash[0]);
      dispatch(fieldTokenCreation(true));
    } else if (currentToken === undefined && tokens && tokens.length > 0) {
      setCurrentToken(tokens[0]);
      searchParams.set('tokenHash', tokens[0].hashId);
      setSearchParams(searchParams);
      dispatch(fieldTokenCreation(true));
    }
    if (
      previousTokens &&
      tokens &&
      previousTokens.length + 1 === tokens.length
    ) {
      const ind = findNewTokenIndex(previousTokens, tokens);
      setCurrentToken(tokens[ind]);
      dispatch(fieldTokenCreation(true));
      searchParams.set('tokenHash', tokens[ind].hashId);
      setSearchParams(searchParams);
    }
    if (tokens && tokens.length !== options.length)
      setOptions((tokens ?? []).map(tokenInfo2Option));
  }, [
    previousTokens,
    currentToken,
    setCurrentToken,
    tokens,
    setSearchParams,
    searchParams,
    options.length,
    open,
    tokens?.length,
    dispatch,
  ]);
  useEffect(() => {
    if (fungibleCurrentImg !== previousFungibleCurrentImg) {
      const currOpt = options.find(
        (option) => option.iconBase === previousFungibleCurrentImg,
      );
      if (currOpt) {
        const newOpts = [...options];
        newOpts[currOpt?.index].iconBase = fungibleCurrentImg;
        setOptions(newOpts);
      }
    }
  }, [fungibleCurrentImg, options, previousFungibleCurrentImg]);

  useShallowCompareEffect(() => {
    if (wallet) {
      fetchTokenInformation(wallet, previousTokens).then((token: Token[]) => {
        if (
          previousTokens?.length !== token.length &&
          previousTokens &&
          token
        ) {
          const differingIndex = token.findIndex((element, ind) => {
            // Check if the corresponding element in array2 differs
            return !element.isEqual(previousTokens[ind]);
          });
          setCurrentToken(token[differingIndex]);
          dispatch(fieldTokenCreation(true));
        }
        setTokens(token);
      });
    }
  }, [wallet, open, modalMessage]);

  const loading = open && !tokens;

  const iconRes = useQueries({
    queries: options.map((opt, ind) => {
      return {
        queryKey: ['issuedIcon', opt.icon],
        queryFn: () => getIpfs(opt.icon),

        enabled: !!opt.icon && tokens && !tokens[ind].getBaseIcon(),
        onSuccess: (ipfsData: any) => {
          convertBaseImg(ipfsData).then((res) => {
            if (tokens) {
              const newOpts = [...options];
              if (newOpts[ind]) {
                newOpts[ind].iconBase = res;
              }
              tokens[ind].setBaseIcon(res);
              setOptions(newOpts);
              if (index === ind) {
                dispatch(fieldFungibleTokenImg(res));
              }
            }
          });
        },
      };
    }),
  });

  const currentTokenOption = useMemo(() => {
    const formattedTokens: Option[] = (tokens ?? []).flatMap((tok, ind) =>
      tok.hashId === currentToken?.hashId ? [tokenInfo2Option(tok, ind)] : [],
    );
    return formattedTokens.length > 0 ? formattedTokens[0] : null;
  }, [currentToken, tokens]);

  const onChange = useCallback(
    (_ev: any, value: Option | null) => {
      if (!value) {
        setCurrentToken(null);
        searchParams.delete('tokenHash');
        setSearchParams(searchParams);
        dispatch(fieldFungibleTokenImg(undefined));
        dispatch(fieldTokenCreation(false));

        if (wallet) navigate(`/${wallet.pub_key}/issuance/fungible`);
      } else {
        setCurrentToken(value.token);
        searchParams.set('tokenHash', value.token.hashId);
        setSearchParams(searchParams);
        dispatch(fieldTokenCreation(true));
        dispatch(fieldFungibleTokenImg(value.iconBase));
      }
    },
    [
      setCurrentToken,
      setSearchParams,
      searchParams,
      navigate,
      wallet,
      dispatch,
    ],
  );

  return (
    <Autocomplete
      classes={{
        option: commonStyles.mutedText,
        noOptions: commonStyles.mutedText,
        loading: commonStyles.mutedText,
        popper: styles.popper,
      }}
      sx={{
        color: 'white',
        maxWidth: {
          xs: '195px',
          sm: '245px',
        },
        width: {
          xs: '195px',
          sm: '245px',
        },
        marginLeft: {
          xs: '3px',
        },
        mr: 2,
        fontWeight: 500,
        '.MuiAutocomplete-inputRoot': {
          backgroundColor: 'transparent',
        },
        '.MuiInputLabel-root': {
          marginTop: '-7px !important',
        },
      }}
      popupIcon={<img src={downIcon} alt="downicon" />}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      onReset={() => {
        setCurrentToken(null);
        dispatch(fieldTokenCreation(false));
      }}
      value={currentTokenOption}
      // getOptionLabel={(option) => `${option.label} ${option.hash}`}
      getOptionLabel={(option) => `${tokenSymbol} ${option.hash}`}
      isOptionEqualToValue={(option, value) => option.hash === value.hash}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          placeholder="Issue Token"
          InputProps={{
            ...params.InputProps,
            startAdornment:
              fungibleCurrentImg === 'loading' && !!currentTokenOption?.icon ? (
                <CircularProgress sx={{ color: '#fff' }} size={20} />
              ) : (
                <img
                  width={30}
                  src={
                    currentTokenOption === null ||
                    !fungibleCurrentImg ||
                    !currentTokenOption?.icon
                      ? DefaultIconLogo
                      : fungibleCurrentImg
                  }
                  className={styles.issued_tokens_image}
                  alt="Issued_token_logo"
                />
              ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress sx={{ color: '#fff' }} size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props2, option) => (
        <li
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props2}
        >
          {iconRes[option.index].status === 'loading' && option.icon && (
            <CircularProgress sx={{ color: '#fff' }} size={20} />
          )}
          {iconRes[option.index].status === 'loading' &&
            option.icon === undefined && (
              <Box className="mr-2" sx={{ pointerEvents: 'none' }}>
                <img
                  width={30}
                  src={DefaultIconLogo}
                  id="issued_icon"
                  alt="default_icon"
                  className={styles.issued_tokens_image}
                />
              </Box>
            )}
          {iconRes[option.index].status === 'success' && option.icon ? (
            <Box className="mr-2" sx={{ pointerEvents: 'none' }}>
              <img
                width={30}
                src={option.iconBase}
                id="issued_icon"
                alt="icon"
                className={styles.issued_tokens_image}
              />
            </Box>
          ) : null}
          <Box
            fontSize="12px"
            fontWeight="bold"
            sx={{ pointerEvents: 'none' }}
            height="40px"
          >
            {option.hash === currentToken?.hashId ? tokenSymbol : option.label}
            <Box>
              <Typography
                variant="h4"
                fontSize="12px"
                sx={{ pointerEvents: 'none', opacity: '0.5' }}
              >
                {trimHash(option.hash)}
              </Typography>
            </Box>
          </Box>
          {option.hasBondingCurve ? (
            <Box
              display="flex"
              justifyContent="end"
              width="auto"
              marginLeft="20px"
            >
              <Typography
                variant="h2"
                fontSize="12px"
                sx={{ pointerEvents: 'none' }}
              >
                +BC
              </Typography>
            </Box>
          ) : null}
        </li>
      )}
    />
  );
};
