import { Token } from 'types/Token';

export function findNewTokenIndex(array1: Token[], array2: Token[]) {
  for (let i = 0; i < array1.length; i += 1) {
    if (array1[i].hashId !== array2[i].hashId) {
      return i;
    }
  }
  return array2.length - 1;
}
