import {
  AppBar,
  Box,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from '@mui/material';
import variables from 'styles/variables.module.scss';
import { useIsMobile } from 'hooks/useMediaQuery';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import styles from './DAOHeader.module.scss';

export const DAOHeader = () => {
  const isMobile = useIsMobile();

  return (
    <AppBar
      color="transparent"
      position="fixed"
      sx={{
        backgroundColor: '#0a0b43',
        paddingLeft: variables.sidebarWidth,
        width: isMobile ? '-webkit-fill-available' : '100%',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      elevation={0}
    >
      <Box
        className={styles.header}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        height="60px"
        pl={4}
        pr={4}
      >
        <Select
          classes={{
            icon: styles.selectIcon,
          }}
          sx={{ color: 'white', width: '15rem' }}
          defaultValue={1}
        >
          <MenuItem value={1}>MyDAO</MenuItem>
          <MenuItem value={2}>Option #2</MenuItem>
        </Select>
        <Switch
          classes={{
            track: styles.switchTrack,
          }}
        />
        <Tooltip title="View notifications">
          <IconButton sx={{ color: 'white' }}>
            <NotificationsIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </AppBar>
  );
};
