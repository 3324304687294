import { styled } from '@mui/material';
import SliderComponent, { SliderProps } from '@mui/material/Slider';

export const Slider = styled(SliderComponent)<SliderProps>(() => ({
  margin: '20px 10px',
  color: '#00e1d4',
  '.MuiSlider-valueLabel': {
    color: '#fff',
    borderRadius: '50% 50% 50% 50%',
    backgroundColor: 'rgb(97,223,212,0.5)',
  },
  '.MuiSlider-markLabel': {
    color: '#fff',
  },
}));
