import { TokenTypeEnums } from 'enums';
// eslint-disable-next-line import/no-unresolved
import { request } from 'graphql-request';
import BigNumber from 'bignumber.js';
import {
  REACT_APP_IPFS_API_ENDPOINT,
  REACT_APP_WHITELIST_API_ENDPOINT,
} from 'conf';
import { getIpfsPinQuery } from 'queries/ipfsQuery';
import { getWhiteList } from 'queries/whiteListQuery';
import { findLastIndex } from 'utils/findLastIndex';
import intToHexString from 'utils/intToHexString';
import {
  L1Tokens,
  properties,
} from 'modules/tokenization/pages/BasicIssuance/options';
import {
  TokenUiCommand,
  UiCommand,
  CreateTokenUi,
  Field,
} from '@coinweb/cweb-wallet-library';

export function decodeL1s(l1States: boolean[], tokenType: TokenTypeEnums) {
  const lastIndex = findLastIndex(l1States);
  let res: string = '';
  l1States.forEach((l1, index) => {
    if (l1)
      res += `${L1Tokens[tokenType][index].value}${
        index !== lastIndex ? ',' : ''
      }`;
  });
  return res;
}
export function decodeProperties(
  propertyStates: boolean[],
  tokenType: TokenTypeEnums,
) {
  const lastIndex = findLastIndex(propertyStates);

  let res: string = '';
  propertyStates.forEach((property, index) => {
    if (property)
      res += `${properties[tokenType][index].value}${
        index !== lastIndex ? ',' : ''
      }`;
  });
  return res;
}
/* eslint-disable no-useless-escape */
export function createTokenJson(
  pubKey: string,
  initialSupply: string,
  decimals: BigNumber,
  extraFields: Field[],
) {
  const res = {
    TokenV1: {
      CreateTokenUi: {
        protocol_fields: {
          issuance_type: {
            Mintable: {
              minters: [
                {
                  auth: 'EcdsaContract',
                  payload: pubKey,
                },
              ],
            },
          },
          initial_token_supply: BigInt(
            intToHexString(
              BigNumber(initialSupply).multipliedBy(
                BigNumber(10).exponentiatedBy(decimals),
              ),
              64,
            ),
          ),
          token_admin: {
            auth: 'EcdsaContract',
            payload: pubKey,
          },
        },
        extra_fields: extraFields,
        memo: null,
      },
    },
  } as UiCommand;
  return JSON.stringify(res);
}
/* eslint-disable no-useless-escape */
export function createTokenCommand(
  pubKey: string,
  initialSupply: string,
  decimals: BigNumber,
  extraFields: Field[],
) {
  const res = {
    CreateTokenUi: {
      protocol_fields: {
        issuance_type: {
          Mintable: {
            minters: [
              {
                auth: 'EcdsaContract',
                payload: pubKey,
              },
            ],
          },
        },
        initial_token_supply: BigInt(
          intToHexString(
            BigNumber(initialSupply).multipliedBy(
              BigNumber(10).exponentiatedBy(decimals),
            ),
            64,
          ),
        ),
        token_admin: {
          auth: 'EcdsaContract',
          payload: pubKey,
        },
      },
      extra_fields: extraFields,
      memo: null,
    } as CreateTokenUi,
  };
  return res as TokenUiCommand;
}
export function updateTokenJson(hash: string, extraFields: Field[]) {
  const res = {
    TokenV1: {
      UpdateTokenUi: {
        token: hash,
        extra_fields: extraFields,
      },
    },
  } as UiCommand;
  return JSON.stringify(res);
}
export function updateTokenCommand(hash: string, extraFields: Field[]) {
  const res = {
    UpdateTokenUi: {
      token: hash,
      extra_fields: extraFields,
    },
  } as TokenUiCommand;
  return res;
}
export const getIpfs = async (hash: String | undefined) => {
  const res = request(REACT_APP_IPFS_API_ENDPOINT, getIpfsPinQuery, {
    hash,
  });
  return res;
};
export const getWhiteListed = async (tokenHash: String | undefined) => {
  const res = request(REACT_APP_WHITELIST_API_ENDPOINT, getWhiteList, {
    tokenHash,
  });
  return res;
};
export const convertBaseImg = async (ipfsData: any) => {
  const base64imgarr = ipfsData.getPin;
  const TYPED_ARRAY = new Uint8Array(base64imgarr);

  const STRING_CHAR = TYPED_ARRAY.reduce((sdata, byte) => {
    return sdata + String.fromCharCode(byte);
  }, '');

  const base64String = btoa(STRING_CHAR);
  return `data:image/png;base64,${base64String}`;
};
// eslint-disable-next-line import/no-unused-modules
export const handleTokenNameErrorText = (
  fillError: boolean,
  tokenName: string,
) => {
  if (fillError === true) return 'Please fill out Token Name field.';
  if (tokenName.length >= 40 && fillError === false)
    return 'Token Name exceeds character limit of 40.';
  return undefined;
};
// eslint-disable-next-line import/no-unused-modules
export const handleTokenSymbolErrorText = (
  fillError: boolean,
  tokenSymbol: string,
) => {
  if (fillError === true) return 'Please fill out Token Symbol field.';
  if (tokenSymbol.length >= 11 && fillError === false)
    return 'Token Symbol exceeds character limit of 10.';
  return undefined;
};
// eslint-disable-next-line import/no-unused-modules
export const handleInitialSupplyErrorText = (
  fillError: boolean,
  supplyLimit: BigNumber,
) => {
  if (fillError === true) return 'Please fill out Initial Supply field.';
  return `Initial Supply exceeds the limit of ${supplyLimit}`;
};
// eslint-disable-next-line import/no-unused-modules
export const handleMaximumSupplyErrorText = (
  fillError: boolean,
  supplyLimit: BigNumber,
) => {
  if (fillError === true) return 'Please fill out Maximum Supply field.';
  return `Maximum Supply exceeds the supply limit of ${supplyLimit}`;
};
