import { useState } from 'react';
import Button from '@mui/material/Button';
import {
  TokenUiCommand,
  embed,
  compose_token_command as composeTokenCommand,
  create_wallet as createWallet,
  from_hex_string as fromHexString,
  sign,
} from '@coinweb/cweb-wallet-library';
import { REACT_APP_API_ENDPOINT } from 'conf';
import Modal from '@mui/material/Modal';
import { mnemonicToHDKey } from 'utils/hdKey';
import { GradientBox } from '../Containers/Box';
import { InputTokenization } from '../Input/Input';

type PayButtonProps = {
  command: TokenUiCommand;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const PayButton = (props: PayButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const [mnemonic, setMnemonic] = useState<string | null>(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePay = async () => {
    try {
      if (mnemonic !== null) {
        const url = new URL('wallet', REACT_APP_API_ENDPOINT);
        const address = url.href;
        const wsAddress = address.replace(/^https:/, 'wss:');
        const hdkey = mnemonicToHDKey(mnemonic);

        const wallet = await createWallet({
          address,
          ws_address: wsAddress,
          shard: null,
          pub_key: hdkey.publicKey.toString('hex'),
          sign_callback: (msg) => {
            return sign(fromHexString(hdkey.privateKey.toString('hex')), msg);
          },
          max_retry_time_secs: null,
          enable_retries: null,
        });

        const res = await composeTokenCommand(wallet, props.command, null);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const uuid = await embed(wallet, res.l2_transaction);
        // eslint-disable-next-line no-console
        console.log('uuid', uuid);
        if (uuid) setOpen(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error at pay', e);
    }
  };

  return (
    <>
      <Button sx={{ marginBottom: '20px' }} onClick={handleOpen}>
        Pay Immediately
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <GradientBox sx={style}>
          <InputTokenization
            placeholder="Mnenemonic phrase"
            label="Mnenemonic"
            variant="outlined"
            value={mnemonic}
            onChange={(e) => setMnemonic(e.target.value)}
          />
          <Button sx={{ marginBottom: '20px' }} onClick={handlePay}>
            Pay Immediately
          </Button>
        </GradientBox>
      </Modal>
    </>
  );
};
