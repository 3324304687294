import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GradientBox } from '../../components/Containers/Box';
import { BeigeButton } from '../../components/ControlButtons/Button';

import { TransfersTable } from './Table';

export const BlacklistPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minHeight="100%"
      padding="0px 20px"
      alignItems="center"
    >
      <GradientBox sx={{ marginTop: '0px' }}>
        <Typography variant="h2" mb="20px">
          Blacklist
        </Typography>

        <TransfersTable />
      </GradientBox>
      <BeigeButton variant="outlined">Add Address</BeigeButton>
    </Box>
  );
};
