type radioOptions = {
  value: 0 | 1;
  label: string;
};

export const editibilityTypes: radioOptions[] = [
  { value: 0, label: 'Editable' },
  { value: 1, label: 'Finalize' },
];

export const daos = [
  { value: 'select', label: 'Create Dao' },
  { value: 'dao1', label: 'Token Management DAO' },
  { value: 'dao2', label: 'LinkMint Team Internal DAO' },
];

export const properties = {
  c1: [
    {
      value: 'm',
      label: 'Mintable',
      info: 'Privileged accounts will be able to create more supply.',
    },
    {
      value: 'v',
      label: 'Vesting',
      info: "Keeps track of individual units for voting in on-chain governance, with a way to delegate one's voting power to a trusted account. ",
    },

    {
      value: 'b',
      label: 'Burnable',
      info: 'Token holders will be able to destroy their tokens.',
    },
    {
      value: 'p',
      label: 'Pausible',
      info: 'Privileged accounts will be able to pause the functionality marked as whenNotPaused. Useful for emergency response.',
    },
    {
      value: 'bl',
      label: 'Blacklisting',
      info: 'Privileged accounts will be able to blacklist certain accounts, useful for regulatory compliance requirements and fraud prevention.',
    },
    {
      value: 'wl',
      label: 'Whitelisting',
      info: 'Privileged accounts will be able to blacklist certain accounts, useful for regulatory compliance requirements such as KYC/AML.',
    },
  ],
  c2: [
    {
      value: 'm',
      label: 'Mintable',
      info: 'Privileged accounts will be able to emit new tokens.',
    },
    {
      value: 'b',
      label: 'Burnable',
      info: 'Token holders will be able to destroy their tokens.',
    },
    {
      value: 'p',
      label: 'Pausible',
      info: 'Privileged accounts will be able to pause the functionality marked as whenNotPaused. Useful for emergency response.',
    },
    {
      value: 'v',
      label: 'Votes',
      info: "Keeps track of individual units for voting in on-chain governance, with a way to delegate one's voting power to a trusted account.",
    },
    {
      value: 'e',
      label: 'Enumarable',
      info: 'Allows on-chain enumeration of all tokens or those owned by an account. Increases gas cost of transfers.',
    },
    {
      value: 'u',
      label: 'URI Storage',
      info: 'Allows updating token URIs for individual token  IDs.',
    },
  ],
  c3: [
    {
      value: 'm',
      label: 'Mintable',
      info: 'Privileged accounts will be able to emit new tokens.',
    },
    {
      value: 'b',
      label: 'Burnable',
      info: 'Token holders will be able to destroy their tokens.',
    },
    {
      value: 'p',
      label: 'Pausible',
      info: 'Privileged accounts will be able to pause the functionality marked as whenNotPaused. Useful for emergency response.',
    },
    {
      value: 'vo',
      label: 'Votes',
      info: "Keeps track of individual units for voting in on-chain governance, with a way to delegate one's voting power to a trusted account.",
    },
    {
      value: 'e',
      label: 'Enumarable',
      info: 'Allows on-chain enumeration of all tokens or those owned by an account. Increases gas cost of transfers.',
    },
    {
      value: 'u',
      label: 'URI Storage',
      info: 'Allows updating token URIs for individual token  IDs.',
    },
  ],
};
export const L1Tokens = {
  c1: [
    { value: 'e20', label: 'ERC-20' },
    { value: 'b20', label: 'BEP-20' },
    { value: 'esdt', label: 'ESDT' },
  ],
  c2: [
    { value: 'erc721', label: 'ERC-721' },
    { value: 'erc998', label: 'ERC-998' },
    { value: 'trc721', label: 'TRC721' },
    { value: 'bep721', label: 'BEP-721' },
    { value: 'bep198', label: 'BEP-198' },
    { value: 'esdt', label: 'ESDT' },
  ],
  c3: [
    { value: 'erc20', label: 'ERC-20' },
    { value: 'bep20', label: 'BEP-20' },
    { value: 'erc721', label: 'ERC-721' },
    { value: 'esdt', label: 'ESDT' },
    { value: 'trx20', label: 'TRX-20' },
  ],
};

export const tokenFees: radioOptions[] = [
  { value: 0, label: 'Payable with my token and Cweb' },
  { value: 1, label: 'Payable with Cweb' },
];

export const shards = [
  { value: 'elrond', label: 'Elrond' },
  { value: 'bitcoin', label: 'Bitcoin' },
  { value: 'bitcoin_cash', label: 'Bitcoin Cash' },
  { value: 'bnb', label: 'BNB Chain' },
  { value: 'polygon', label: 'Polygon' },
];

export const uploadTypes: radioOptions[] = [
  { value: 0, label: 'Upload Image' },
  { value: 1, label: 'External URL' },
];

export const subtokenTypes: radioOptions[] = [
  { value: 0, label: 'NonFungible' },
  { value: 1, label: 'Fungible' },
];
