import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { REACT_APP_DEVNET } from 'conf';

export const BlueButton = styled(Button)({
  color: '#fff !important',
  marginTop: '20px',
  maxWidth: '260px',
  width: '-webkit-fill-available',
  height: '44px',
  borderColor:
    REACT_APP_DEVNET === 'true' ? '#00867e !important' : '#3778ff !important',
  backgroundColor:
    REACT_APP_DEVNET === 'true' ? '#00867e !important' : '#3778ff !important',
  '.MuiInputBase-root': {
    color:
      REACT_APP_DEVNET === 'true' ? '#00867e !important' : '#3778ff !important',
  },
  '.Mui-focused': {
    borderColor:
      REACT_APP_DEVNET === 'true' ? '#00867e !important' : '#3778ff !important',
  },
  '&:hover': {
    backgroundColor:
      REACT_APP_DEVNET === 'true' ? '#00867e !important' : '#3778ff !important',
  },
}) as typeof Button;

export const BeigeButton = styled(Button)(() => ({
  color: '#adadd0 !important',
  marginTop: '20px',
  maxWidth: '260px',
  width: '-webkit-fill-available',
  height: '44px',
  fontSize: '14px',
  borderColor: '#adadd0 !important',
  '.MuiInputBase-root': {
    color: '#adadd0!important',
    maxWidth: '260px',
  },
  '.Mui-focused': {
    borderColor: '#adadd0 !important',
  },
})) as typeof Button;

export const BridgeButton = styled(Button)(() => ({
  color: '#adadd0',
  border: 'none',
  borderBottom: '1px solid #adadd0',
  height: '50px',
  transition: 'none',
  borderRadius: '0px',
}));
