import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Table,
  TableBody,
} from '@mui/material';
import cx from 'classnames';

import styles from './Table.module.scss';

const createData = (
  name: string,
  startDate: string,
  endDate: string,
  minStakingPeriod: string,
  rewards: string,
  status: string,
  fund: string,
) => ({
  name,
  startDate,
  endDate,
  minStakingPeriod,
  rewards,
  status,
  fund,
});

const rows = [
  createData(
    'Name 1',
    '11/08/2022',
    '11/09/2022',
    '3 months',
    '100.0',
    'Open',
    'Fixed',
  ),
  createData(
    'Name 2',
    '09/05/2022',
    '09/07/2022',
    '1 month',
    '150.0',
    'Open',
    'Variable minted',
  ),
  createData(
    'Name 3',
    '21/11/2022',
    '21/12/2022',
    '2 months',
    '156.0',
    'Closed',
    'Manually',
  ),
];

export const StakingTable = () => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell className={styles.th}>Rank</TableCell>
            <TableCell className={styles.th} align="left">
              Start date
            </TableCell>
            <TableCell className={styles.th} align="left">
              End date
            </TableCell>
            <TableCell className={styles.th} align="left">
              Min staking period
            </TableCell>
            <TableCell className={styles.th} align="left">
              Rewards
            </TableCell>
            <TableCell className={styles.th} align="left">
              Status
            </TableCell>
            <TableCell className={styles.th} align="left">
              Fund
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell
                className={cx(styles.cell, 'opacity-90')}
                component="th"
                scope="row"
              >
                {row.name}
              </TableCell>
              <TableCell className={cx(styles.cell, 'opacity-90')} align="left">
                {row.startDate}
              </TableCell>
              <TableCell className={cx(styles.cell, 'opacity-90')} align="left">
                {row.endDate}
              </TableCell>
              <TableCell className={cx(styles.cell, 'opacity-90')} align="left">
                {row.minStakingPeriod}
              </TableCell>
              <TableCell className={cx(styles.cell, 'opacity-90')} align="left">
                {row.rewards}
              </TableCell>
              <TableCell className={cx(styles.cell, 'opacity-90')} align="left">
                {row.status}
              </TableCell>
              <TableCell className={cx(styles.cell, 'opacity-90')} align="left">
                {row.fund}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
