import { Box, Typography } from '@mui/material';
import { useIsMobile } from 'hooks/useMediaQuery';
import round from 'utils/round';
import { GradientBox } from '../../../components/Containers/Box';
import { Quadratic } from '../liquidityTypes';
import styles from '../Liquidity.module.scss';

type Props = {
  value: Quadratic;
};

export const PriceFormulaSummarySection = (props: Props) => {
  const isMobile = useIsMobile();

  return (
    <GradientBox className="mt-5">
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h2">Formula Summary</Typography>
      </Box>

      <Box
        display={isMobile ? 'block' : 'grid'}
        className={isMobile ? '' : 'w-full grid-cols-2 gap-5'}
      >
        <Box width="100%" mb={isMobile ? 2 : 0}>
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Typography>Buy Price</Typography>
            <Typography variant="h4" className="mt-2" fontSize="14px">
              -1 / (2a<span className="sub">buy</span>x + b
              <span className="sub">buy</span>)
            </Typography>
          </Box>
          <Typography
            fontSize="16px"
            className="break-words"
            textAlign={isMobile ? 'right' : undefined}
          >
            -1 / (
            <span id={styles.var}>
              {round(props.value.buy.a.multipliedBy(2), 5).toFixed()}
            </span>
            x +{' '}
            <span id={styles.var}>{round(props.value.buy.b, 5).toFixed()}</span>
            )
          </Typography>
        </Box>

        <Box width="100%" mb={isMobile ? 2 : 0}>
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Typography>Sell Price</Typography>
            <Typography variant="h4" className="mt-2" fontSize="14px">
              -1 / (2a<span className="sub">sell</span>x + b
              <span className="sub">sell</span>)
            </Typography>
          </Box>
          <Typography
            fontSize="16px"
            className="break-words"
            textAlign={isMobile ? 'right' : undefined}
          >
            -1 / (
            <span id={styles.var}>
              {round(props.value.sell.a.multipliedBy(2), 5).toFixed()}
            </span>
            x +{' '}
            <span id={styles.var}>
              {round(props.value.sell.b, 5).toFixed()}
            </span>
            )
          </Typography>
        </Box>

        <Box width="100%" mb={isMobile ? 2 : 0}>
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Typography>Buy Amount</Typography>
            <Typography variant="h4" className="mt-2" fontSize="14px">
              a<span className="sub">buy</span>x<span className="super">2</span>
              + b<span className="sub">buy</span> x+ c
              <span className="sub">buy</span>{' '}
            </Typography>
          </Box>
          <Typography
            fontSize="16px"
            className="break-words"
            textAlign={isMobile ? 'right' : undefined}
          >
            <span id={styles.var}>
              {round(props.value.sell.a, 5).toFixed()}
            </span>
            x<span className="super">2</span> +{' '}
            <span id={styles.var}>{round(props.value.buy.b, 5).toFixed()}</span>
            x +{' '}
            <span id={styles.var}>{round(props.value.buy.c, 5).toFixed()}</span>
          </Typography>
        </Box>

        <Box width="100%">
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Typography>Sell Amount</Typography>
            <Typography variant="h4" className="mt-2" fontSize="14px">
              a<span className="sub">sell</span>x
              <span className="super">2</span>+ b
              <span className="sub">sell</span> x+ c
              <span className="sub">sell</span>{' '}
            </Typography>
          </Box>
          <Typography
            fontSize="16px"
            className="break-words"
            textAlign={isMobile ? 'right' : undefined}
          >
            <span id={styles.var}>
              {round(props.value.sell.a, 5).toFixed()}
            </span>
            x<span className="super">2</span> +{' '}
            <span id={styles.var}>
              {round(props.value.sell.b, 5).toFixed()}
            </span>
            x +{' '}
            <span id={styles.var}>
              {round(props.value.sell.c, 5).toFixed()}
            </span>
          </Typography>
        </Box>
      </Box>
    </GradientBox>
  );
};
