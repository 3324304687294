import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const InputTokenization = styled(TextField)(() => ({
  width: '100%',
  color: '#fff !important',
  marginTop: '10px',

  '.MuiInputBase-root': {
    color: '#adadd0 !important',
    maxHeight: '40px',
  },
  '.Mui-focused': {
    border: '0px ppx 0px !important',
  },
  '.MuiInputLabel-root': {
    color: '#adadd0 !important',
    marginTop: '-5px',
  },
  '.MuiOutlinedInput-input': {
    color: '#adadd0',
  },
  '.MuiFormHelperText-root': {
    color: '#adadd0',
  },
  '.Mui-disabled': {
    color: '#ffffff !important',
    WebkitTextFillColor: '#fff  !important',
  },
}));

export const InputDescription = styled(TextField)(() => ({
  width: '100%',
  color: '#fff !important',
  marginTop: '10px',
  WebkitTextFillColor: 'none !important',
  '.MuiInputBase-root': {
    color: '#adadd0 !important',
    maxHeight: '90px',
  },
  '.MuiOutlinedInput-input': {
    color: '#adadd0 !important',
    maxHeight: '90px',
    overflow: 'scroll !important',
  },
  '.Mui-focused': {
    borderColor: '#e4e3ed !important',
  },
  '.MuiInputLabel-root': {
    color: '#adadd0 !important',
    marginTop: '-5px',
  },
  '.Mui-disabled': {
    color: '#ffffff !important',
    WebkitTextFillColor: '#fff  !important',
  },
}));
