export enum TokenTypeEnums {
  FUNGIBLE = 'c1',
  NONFUNGIBLE = 'c2',
  MULTI = 'c3',
}

export enum TokenFields {
  NAME = 'name',
  CODE = 'symbol',
  ICON = 'ipfs_image_url',
  EXPONENT = 'exponent',
  PROPERTY = 'c14e',
  L1_TOKEN_TYPE = 'b4e',
  MAXIMUM_SUPPLY = 'ms',
  DESCRIPTION = 'd9n',
  FEE = 'fee',
  TOKEN_TYPE = 'std',
  EDITIBILITY_TYPE = 'e',
}

export enum BondingCurveFields {
  TYPE = 'curve_type',
  PRICE = 'price',
  NAKED = 'naked_tokens',
  K = 'acceleration',
  FEE = 'fee',
}
