import { InfoOutlined } from '@mui/icons-material';

import { Sidebar } from 'components/Sidebar';
import { routePaths } from 'routePaths';

const ITEMS = [
  {
    text: 'Transactions',
    url: routePaths.DAO.TRANSACTIONS,
    icon: <InfoOutlined className="text-inherit" />,
    items: [
      {
        text: 'Queue',
        url: routePaths.DAO.QUEUE,
      },
      {
        text: 'History',
        url: routePaths.DAO.HISTORY,
      },
    ],
  },
  {
    text: 'Address book',
    url: routePaths.DAO.ADDRESS_BOOK,
  },
  {
    text: 'Settings',
    url: routePaths.DAO.SETTINGS,
    items: [
      {
        text: 'Details',
        url: routePaths.DAO.DETAILS,
      },
      {
        text: 'Owners',
        url: routePaths.DAO.OWNERS,
      },
      {
        text: 'Spending Limit',
        url: routePaths.DAO.SPENDING_LIMIT,
      },
    ],
  },
];

export const DAOSidebar = () => {
  return <Sidebar items={ITEMS} isDAO />;
};
