import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { REACT_APP_MODE } from 'conf';
import { useCurrentToken } from 'hooks/useCurrentToken';
import { PreviewLiquidityTokenSection } from './sections/PreviewLiquidityTokenSection';
import { PreviewFlowTypeSection } from './sections/PreviewFlowTypeSection';
import { PreviewPricingSetupSection } from './sections/PreviewPricingSetupSection';
import { PreviewPriceTypeSection } from './sections/PreviewPriceTypeSection';
import { PreviewBondingCurveSection } from './sections/PreviewBondingCurveSection';
import { WithdrawBCSurplusSection } from './sections/WithdrawBCSurplusSection';
import {
  flowTypes,
  priceTypes,
  pricingTypes,
  Quadratic,
} from './liquidityTypes';
import {
  BeigeButton,
  BlueButton,
} from '../../components/ControlButtons/Button';
import { CenteredBox, PreviewWrapper } from '../../components/Containers/Box';
import { PriceParameterSummarySection } from './sections/PriceParameterSummarySection';
import { PriceFormulaSummarySection } from './sections/PriceFormulaSummarySection';

type LiquidityPreviewProps = {
  tokenHash?: string;
  flowType: flowTypes;
  priceParams: Quadratic;
  pricingType: pricingTypes;
  priceType: priceTypes;
  setPreview: (value: boolean) => void;
  setCreation: (value: boolean) => void;
  handleCreate: () => void;
};

export function LiquidityPreview({
  tokenHash,
  flowType,
  pricingType,
  priceType,
  priceParams,
  setPreview,
  setCreation,
  handleCreate,
}: LiquidityPreviewProps) {
  const navigate = useNavigate();

  const { tokenName, tokenSymbol, fungibleCurrentImg } = useSelector(
    (state: any) => state.token,
  );

  const [changed, setChanged] = useState<boolean>(false);
  const [token] = useCurrentToken();

  function compareArrays(arr1: BigNumber[], arr2: BigNumber[]): boolean {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Use the .every method to check if all elements are equal
    return arr1.every((element, index) => element.isEqualTo(arr2[index]));
  }
  useEffect(() => {
    if (token?.bondingCurve) {
      const buyPoly = [priceParams.buy.c, priceParams.buy.b, priceParams.buy.a];

      const sellPoly = [
        priceParams.sell.c,
        priceParams.sell.b,
        priceParams.sell.a,
      ];

      if (
        compareArrays(token?.bondingCurve.buy.poly, buyPoly) &&
        compareArrays(token?.bondingCurve.sell.poly, sellPoly)
      )
        setChanged(false);
      else setChanged(true);
    }
  }, [
    priceParams.buy.a,
    priceParams.buy.b,
    priceParams.buy.c,
    priceParams.sell.a,
    priceParams.sell.b,
    priceParams.sell.c,
    token?.bondingCurve,
  ]);

  const handleEdit = () => {
    navigate('.', {
      state: {
        edit: true,
      },
    });
    setPreview(false);
    setCreation(false);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" padding="0px 20px">
      {' '}
      <PreviewWrapper>
        <PreviewLiquidityTokenSection
          tokenName={tokenName}
          tokenSymbol={tokenSymbol}
          baseImg={fungibleCurrentImg}
        />
        {tokenHash && token && token.bondingCurve ? (
          <WithdrawBCSurplusSection hash={tokenHash} />
        ) : null}
        {(REACT_APP_MODE === 'pro' || REACT_APP_MODE === 'devnet') && (
          <PreviewFlowTypeSection value={flowType} />
        )}
        <PreviewPricingSetupSection value={pricingType} />
        <PreviewPriceTypeSection value={priceType} />
        <PreviewBondingCurveSection
          value={priceType}
          yTokenName={tokenSymbol}
          params={priceParams}
        />
        <Box mt="20px">
          <PriceParameterSummarySection
            priceType={priceType}
            quadratic={priceParams}
          />
        </Box>
        <PriceFormulaSummarySection value={priceParams} />
        <CenteredBox width="100%" className="mt-5 flex">
          <BeigeButton variant="outlined" className="mr-5" onClick={handleEdit}>
            Edit Bonding Curve
          </BeigeButton>
          {(changed || !token?.bondingCurve) && (
            <BlueButton variant="outlined" onClick={handleCreate}>
              Broadcast Changes
            </BlueButton>
          )}
        </CenteredBox>
      </PreviewWrapper>
    </Box>
  );
}
