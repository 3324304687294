import { ChangeEvent, useCallback, useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { REACT_APP_DEVNET } from 'conf';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  SxProps,
} from '@mui/material';
import checkDecimalAllZero from 'utils/checkDecimalAllZero';
import getDecimalsCount from 'utils/getDecimalsCount';
import LeftIcon from 'images/icons/numeric_left.svg';
import RightIcon from 'images/icons/numeric_right.svg';
import commonStyles from 'styles/commonStyles.module.scss';
import { NumberFormatCustom } from './NumberFormatCustom';
import styles from './NumericInput.module.scss';

type Props = {
  suffix?: string;
  prefix?: string;
  error?: boolean;
  maxValue?: BigNumber;
  minError?: boolean;
  helperText?: string;
  infoText?: string;
  errorText?: string;
  allowDecimals?: boolean;
  allowNegative?: boolean;
  allowPositive?: boolean;
  increment?: number;
  name?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: BigNumber;
  onChange: (value: BigNumber, name?: string) => void;
  sx?: SxProps;
  containerSx?: SxProps;
  inputLabelSx?: SxProps;
  disabledText?: string;
  minErrorText?: string;
  dispatch: boolean;
};

export const NumericInput = (props: Props) => {
  const { onChange, name, allowNegative, allowPositive, increment = 1 } = props;
  const dispatch = useDispatch();

  const [positiveError, setPositiveError] = useState<boolean>(false);
  const [negativeError, setNegativeError] = useState<boolean>(false);
  const [maxError, setMaxError] = useState<boolean>(false);

  const [precision, setPrecision] = useState<number>(
    getDecimalsCount(props.value ?? '0'),
  );

  const strState = props.value?.toFixed(precision) ?? '';
  const inputRef = useRef<HTMLInputElement | null>(null);
  if (inputRef && inputRef.current && inputRef.current.value && props.value) {
    inputRef.current.value = props.value.toFixed();
  }
  useEffect(() => {
    if (props.value) {
      if (getDecimalsCount(strState) < getDecimalsCount(props.value))
        setPrecision(getDecimalsCount(props.value));
      if (getDecimalsCount(strState) < getDecimalsCount(props.value))
        setPrecision(getDecimalsCount(props.value));
    }
  }, [props.value, strState]);

  const handleIncrease = useCallback(() => {
    setNegativeError(false);
    let value = props.value ?? BigNumber(0);
    const canNotIncrease =
      props.value !== undefined &&
      allowPositive === false &&
      (props.value.plus(increment).isPositive() ||
        props.value.plus(increment).isGreaterThan(0));
    if (canNotIncrease) {
      value = BigNumber(0);
      setPositiveError(true);
    }
    const isExceed =
      props.value !== undefined &&
      props.maxValue &&
      props.value.plus(increment).isGreaterThan(props.maxValue);

    if (props.value !== undefined && !canNotIncrease && !isExceed) {
      value = props.value.plus(increment);
      setPositiveError(false);
    }
    if (isExceed && props.maxValue) value = props.maxValue;
    setPrecision(getDecimalsCount(value));
    if (props.dispatch) dispatch(onChange(value));
    else onChange(value, name);
  }, [
    allowPositive,
    increment,
    name,
    onChange,
    dispatch,
    props.maxValue,
    props.dispatch,
    props.value,
  ]);

  const handleDecrease = useCallback(() => {
    setPositiveError(false);
    let value = props.value ?? BigNumber(0);
    const canNotDecrease =
      props.value !== undefined &&
      allowNegative === false &&
      (props.value.minus(increment).isNegative() ||
        props.value.minus(increment).isLessThan(0));
    if (canNotDecrease) {
      setNegativeError(true);
      value = BigNumber(0);
    }
    if (props.value !== undefined && !canNotDecrease) {
      value = props.value.minus(increment);
      setNegativeError(false);
    }
    // This piece of code to check if new value has decimals
    // if yes, set Fixed precision according to decimals number
    setPrecision(getDecimalsCount(value));

    if (props.dispatch) dispatch(onChange(value));
    else onChange(value, name);
  }, [
    allowNegative,
    props.value,
    increment,
    name,
    onChange,
    dispatch,
    props.dispatch,
  ]);

  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      let value;
      if (
        props.maxValue &&
        BigNumber(ev.target.value).isGreaterThan(props.maxValue)
      ) {
        value = props.maxValue;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (props.allowDecimals) {
          if (!allowPositive && BigNumber(ev.target.value).isGreaterThan(0)) {
            value = '0';
            if (inputRef.current) {
              inputRef.current.blur();
            }
            setPositiveError(true);
          } else if (
            !allowNegative &&
            BigNumber(ev.target.value).isLessThan(0)
          ) {
            value = '0';
            if (inputRef.current) {
              inputRef.current.blur();
            }
            setNegativeError(true);
          } else {
            value = ev.target.value;
            setPositiveError(false);
            setNegativeError(false);
          }
        } else if (
          !allowPositive &&
          BigNumber(ev.target.value).isGreaterThan(0)
        ) {
          value = '0';
          if (inputRef.current) {
            inputRef.current.blur();
          }
          setPositiveError(true);
        } else if (!allowNegative && BigNumber(ev.target.value).isLessThan(0)) {
          value = '0';
          if (inputRef.current) {
            inputRef.current.blur();
          }
          setNegativeError(true);
        } else {
          value = String(parseInt(ev.target.value, 10));
          setPositiveError(false);
          setNegativeError(false);
        }
      }

      const convertedValue = BigNumber(value);
      setPrecision(getDecimalsCount(value));
      if (props.dispatch) dispatch(onChange(convertedValue, name));
      else onChange(convertedValue, name);
    },
    [
      name,
      onChange,
      props.allowDecimals,
      allowNegative,
      allowPositive,
      props.maxValue,
      dispatch,
      props.dispatch,
    ],
  );

  const handleBlur = useCallback(() => {
    if (precision !== 0) {
      const isAllZeroes = checkDecimalAllZero(strState);
      if (isAllZeroes) setPrecision(0);
      else setPrecision(getDecimalsCount(props.value ?? strState));
    }
  }, [props.value, strState, precision]);
  return (
    <FormControl
      sx={{
        width: '100%',
        ...props.containerSx,
      }}
      variant="outlined"
    >
      <InputLabel
        sx={{
          '&.MuiInputLabel-root': {
            marginTop: '5px',
          },
          ...props.inputLabelSx,
        }}
        className={commonStyles.mutedText}
        shrink={props.value !== null}
      >
        {props.label || props.placeholder}
      </InputLabel>
      <OutlinedInput
        inputRef={inputRef}
        inputProps={{
          suffix: props.suffix,
          prefix: props.prefix,
          allowNegative: props.allowNegative,
          max: props.maxValue,
          onChange: handleChange,
          value: props.value,
          onErrorChange: setMaxError,
        }}
        inputComponent={NumberFormatCustom as any}
        className={styles.input}
        disabled={props.disabled}
        value={strState}
        placeholder={props.placeholder}
        label={props.label}
        sx={{
          paddingRight: 0,
          height: '40px',
          ...props.sx,
        }}
        onChange={handleChange}
        onBlur={handleBlur}
        endAdornment={
          <InputAdornment position="end">
            <Box height="40px" display="flex">
              <Button
                disabled={props.disabled}
                className={cx(styles.button, styles.buttonDecrease)}
                onClick={handleDecrease}
              >
                <img src={LeftIcon} alt="left_icon" />
              </Button>
              <Button
                disabled={props.disabled}
                className={cx(styles.button, styles.buttonIncrease)}
                onClick={handleIncrease}
              >
                <img src={RightIcon} alt="left_icon" />
              </Button>
            </Box>
          </InputAdornment>
        }
      />
      {props.helperText &&
      props.maxValue &&
      props.value &&
      props.value.isLessThan(props.maxValue) &&
      !maxError ? (
        <FormHelperText sx={{ color: '#adadd0' }}>
          {props.helperText}
        </FormHelperText>
      ) : undefined}
      {props.infoText ? (
        <FormHelperText
          sx={{
            color: REACT_APP_DEVNET === 'true' ? '#00867e' : '#4877f6',
            marginTop: '-3px',
          }}
        >
          {props.infoText}
        </FormHelperText>
      ) : undefined}
      {props.disabledText && props.disabled ? (
        <FormHelperText sx={{ color: '#adadd0' }}>
          {props.disabledText}
        </FormHelperText>
      ) : undefined}
      {(props.errorText &&
        props.maxValue &&
        props.value &&
        props.value.isGreaterThan(props.maxValue) &&
        !props.disabled) ||
      props.error ||
      maxError ? (
        <FormHelperText error>{props.errorText}</FormHelperText>
      ) : undefined}
      {props.minErrorText &&
      props.minError &&
      props.value &&
      !props.disabled ? (
        <FormHelperText error>{props.minErrorText}</FormHelperText>
      ) : undefined}
      {positiveError ? (
        <FormHelperText error>Value can not be positive.</FormHelperText>
      ) : undefined}
      {negativeError ? (
        <FormHelperText error>Value can not be negative.</FormHelperText>
      ) : undefined}
    </FormControl>
  );
};
