import { useNavigate } from 'react-router-dom';
import { useCurrentToken } from 'hooks/useCurrentToken';
import { usePubKeyParam } from 'hooks/usePubKeyParam';

export const useNavigateIssuance = () => {
  const [token] = useCurrentToken();
  const pubKey = usePubKeyParam();
  const navigate = useNavigate();

  if (!token) {
    navigate(`/${pubKey}/issuance/fungible`);
  }
};
