import { SetStateAction } from 'react';

export function resolveHookState<S, C extends S>(
  nextState: SetStateAction<S>,
  currentState?: C,
): S {
  if (typeof nextState === 'function') {
    return (nextState as Function)(currentState);
  }

  return nextState;
}
