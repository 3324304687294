import Backdrop from '@mui/material/Backdrop';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import iconOk from 'images/success.svg';
import { OperationType } from 'types/operationType';
import {
  fieldModalMessage,
  fieldTokenCreation,
} from 'redux/reducers/tokenReducer';
import styles from './styles.module.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: 320,
  border: '1px solid rgba(153, 161, 189, 0.14)',
  boxShadow: 24,
  color: 'var(--main-white-color)',
  p: '16px',
  backgroundColor: '#1f1d47',
};

type SuccessNotificationModalProps = {
  operationType: OperationType | undefined;
  onClose: (value: boolean) => void;
};

const SuccessNotificationModal = (props: SuccessNotificationModalProps) => {
  const { modalMessage, tokenName } = useSelector((state: any) => state.token);
  const dispatch = useDispatch();

  const open =
    modalMessage &&
    props.operationType &&
    modalMessage.type === props.operationType &&
    ((modalMessage.type !== OperationType.CUSTOM_TOKEN_UPDATED &&
      tokenName === modalMessage.tokenName) ||
      modalMessage.type === OperationType.CUSTOM_TOKEN_UPDATED);

  return (
    modalMessage &&
    modalMessage.text && (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {
          dispatch(fieldModalMessage(undefined));
          props.onClose(true);
          dispatch(fieldTokenCreation(true));
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={styles.admissible}>
              <img src={iconOk} alt="" />
            </div>
            <div className={styles.validity}>
              <div className={styles.validityBlock}>{modalMessage.text}</div>
            </div>
            <div
              className={styles.greenBlock}
              onClick={() => {
                dispatch(fieldModalMessage(undefined));
                dispatch(fieldTokenCreation(true));
                props.onClose(true);
              }}
            >
              <div className={styles.greenText}>
                <p>Close</p>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    )
  );
};

export default SuccessNotificationModal;
