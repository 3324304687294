import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {
  GradientBox,
  SpaceBetweenBox,
} from '../../../components/Containers/Box';
import { priceTypes, Quadratic } from '../liquidityTypes';
import { AmountChart } from './AmountChart';

type Props = {
  params: Quadratic;
  value: priceTypes;
  yTokenName: string;
};
export const PreviewBondingCurveSection = (props: Props) => {
  return (
    <GradientBox>
      <SpaceBetweenBox sx={{ marginBottom: '30px' }}>
        <Typography variant="h2">Bonding Curve</Typography>
        <Typography variant="h4" fontWeight={600}>
          {props.value}
        </Typography>
      </SpaceBetweenBox>
      <Box display="flex" flexDirection="column">
        <AmountChart value={props.params} yTokenName={props.yTokenName} />
      </Box>
    </GradientBox>
  );
};
