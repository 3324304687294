// eslint-disable-next-line import/no-unresolved
import { gql } from 'graphql-request';

export const createIpfsAddPinQuery = gql`
  mutation IpfsAddPin($input: String!) {
    ipfsAddPin(base64: $input, imgChecks: true)
  }
`;

export const getIpfsPinQuery = gql`
  query getPin($hash: String!) {
    getPin(hash: $hash)
  }
`;
