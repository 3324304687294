import { Box, Typography } from '@mui/material';
import { useIsMobile } from 'hooks/useMediaQuery';
import { GradientBox } from '../../../components/Containers/Box';
import { InputTokenization } from '../../../components/Input/Input';

type Props = {
  disabled: boolean;
};

export const LiquiditySetupSection = (props: Props) => {
  const isMobile = useIsMobile();

  return (
    <GradientBox>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h2">Liquidity Setup</Typography>
      </Box>

      <Box mb={3}>
        <Typography className="font-medium" variant="body2">
          Initial Cweb Quantity
        </Typography>

        <InputTokenization
          disabled={props.disabled}
          placeholder="Insert Amount"
          variant="outlined"
          sx={{ width: isMobile ? '100%' : '500px' }}
          type="number"
        />
      </Box>

      <Box mb={3}>
        <Typography className="font-medium" variant="body2">
          Liquidity pool balance
        </Typography>
        <InputTokenization
          disabled={props.disabled}
          placeholder="Insert Amount"
          variant="outlined"
          sx={{ width: isMobile ? '100%' : '500px' }}
          type="number"
        />
      </Box>
      <Box>
        <Typography className="font-medium" variant="body2">
          Initial fee percentage
        </Typography>
        <InputTokenization
          disabled={props.disabled}
          placeholder="Insert Amount"
          variant="outlined"
          sx={{ width: isMobile ? '100%' : '500px' }}
          type="number"
        />
      </Box>
    </GradientBox>
  );
};
