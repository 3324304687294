import { styled } from '@mui/material/styles';
import SwitchComponent from '@mui/material/Switch';

export const Switch = styled(SwitchComponent)(() => ({
  color: '#94fdfc',
  minWidth: '60px',
  '& .MuiSwitch-switchBase': {
    color: '#d4d4ee',
    '&.Mui-checked': {
      color: '#94fdfc',
      '& + .MuiSwitch-track': {
        backgroundColor: '#474288',
      },
    },
  },

  '& .MuiSwitch-track': {
    height: 10,
    backgroundColor: '#94fdfc !important',
    color: '#94fdfc !important',
    '&:before': {
      backgroundColor: '#94fdfc',
    },
    '&:after': {
      backgroundColor: '#94fdfc',
    },
  },

  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    marginLeft: 3,
  },
}));
