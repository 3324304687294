import { styled } from '@mui/material/styles';
import TableContainerComponent from '@mui/material/TableContainer';
import TableCellComponent from '@mui/material/TableCell';
import TooltipComponent from '@mui/material/Tooltip';

export const TableContainer = styled(TableContainerComponent)({
  backgroundColor: 'transparent',
  padding: '0px',
  boxShadow: '0px 0px 0px !important',
});

export const TableCell = styled(TableCellComponent)({
  color: '#c9c6dc',
  borderBottom: '1px solid #94fdfc !important',
});

export const Tooltip = styled(TooltipComponent)({
  color: '#c9c6dc',
  borderBottom: '1px solid #94fdfc !important',
});
