import { Outlet, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';

import { routePaths } from 'routePaths';
import { OwnersPage } from '../../pages/Owners';
import { AddressBookPage } from '../../pages/AddressBook';
import { DetailsPage } from '../../pages/Details';
import { QueuePage } from '../../pages/Queue';
import { HistoryPage } from '../../pages/History';
import { SpendingLimitPage } from '../../pages/SpendingLimit';
import { DAOHeader } from './DAOHeader';
import { DAOSidebar } from './DAOSidebar';

export const DAO = () => {
  return (
    <Box display="flex" flexDirection="column">
      <DAOHeader />
      <Box display="flex">
        <DAOSidebar />
        <Box pt={15} pl={8} pr={8} pb={8} width="100%">
          <Routes>
            <Route path={routePaths.DAO.TRANSACTIONS} element={<Outlet />}>
              <Route path={routePaths.DAO.QUEUE} element={<QueuePage />} />
              <Route path={routePaths.DAO.HISTORY} element={<HistoryPage />} />
            </Route>

            <Route
              path={routePaths.DAO.ADDRESS_BOOK}
              element={<AddressBookPage />}
            />

            <Route path={routePaths.DAO.SETTINGS} element={<Outlet />}>
              <Route path={routePaths.DAO.DETAILS} element={<DetailsPage />} />
              <Route path={routePaths.DAO.OWNERS} element={<OwnersPage />} />
              <Route
                path={routePaths.DAO.SPENDING_LIMIT}
                element={<SpendingLimitPage />}
              />
            </Route>
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};
