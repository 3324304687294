import React from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { TokenUiCommand } from '@coinweb/cweb-wallet-library';
import BigNumber from 'bignumber.js';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/material/Box';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { OperationType } from 'types/operationType';
import SuccessNotificationModal from 'components/Modals/SuccessNotificationModal';
import {
  L1TokensInfotext,
  tokenTypeInfotext,
  tokenCreationInfotext,
  tokenPropertiesInfoText,
  tokenSubTokenInfoText,
  tokenFeePaymentInfoText,
} from 'texts';
import { findLastIndex } from 'utils/findLastIndex';
import useWindowDimensions from 'utils/getWindowDimensions';
import QRCode from 'react-qr-code';
import round from 'utils/round';
import { routePaths } from 'routePaths';
import { InfoIcon } from '../../components/Icons/Icons';
import {
  GradientBox,
  TextBox,
  SpaceBetweenBox,
  CenteredBox,
} from '../../components/Containers/Box';
import { PayButton } from '../../components/ControlButtons/PayButton';
import {
  editibilityTypes,
  daos,
  properties,
  L1Tokens,
  tokenFees,
  shards,
  uploadTypes,
  subtokenTypes,
} from './options';
import styles from './BasicIssuance.module.scss';
import { SelectTokenization } from '../../components/Input/Select';
import {
  BpCheckedIconCheck,
  BpIconCheck,
} from '../../components/ControlButtons/Check';
import {
  BpIconRadio,
  BpCheckedIconRadio,
} from '../../components/ControlButtons/Radio';
import { AmountChart } from '../Liquidity/sections/AmountChart';
import { Quadratic } from '../Liquidity/liquidityTypes';
import { BlueButton } from '../../components/ControlButtons/Button';

export type BondingCurveStateType = {
  supply?: BigNumber;
  profit?: BigNumber;
  quadratic: Quadratic | undefined;
};

type EditibilitySectionProps = BoxProps & {
  editibilityType: number | undefined;
  onEditibilityChange: (value: 0 | 1) => void;
};
export const EditibilitySection = (props: EditibilitySectionProps) => (
  <GradientBox sx={{ marginTop: '0px !important' }}>
    <>
      <Typography variant="h2">
        Token Type <InfoIcon title={tokenTypeInfotext} />
      </Typography>
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup
          row
          value={props.editibilityType}
          onChange={(e: any) => props.onEditibilityChange(e.target.value)}
        >
          {editibilityTypes &&
            editibilityTypes.map((editibility) => (
              <FormControlLabel
                value={editibility.value}
                control={
                  <Radio
                    icon={<BpIconRadio />}
                    checkedIcon={<BpCheckedIconRadio />}
                  />
                }
                label={editibility.label}
                key={editibility.value}
                sx={{ marginRight: '30px' }}
              />
            ))}
        </RadioGroup>
      </FormControl>
    </>
  </GradientBox>
);
type PreviewEditibilitySectionProps = BoxProps & {
  editibility: string;
};
export const PreviewEditibilitySection = (
  props: PreviewEditibilitySectionProps,
) => (
  <GradientBox>
    <SpaceBetweenBox sx={{ marginTop: '10px' }}>
      <Typography variant={props.editibility === '' ? 'h3' : 'h2'}>
        Token Type
      </Typography>
      <Typography fontWeight={600} sx={{ fontSize: '14px', color: '#adadd0' }}>
        {props.editibility}
      </Typography>
    </SpaceBetweenBox>
  </GradientBox>
);

type DAOSectionProps = BoxProps & {
  dao: string;
  onDAOChange: (value: string) => void;
};
export const DAOSection = (props: DAOSectionProps) => (
  <GradientBox sx={{ marginTop: '0px !important' }}>
    <FormControl sx={{ width: '100%' }}>
      <InputLabel className={styles.selectLabel}>Select DAO</InputLabel>
      <SelectTokenization
        value={props.dao || ''}
        label="Select DAO"
        onChange={(e) => {
          if (e.target.value === 'select') {
            window.open('/dao', '_blank');
          } else
            props.onDAOChange(
              typeof e.target.value === 'string' ? e.target.value : '',
            );
        }}
      >
        {daos &&
          daos.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
      </SelectTokenization>
      <Typography
        variant="h4"
        fontSize="12px !important"
        sx={{ marginTop: '10px' }}
      >
        You can attach your DAO later.
      </Typography>
    </FormControl>
  </GradientBox>
);

/* eslint-disable @typescript-eslint/no-unused-vars */

type PropertiesSectionProps = BoxProps & {
  propertyStates: boolean[];
  tokenType: 'c1' | 'c2' | 'c3';
  handleProperties: (value: boolean, index: number) => void;
};
export const PropertiesSection = (props: PropertiesSectionProps) => (
  <GradientBox>
    <>
      <Typography variant="h2">
        Properties <InfoIcon title={tokenPropertiesInfoText} />
      </Typography>
      <FormControl
        sx={{
          width: '100%',
          display: '-webkit-inline-box',
          justifyContent: 'space-between',
        }}
      >
        {properties &&
          properties[props.tokenType]?.map((property, index: number) => (
            <FormControlLabel
              value={property.value}
              control={
                <Checkbox
                  checked={props.propertyStates[index]}
                  checkedIcon={<BpCheckedIconCheck />}
                  icon={<BpIconCheck />}
                  onChange={(event) =>
                    props.handleProperties(event.target.checked, index)
                  }
                />
              }
              label={
                <TextBox>
                  <Typography fontSize={14} fontWeight={500}>
                    {property.label}
                  </Typography>
                  <InfoIcon title={property.info} />
                </TextBox>
              }
              key={property.label}
            />
          ))}
      </FormControl>
    </>
  </GradientBox>
);

type PreviewPropertiesSectionProps = BoxProps & {
  propertyStates: boolean[];
  tokenType: 'c1' | 'c2' | 'c3';
};
export const PreviewPropertiesSection = (
  props: PreviewPropertiesSectionProps,
) => {
  const lastIndex = findLastIndex(props.propertyStates);
  const { propertyStates, tokenType } = props;

  return (
    <GradientBox>
      <>
        <Typography
          variant={propertyStates.every((v) => v === false) ? 'h3' : 'h2'}
        >
          Properties
        </Typography>
        <Box display="flex" className="mt-5">
          {props.propertyStates.map((property, index) =>
            property ? (
              <Typography
                key={properties[tokenType][index].label}
                fontWeight={600}
                sx={{ fontSize: '14px', color: '#adadd0' }}
              >
                {properties[tokenType][index].label}

                {index !== lastIndex ? ', ' : null}
              </Typography>
            ) : null,
          )}
        </Box>
      </>
    </GradientBox>
  );
};

type L1SectionProps = BoxProps & {
  l1States: boolean[];
  tokenType: 'c1' | 'c2' | 'c3';
  handleL1Tokens: (e: boolean, index: number) => void;
};
export const L1Section = (props: L1SectionProps) => (
  <GradientBox>
    <>
      <Typography variant="h2">
        L1 Token Types <InfoIcon title={L1TokensInfotext} />
      </Typography>
      <FormControl sx={{ width: '100%', display: '-webkit-inline-box' }}>
        {L1Tokens &&
          L1Tokens[props.tokenType]?.map((tokens, index) => (
            <FormControlLabel
              sx={{ marginRight: '50px' }}
              value={tokens.value}
              control={
                <Checkbox
                  checked={props.l1States[index]}
                  checkedIcon={<BpCheckedIconCheck />}
                  icon={<BpIconCheck />}
                  onChange={(e) =>
                    props.handleL1Tokens(e.target.checked, index)
                  }
                />
              }
              label={
                <Typography fontSize={14} fontWeight={500}>
                  {tokens.label}
                </Typography>
              }
              key={tokens.label}
            />
          ))}
      </FormControl>
    </>
  </GradientBox>
);

type PreviewL1SectionProps = BoxProps & {
  tokenType: 'c1' | 'c2' | 'c3';
  l1States: boolean[];
};
export const PreviewL1Section = (props: PreviewL1SectionProps) => {
  const { l1States, tokenType } = props;
  const lastIndex = findLastIndex(props.l1States);

  return (
    <GradientBox>
      <>
        <Typography variant={l1States.every((v) => v === false) ? 'h3' : 'h2'}>
          L1 Token Types
        </Typography>
        <Box display="flex" className="mt-5">
          {props.l1States.map((l1, index) =>
            l1 ? (
              <Typography
                key={L1Tokens[tokenType][index].label}
                fontWeight={600}
                sx={{ fontSize: '14px', color: '#adadd0' }}
              >
                {L1Tokens[tokenType][index].label}

                {index !== lastIndex ? ', ' : null}
              </Typography>
            ) : null,
          )}
        </Box>
      </>
    </GradientBox>
  );
};

type TokenFeePaymentSectionProps = BoxProps & {
  fee: number | undefined;
  onFeesChange: (value: number) => void;
};
export const TokenFeePaymentSection = (props: TokenFeePaymentSectionProps) => (
  <GradientBox>
    <>
      <Typography variant="h2">
        Token Fee Payment <InfoIcon title={tokenFeePaymentInfoText} />
      </Typography>
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup
          sx={{
            flexDirection: 'row !important',
            width: 'auto',
          }}
          value={props.fee}
          onChange={(e: any) => props.onFeesChange(e.target.value)}
        >
          {tokenFees &&
            tokenFees.map((fees) => (
              <FormControlLabel
                value={fees.value}
                control={
                  <Radio
                    icon={<BpIconRadio />}
                    checkedIcon={<BpCheckedIconRadio />}
                  />
                }
                label={fees.label}
                key={fees.value}
              />
            ))}
        </RadioGroup>
      </FormControl>
    </>
  </GradientBox>
);

type PreviewTokenFeePaymentSectionProps = BoxProps & {
  fee: number | undefined;
};
export const PreviewTokenFeePaymentSection = (
  props: PreviewTokenFeePaymentSectionProps,
) => (
  <GradientBox>
    <>
      <Typography variant={props.fee === undefined ? 'h3' : 'h2'}>
        Token Fee Payment
      </Typography>
      {props.fee !== undefined ? (
        <Typography
          fontWeight={600}
          sx={{ fontSize: '14px', color: '#adadd0', marginTop: '20px' }}
        >
          {tokenFees[props.fee]?.label}
        </Typography>
      ) : null}
    </>
  </GradientBox>
);

type ShardSectionProps = BoxProps & {
  shard: string;
  onShardChange: (value: string) => void;
};
export const ShardSection = (props: ShardSectionProps) => (
  <GradientBox>
    <FormControl sx={{ width: '100%' }}>
      <InputLabel className={styles.selectLabel}>Select Shard</InputLabel>
      <SelectTokenization
        value={props.shard || ''}
        label="Select Shard"
        onChange={(e: SelectChangeEvent<unknown>) =>
          props.onShardChange(
            typeof e.target.value === 'string' ? e.target.value : '',
          )
        }
      >
        {shards &&
          shards.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
      </SelectTokenization>
    </FormControl>
  </GradientBox>
);

type TokenImageProps = BoxProps & {
  children: React.ReactNode | React.ReactNode[];
};
export const TokenImage = (props: TokenImageProps) => (
  <GradientBox>{props.children}</GradientBox>
);
type TokenDetailsProps = BoxProps & {
  children: React.ReactNode | React.ReactNode[];
};
export const TokenDetails = (props: TokenDetailsProps) => (
  <GradientBox>{props.children}</GradientBox>
);
type PreviewDetailsProps = BoxProps & {
  children: React.ReactNode;
};
export const PreviewDetails = (props: PreviewDetailsProps) => (
  <GradientBox>{props.children}</GradientBox>
);

type GridContainerBoxProps = BoxProps & {
  children: React.ReactNode;
};
export const GridContainerBox: React.FC<GridContainerBoxProps> = (
  props: GridContainerBoxProps,
) => {
  const { width } = useWindowDimensions();
  return (
    <Box
      className={
        width < 700
          ? 'w-full grid grid-rows-2 gap-5'
          : 'w-full grid grid-cols-2 gap-5'
      }
    >
      {props.children}
    </Box>
  );
};
type PreviewInputBoxProps = BoxProps & {
  label: string;
  value: string | number | null | JSX.Element;
};
export const PreviewInputBox = (props: PreviewInputBoxProps) => (
  <SpaceBetweenBox sx={{ marginTop: '10px' }}>
    <Typography
      fontWeight={600}
      sx={{ fontSize: '14px', color: '#adadd0', width: '150px' }}
    >
      {props.label}:
    </Typography>
    <Typography
      fontWeight={600}
      sx={{ marginLeft: '20px', fontSize: '14px', color: '#adadd0' }}
    >
      {props.value}
    </Typography>
  </SpaceBetweenBox>
);
export const PreviewDescriptionBox = (props: PreviewInputBoxProps) => (
  <Box sx={{ marginTop: '10px' }}>
    <Typography fontWeight={600} sx={{ fontSize: '14px', color: '#adadd0' }}>
      {props.label}:
    </Typography>
    <Typography
      fontWeight={600}
      sx={{ fontSize: '14px', color: '#adadd0', overflowWrap: 'anywhere' }}
    >
      {props.value}
    </Typography>
  </Box>
);
type ImagePreviewBoxProps = RadioGroupProps & {
  baseImg: string | null | undefined;
};
export const ImagePreviewBox = (props: ImagePreviewBoxProps) =>
  props.baseImg === undefined ? (
    <Box className={styles.sphere}>
      <Box className={styles.innerSphere}>
        <AddIcon className={styles.imageIcon} />
      </Box>
    </Box>
  ) : (
    <>
      {props.baseImg && (
        <Box className={styles.sphere} key={props.baseImg}>
          <Box className={styles.innerSphere}>
            <img
              className={styles.innerSphere}
              src={props.baseImg}
              alt="token_image"
            />
          </Box>
        </Box>
      )}
      {/* {props.icon && (
          <Box className={styles.sphere}>
            <Box className={styles.innerSphere}>
              <img
                className={styles.innerSphere}
                src={props.icon}
                alt="token_image"
              />
            </Box>
          </Box>
        )} */}
    </>
  );

type ImageUploadSectionProps = RadioGroupProps & {
  uploadType: 0 | 1;
  onUploadTypeChange: (value: 0 | 1) => void;
};
export const ImageUploadSection = (props: ImageUploadSectionProps) => (
  <RadioGroup
    className="mt-5"
    row
    value={props.uploadType}
    onChange={(e: any) => props.onUploadTypeChange(e.target.value)}
  >
    {uploadTypes &&
      uploadTypes.map((upload) => (
        <FormControlLabel
          value={upload.value}
          control={
            <Radio
              icon={<BpIconRadio />}
              checkedIcon={<BpCheckedIconRadio />}
            />
          }
          label={upload.label}
          key={upload.value}
        />
      ))}
  </RadioGroup>
);

type SubtokenSectionProps = BoxProps & {
  subtoken: 0 | 1;
  onSubtokenChange: (value: 0 | 1) => void;
};
export const SubtokenSection = (props: SubtokenSectionProps) => (
  <GradientBox>
    <>
      <Typography variant="h2">
        Subtoken Type <InfoIcon title={tokenSubTokenInfoText} />
      </Typography>
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup
          sx={{
            flexDirection: 'row !important',
            width: 'auto',
          }}
          value={props.subtoken}
          onChange={(e: any) => props.onSubtokenChange(e.target.value)}
        >
          {subtokenTypes &&
            subtokenTypes.map((fees) => (
              <FormControlLabel
                value={fees.value}
                control={
                  <Radio
                    icon={<BpIconRadio />}
                    checkedIcon={<BpCheckedIconRadio />}
                  />
                }
                label={fees.label}
                key={fees.value}
              />
            ))}
        </RadioGroup>
      </FormControl>
    </>
  </GradientBox>
);
type CreationSectionProps = BoxProps & {
  tokenName: string;
  verb: string;
  data: string;
  command?: TokenUiCommand;
  onClose: (value: boolean) => void;
  button: boolean;
  size: number | undefined;
  operationType: OperationType | undefined;
};
export const CreationSection = (props: CreationSectionProps) => {
  const { tokenName, verb, data, command, onClose, button, size } = props;

  return (
    <>
      <GradientBox
        sx={{
          width: 'fit-content',
          height: 'auto',
          margin:
            size && size > 100 ? '0px auto 20px auto' : '0px auto 10px auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '4px',
          padding: size && size > 100 ? '30px' : '10px',
          position: 'relative',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '0px', right: '0px' }}
          onClick={() => {
            onClose(true);
          }}
        >
          <CloseIcon />
        </IconButton>
        {size && (
          <QRCode
            size={size}
            style={{
              marginTop: '10px',
            }}
            bgColor="transparent"
            fgColor="#fff"
            value={data}
          />
        )}
      </GradientBox>
      {button && command && <PayButton command={command} />}
      <Typography
        variant="h4"
        sx={{ maxWidth: '340px', margin: 'auto', textAlign: 'center' }}
      >
        {`${tokenCreationInfotext} ${verb} ${tokenName}`}{' '}
      </Typography>
      <SuccessNotificationModal
        operationType={props.operationType}
        onClose={onClose}
      />
    </>
  );
};
type LoaderSectionProps = BoxProps & {
  tokenName: string;
  verb: string;
  onClose: (value: boolean) => void;
};
export const LoaderSection = (props: LoaderSectionProps) => {
  const { height, width } = useWindowDimensions();
  return (
    <>
      <GradientBox
        sx={{
          width: 'fit-content',
          height: 'auto',
          margin: '80px auto 20px auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '4px',
          padding: '30px',
          position: 'relative',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '0px', right: '0px' }}
          onClick={() => {
            props.onClose(true);
          }}
        >
          <CloseIcon />
        </IconButton>
        <CircularProgress
          sx={{ color: '#fff', marginTop: '28px' }}
          size={height < width ? height - 160 : width - 240}
        />
      </GradientBox>
      <Typography variant="h4" sx={{ maxWidth: '340px', margin: 'auto' }}>
        {`${tokenCreationInfotext} ${props.verb} ${props.tokenName}`}{' '}
      </Typography>
    </>
  );
};
type BondingCurveSectionProps = BoxProps & {
  params: BondingCurveStateType;
  yTokenName: string;
  pubkey: string | undefined;
};
export const BondingCurveSection = (props: BondingCurveSectionProps) => {
  const navigate = useNavigate();

  return (
    <GradientBox>
      <SpaceBetweenBox sx={{ marginBottom: '30px' }}>
        <Typography variant={props.params.quadratic ? 'h2' : 'h3'}>
          Bonding Curve
        </Typography>
        <>
          <Typography variant="h4" fontWeight={600}>
            Supply:{' '}
            {props.params.supply
              ? round(props.params.supply, 5).toFixed()
              : undefined}
          </Typography>
          <Typography variant="h4" fontWeight={600}>
            Profit:{' '}
            {props.params.profit
              ? round(props.params.profit, 5).toFixed()
              : undefined}
          </Typography>
        </>
      </SpaceBetweenBox>
      {props.params.quadratic ? (
        <Box display="flex" flexDirection="column">
          <AmountChart
            value={props.params.quadratic}
            yTokenName={props.yTokenName}
          />
        </Box>
      ) : undefined}
      <CenteredBox width="100%" className="mt-5 flex">
        <BlueButton
          variant="outlined"
          onClick={() => {
            navigate(`/${props.pubkey}/${routePaths.TOKENIZATION.LIQUIDITY}`, {
              state: {
                edit: true,
              },
            });
          }}
        >
          Edit Bonding Curve
        </BlueButton>
      </CenteredBox>
    </GradientBox>
  );
};
