import { Token } from 'types/Token';
import createGlobalState from './factory/createGlobalState';

// TODO: This could also be encoded in the url.
// We define null as explicitly unset, while undefined is not initialized.
// Thus other components can set this to the first issued token if they find
// undefined, but should leave it alone if it is set to null.
//
// Also we only store ProtocolFields as they are immutable, thus we do not
// need to deal with syncing any information with changes the user performs
// on the token.
// TODO: Do we need caching of each token for ever?
export const useCurrentToken = createGlobalState<Token | null | undefined>(
  undefined,
);
