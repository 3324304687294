export function findLastIndex(array: boolean[]) {
  const index = array
    .slice()
    .reverse()
    .findIndex((x) => x === true);

  const count = array.length - 1;
  const finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
}
