import { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { string } from 'prop-types';
import cx from 'classnames';

import styles from './Sidebar.module.scss';

export const Link = forwardRef((props, ref) => {
  return (
    <NavLink
      end
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={({ isActive }) =>
        cx(props.className, styles.listItem, {
          [styles.activeListItem]: isActive,
        })
      }
    />
  );
});

Link.displayName = 'Link';

Link.propTypes = {
  className: string,
};
