import { take } from 'lodash-es';
import { User } from '@coinweb/cweb-wallet-library';
import { useWalletFromParams } from 'hooks/useWalletFromParams';
import { useCurrentToken } from 'hooks/useCurrentToken';
import {
  useTokenQueryTopHolders,
  useTokenQueryTotalSupply,
} from 'hooks/useTokenizationBlock';
import { TokenFields } from 'enums';
import { Row } from './types';

const MAX_HOLDERS_COUNT = 10;

export const useRows = (): Row[] => {
  const [wallet] = useWalletFromParams();
  const [token] = useCurrentToken();

  const decimalPlaces: number = (() => {
    const field = token?.extraField(TokenFields.EXPONENT);
    return field ? Number(field) : 18;
  })();

  const queries: string[] = token ? [token.hashId] : [];

  const holders: [User, BigInt][] = take(
    useTokenQueryTopHolders(wallet, queries) ?? [],
    MAX_HOLDERS_COUNT,
  );

  // TODO: The return type for amounts seems to be string, but typed as bigint
  // TODO: I could not get successful results from thie hook, so this might
  // need more testing.
  const [totalSupplyStr] =
    useTokenQueryTotalSupply(wallet, queries)?.flat() ?? [];

  const totalSupply = totalSupplyStr
    ? BigInt(totalSupplyStr as unknown as string)
    : undefined;

  // TODO: The return type for amounts seems to be string, but typed as bigint
  const rows: Row[] = (holders ?? []).map(([{ payload }, amount_], idx) => {
    let percentage: number;

    const amount = amount_ as unknown as string;

    if (totalSupply && totalSupply > 0) {
      percentage =
        Number(
          (((BigInt(amount) as bigint) * BigInt(10000)) /
            (totalSupply as bigint)) as bigint,
        ) / 100.0;
    } else {
      percentage = 0;
    }

    const quantity: number = Number(
      BigInt(amount) / BigInt(10 ** decimalPlaces),
    );

    return {
      rank: (idx + 1).toString(),
      address: payload.toString(),
      quantity: quantity.toString(),
      percentage,
    };
  });

  return rows;
};
