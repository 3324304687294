import {
  create_wallet as createWallet,
  // enable_logging as enableLogging,
  Wallet,
  wasmLoadingPromise,
} from '@coinweb/cweb-wallet-library';
import { REACT_APP_API_ENDPOINT } from 'conf';

import createGlobalState from './factory/createGlobalState';
import useEffectOnce from './useEffectOnce';

const walletWithKey = async (pubKey: string): Promise<Wallet> => {
  const url = new URL('wallet', REACT_APP_API_ENDPOINT);
  const address = url.href;
  const wsAddress = address.replace(/^https:/, 'wss:');

  if (wasmLoadingPromise !== undefined) {
    await wasmLoadingPromise;
  }

  // enableLogging({ gql: true }); // For debugging

  return createWallet({
    address,
    ws_address: wsAddress,
    pub_key: pubKey,
    shard: null,
    sign_callback: (_msg: Uint8Array) => {
      throw new Error('Unimplemented');
    },
    max_retry_time_secs: null,
    enable_retries: null,
  });
};

type WalletWrapper = {
  wallet?: Wallet;
  pubKey: string;
};

const useWalletValue = createGlobalState<WalletWrapper>({ pubKey: '' });

type UseWallet = (pubKey: string) => Wallet | undefined;

function freeWallet(wallet?: Wallet) {
  if (wallet !== undefined) {
    // eslint-disable-next-line no-console
    // console.log('Free wallet:', wallet);

    wallet.free();
  }
}

// Get a reference to the global wallet, and ensure that it is
// using `pubKey` as public key.
//
// If the current wallet is using a different pubkey, a new wallet
// will be created, and the old destroyed.
//
// The return value is Wallet | undefined because creating a wallet
// is an async operation, so during creation, the wallet will not
// exist. Similarly, during re-creation, the wallet will be undefined
// until the new one is fully created.

export const useWallet: UseWallet = (pubKey: string) => {
  const [walletWrapper, setWalletWrapper] = useWalletValue();

  // Create a new wallet (async) and switch to it if we still want
  // the pubKey.
  const tryAttachNewWallet = async () => {
    // eslint-disable-next-line no-console
    const wallet = await walletWithKey(pubKey);
    // Re-check that we still want the new pubKey in case multiple
    // wallets with different pubKeys are being created at the same
    // time.  This is just to ensure we don't have a leak.
    setWalletWrapper((oldWrapper) => {
      if (oldWrapper?.pubKey !== pubKey) {
        freeWallet(wallet);
        return oldWrapper;
      }
      // eslint-disable-next-line no-console
      // console.log('Created new wallet for pub key:', pubKey, wallet);

      return { pubKey, wallet };
    });
  };

  useEffectOnce(() => {
    setWalletWrapper((oldWrapper) => {
      // Check inside a setter which acts as a critical section so only
      // a single wallet switch happens.
      if (oldWrapper?.pubKey !== pubKey) {
        freeWallet(oldWrapper?.wallet);
        tryAttachNewWallet();
        return { pubKey };
      }
      return oldWrapper;
    });
  });

  return walletWrapper?.wallet;
};
