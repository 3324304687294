import createHexString from 'utils/createHexString';
import parseHexString from 'utils/parseHexString';

const PUBKEY_HEX_LEN = 66;
const PUBKEY_BYTE_LEN = 33;

export default function checkPubkey(data: string) {
  if (data.length === PUBKEY_HEX_LEN) {
    const byteRes = parseHexString(data);

    if (byteRes.length === PUBKEY_BYTE_LEN) {
      const HexRes = createHexString(byteRes);
      if (data.toLowerCase() === HexRes) return true;
    }
  }
  return false;
}
