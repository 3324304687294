import Typography from '@mui/material/Typography';
import { priceTypes } from '../liquidityTypes';
import {
  GradientBox,
  SpaceBetweenBox,
} from '../../../components/Containers/Box';

type Props = {
  value: priceTypes;
};

export const PreviewPriceTypeSection = (props: Props) => {
  return (
    <GradientBox>
      <SpaceBetweenBox>
        <Typography variant="h2">Curve Type</Typography>
        <Typography variant="h4" fontWeight={600}>
          {props.value}
        </Typography>
      </SpaceBetweenBox>
    </GradientBox>
  );
};
