import { useEffect, useState } from 'react';
import { useCurrentToken } from 'hooks/useCurrentToken';
import { Box, TextField, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import { useSelector, useDispatch } from 'react-redux';
import { REACT_APP_MODE } from 'conf';
import commonStyles from 'styles/commonStyles.module.scss';
import DefaultIconLogo from 'images/defaultTokenIcon.svg';
import { usePrevious } from 'hooks/usePrevious';
import { CopyButton } from 'components/CopyButton';
import Tooltip from '@mui/material/Tooltip';
import { fieldTokenNotifications } from 'redux/reducers/tokenReducer';
import { notificationHistoryInfoText } from 'texts';
import IosShareIcon from '@mui/icons-material/IosShare';
import { BlueButton } from 'modules/tokenization/components/ControlButtons/Button';
import { NotificationType } from 'utils/notifications';
import { displayHash } from 'utils/displayHash';
import {
  PreviewWrapper,
  GradientBox,
  SpaceBetweenBox,
} from '../components/Containers/Box';
import imgStyles from './BasicIssuance/BasicIssuance.module.scss';
import { InfoIcon } from '../components/Icons/Icons';

export const NotificationsPage = () => {
  const [token] = useCurrentToken();
  const [description, setDescription] = useState<string>('');
  const dispatch = useDispatch();

  const { tokenName, tokenSymbol, fungibleCurrentImg, tokenNotifications } =
    useSelector((state: any) => state.token);

  const previousNotifications = usePrevious(tokenNotifications);

  useEffect(() => {
    if (!token) {
      dispatch(fieldTokenNotifications([]));
      return;
    }
    /* check to prevent infinite update */
    if (tokenNotifications?.length === previousNotifications?.length) {
      return;
    }
    if (
      tokenNotifications &&
      previousNotifications &&
      tokenNotifications.length !== previousNotifications.length
    ) {
      const readNotifications: NotificationType[] = tokenNotifications?.map(
        (item: NotificationType) => ({
          ...item,
          read: true,
        }),
      )!;
      dispatch(fieldTokenNotifications(readNotifications));
    }
    // setNotifications(readNotifications);
  }, [previousNotifications, tokenNotifications, dispatch, token]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minHeight="100vh"
      padding="0px 20px"
    >
      <PreviewWrapper>
        <img
          className={imgStyles.previewImg}
          src={
            token && fungibleCurrentImg ? fungibleCurrentImg : DefaultIconLogo
          }
          alt="token_image"
          width={100}
        />
        <Box display="flex">
          <Typography
            fontWeight={600}
            sx={{
              width: 'fit-content',
              fontSize: '18px',
              margin: '20px auto 0px auto',
            }}
            align="center"
          >
            {tokenName && tokenSymbol && token
              ? `${tokenName} - ${tokenSymbol}`
              : 'No token '}
          </Typography>
        </Box>
        <GradientBox>
          <SpaceBetweenBox>
            <Typography variant="h2">
              Notifications History{' '}
              <InfoIcon title={notificationHistoryInfoText} />
            </Typography>
            {REACT_APP_MODE === 'retail' ? null : (
              <Typography>1098 Holders</Typography>
            )}
          </SpaceBetweenBox>
          <Box mt="20px" pb={2} sx={{ maxHeight: '250px', overflow: 'scroll' }}>
            {tokenNotifications?.map((item: NotificationType) => {
              return (
                <SpaceBetweenBox
                  key={item.id}
                  sx={{
                    display: 'flex',
                    pt: 1,
                  }}
                >
                  <Tooltip title={item.message2 ? item.message2 : ''}>
                    <Typography
                      className={commonStyles.mutedText}
                      display="inline-block"
                      fontWeight={500}
                      width="90%"
                      variant="body2"
                      style={{ wordBreak: 'break-word' }}
                    >
                      {item.message}
                    </Typography>
                  </Tooltip>
                  <div style={{ display: 'flex' }}>
                    <Tooltip title={item.id}>
                      <Typography
                        display="inline-block"
                        fontWeight={500}
                        width="90%"
                      >
                        {displayHash(item.id)}
                      </Typography>
                    </Tooltip>
                    <CopyButton
                      sx={{ padding: '0px 0px 0px 5px' }}
                      text={item.id}
                      hint="Copy TxID"
                    />

                    <a
                      href={`https://explorer.coinweb.io/?hash=${item.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IosShareIcon />
                    </a>
                  </div>
                  <NotificationsIcon className={commonStyles.mutedText} />
                </SpaceBetweenBox>
              );
            })}
          </Box>
        </GradientBox>
        {REACT_APP_MODE === 'pro' ? (
          <>
            <GradientBox>
              <TextField
                disabled
                label="Description"
                placeholder="1"
                variant="outlined"
                multiline
                minRows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{
                  width: '100%',
                  color: '#fff !important',
                  marginTop: '10px',
                  WebkitTextFillColor: 'none !important',
                  '.MuiInputBase-root': {
                    color: '#adadd0 !important',
                    maxHeight: '217px',
                  },
                  '.Mui-focused': {
                    borderColor: '#e4e3ed !important',
                  },
                  '.MuiInputLabel-root': {
                    color: '#adadd0 !important',
                    marginTop: '-5px',
                  },
                  '.Mui-disabled': {
                    color: '#ffffff !important',
                    WebkitTextFillColor: '#fff  !important',
                  },
                }}
              />
            </GradientBox>
            <Box width="100% " display="flex" mt="20px">
              <BlueButton disabled variant="outlined" sx={{ margin: 'auto' }}>
                Notify Subscribers
              </BlueButton>
            </Box>
          </>
        ) : null}
      </PreviewWrapper>
    </Box>
  );
};
