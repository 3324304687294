import { merge } from 'lodash-es';
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
);

export const defaultOptions: ChartOptions<'line'> = {
  color: '#bebeff',
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  scales: {
    x: {
      // beginAtZero: true,
      title: {
        color: 'rgba(255, 255, 255, 0.7)',
        display: true,
      },
      ticks: {
        color: 'rgba(255, 255, 255, 0.7)',
      },
      grid: {
        color: ['rgba(190,190,255, 0.1)'],
        // tickColor: '#bebeff',
      },
    },
    y: {
      // beginAtZero: true,
      title: {
        color: 'rgba(255, 255, 255, 0.7)',
        display: true,
      },
      ticks: {
        color: 'rgba(255, 255, 255, 0.7)',
      },
      grid: {
        color: 'rgba(190,190,255, 0.1)',
        // tickColor: '#bebeff',
      },
    },
  },

  plugins: {
    legend: {
      position: 'top' as const,
    },
    zoom: {
      zoom: {
        mode: 'x',
        drag: {
          enabled: false,
        },
        wheel: {
          enabled: true,
        },
      },
      pan: {
        enabled: true,
        mode: 'xy',
      },
    },
  },
};

type Props = {
  xAxisTitle?: string;
  yAxisTitle?: string;
  tooltipTitle?: string;
  tooltipLabelSuffix?: string;
  data: ChartData<'line'>;
  options?: ChartOptions<'line'>;
};

const formatTooltipValue = (tooltipItem: TooltipItem<any>) => {
  const fragments = tooltipItem.parsed.y.toString().split('.');

  const integerPart = fragments[0]
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const decimalPart = fragments[1];

  return [integerPart, decimalPart].filter(Boolean).join('.');
};

const getOptions = ({
  xAxisTitle,
  yAxisTitle,
  tooltipTitle,
  tooltipLabelSuffix,
  options,
}: Partial<Props>) => {
  return merge(
    {},
    defaultOptions,
    options,
    {
      scales: {
        x: {
          title: {
            text: xAxisTitle,
          },
        },
        y: {
          title: {
            text: yAxisTitle,
          },
        },
      },
    },
    tooltipTitle || tooltipLabelSuffix
      ? ({
          plugins: {
            tooltip: {
              mode: 'index',
              callbacks: {
                ...(tooltipTitle
                  ? {
                      title(tooltipItems: TooltipItem<any>[]) {
                        const tooltipItem = tooltipItems[0];
                        const formattedValue = formatTooltipValue(tooltipItem);

                        return `${formattedValue} ${tooltipTitle}`;
                      },
                    }
                  : {}),
                label(tooltipItem: TooltipItem<any>): string {
                  const formattedValue = formatTooltipValue(tooltipItem);

                  return `${tooltipItem.dataset.label}: ${formattedValue}`;
                },
                ...(tooltipLabelSuffix
                  ? {
                      afterLabel() {
                        return tooltipLabelSuffix;
                      },
                    }
                  : {}),
              },
            },
          },
        } as ChartOptions<'line'>)
      : {},
  );
};

export const LineChart = (props: Props) => {
  return (
    <Line
      data={props.data}
      options={getOptions({
        xAxisTitle: props.xAxisTitle,
        yAxisTitle: props.yAxisTitle,
        tooltipLabelSuffix: props.tooltipLabelSuffix,
        tooltipTitle: props.tooltipTitle,
        options: props.options,
      })}
    />
  );
};

export default LineChart;
