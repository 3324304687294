export const revealAddress = (addressData, txData) => {
  const dict = {};
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < addressData.length; i++) {
    /* eslint-disable-next-line no-plusplus */
    for (let j = 0; j < txData.length; j++) {
      if (addressData[i].txId === txData[j].interactWith)
        dict[txData[j].interactWith] = addressData[i].name;
    }
  }
  return dict;
};
