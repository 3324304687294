import React from 'react';
import { generatePath } from 'react-router-dom';
import IssuanceIcon from 'images/icons/sidebar/icon-basicissuance.svg';
import LiquidityIcon from 'images/icons/sidebar/icon-liquidity.svg';
import AnalyticsIcon from 'images/icons/sidebar/icon-analytics.svg';
import TransfersIcon from 'images/icons/sidebar/icon-transfers.svg';
import BridgesIcon from 'images/icons/sidebar/icon-bridges.svg';
import ExchangeIcon from 'images/icons/sidebar/icon-exchange.svg';
import StakingIcon from 'images/icons/sidebar/icon-staking.svg';
import BatchSendIcon from 'images/icons/sidebar/icon-batchsend.svg';
import NotificationsIcon from 'images/icons/sidebar/icon-sendnotifications.svg';
import ComplianceIcon from 'images/icons/sidebar/icon-compliance.svg';
import ActiveIssuanceIcon from 'images/icons/sidebar/icon-basicissuance-active.svg';
import ActiveLiquidityIcon from 'images/icons/sidebar/icon-liquidity-active.svg';
import ActiveAnalyticsIcon from 'images/icons/sidebar/icon-analytics-active.svg';
import ActiveTransfersIcon from 'images/icons/sidebar/icon-transfers-active.svg';
import ActiveBridgesIcon from 'images/icons/sidebar/icon-bridges-active.svg';
import ActiveExchangeIcon from 'images/icons/sidebar/icon-exchange-active.svg';
import ActiveStakingIcon from 'images/icons/sidebar/icon-staking-active.svg';
import ActiveBatchSendIcon from 'images/icons/sidebar/icon-batchsend-active.svg';
import ActiveNotificationsIcon from 'images/icons/sidebar/icon-sendnotifications-active.svg';
import ActiveComplianceIcon from 'images/icons/sidebar/icon-compliance-active.svg';
import AdminIcon from 'images/icons/sidebar/icon-admin.svg';
import LogoutIcon from 'images/icons/sidebar/icon-logout.svg';
import { SidebarItemType, Sidebar } from 'components/Sidebar';
import { routePaths } from 'routePaths';
import { useCurrentToken } from 'hooks/useCurrentToken';
import { REACT_APP_MODE } from 'conf';

export const ITEMS: SidebarItemType[] = [
  {
    text: 'Issuance',
    url: generatePath(routePaths.TOKENIZATION.BASIC_ISSUANCE.ROOT),
    icon: <img src={IssuanceIcon} alt="issuance_icon" />,
    activeIcon: <img src={ActiveIssuanceIcon} alt="issuance_icon" />,
    items: [
      {
        text: 'Fungible Token',
        url: routePaths.TOKENIZATION.BASIC_ISSUANCE.FUNGIBLE,
      },
      {
        text: 'Nonfungible Token',
        url: routePaths.TOKENIZATION.BASIC_ISSUANCE.NONFUNGIBLE,
      },
      {
        text: 'Multi Token',
        url: routePaths.TOKENIZATION.BASIC_ISSUANCE.MULTI,
      },
    ],
  },
  {
    text: 'Liquidity',
    url: routePaths.TOKENIZATION.LIQUIDITY,
    icon: <img src={LiquidityIcon} alt="liquidity_icon" />,
    activeIcon: <img src={ActiveLiquidityIcon} alt="liquidity_icon" />,
  },
  {
    text: 'Analytics',
    url: routePaths.TOKENIZATION.ANALYTICS,
    icon: <img src={AnalyticsIcon} alt="issuance_icon" />,
    activeIcon: <img src={ActiveAnalyticsIcon} alt="issuance_icon" />,
  },
  {
    text: 'Transfers',
    url: generatePath(routePaths.TOKENIZATION.TRANSFERS_ROOT),
    icon: <img src={TransfersIcon} alt="issuance_icon" />,

    activeIcon: <img src={ActiveTransfersIcon} alt="issuance_icon" />,
    items: [
      {
        text: 'Fees',
        url: routePaths.TOKENIZATION.FEES,
      },
      {
        text: 'Blacklist',
        url: routePaths.TOKENIZATION.BLACKLIST,
      },
      {
        text: 'Whitelist',
        url: routePaths.TOKENIZATION.WHITELIST,
      },
    ],
  },
  {
    text: 'Bridges',
    url: generatePath(routePaths.TOKENIZATION.BRIDGES.ROOT),
    icon: <img src={BridgesIcon} alt="bridge_icon" />,

    activeIcon: <img src={ActiveBridgesIcon} alt="bridge_icon" />,
    items: [
      {
        text: 'Common Settings',
        url: routePaths.TOKENIZATION.BRIDGES.COMMON_SETTINGS,
      },
      {
        disabled: true,
        text: 'Elrond Mainnet',
        url: routePaths.TOKENIZATION.BRIDGES.ELROND,
      },
      {
        text: 'EVM-based Bridges',
        url: routePaths.TOKENIZATION.BRIDGES.EVM,
        items: [
          {
            text: 'Ethereum Mainnet',
            url: routePaths.TOKENIZATION.BRIDGES.ETHEREUM,
          },
          {
            text: 'BNB Chain Mainnet',
            url: routePaths.TOKENIZATION.BRIDGES.BNB,
          },
          {
            text: 'Polygon Mainnet',
            url: routePaths.TOKENIZATION.BRIDGES.POLYGON,
          },
        ],
      },
    ],
  },
  {
    text: 'Exchange',
    url: routePaths.TOKENIZATION.EXCHANGE,
    icon: <img src={ExchangeIcon} alt="exchange_icon" />,
    activeIcon: <img src={ActiveExchangeIcon} alt="exchange_icon" />,
  },
  {
    text: 'Staking',
    url: routePaths.TOKENIZATION.STAKING,
    icon: <img src={StakingIcon} alt="staking_icon" />,
    activeIcon: <img src={ActiveStakingIcon} alt="staking_icon" />,
  },
  {
    text: 'Batch Send',
    url: routePaths.TOKENIZATION.BATCH_SEND,
    icon: <img src={BatchSendIcon} alt="issuance_icon" />,
    activeIcon: <img src={ActiveBatchSendIcon} alt="issuance_icon" />,
  },
  {
    text: 'Notifications',
    url: routePaths.TOKENIZATION.NOTIFICATIONS,
    icon: <img src={NotificationsIcon} alt="issuance_icon" />,
    activeIcon: <img src={ActiveNotificationsIcon} alt="issuance_icon" />,
  },
  {
    text: 'Compliance',
    url: routePaths.TOKENIZATION.COMPLIANCE,
    icon: <img src={ComplianceIcon} alt="issuance_icon" />,
    activeIcon: <img src={ActiveComplianceIcon} alt="issuance_icon" />,
  },
];

const FOOTER_ITEMS = [
  {
    text: 'Admin',
    url: routePaths.ADMIN,
    icon: <img src={AdminIcon} alt="issuance_icon" />,
    activeIcon: <img src={AdminIcon} alt="issuance_icon" />,
  },
  {
    text: 'Logout',
    url: routePaths.LOGOUT,
    onClick: (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault();
      localStorage.removeItem('privKey');
      window.location.replace(routePaths.TOKENIZATION.LOGIN);
    },
    icon: <img src={LogoutIcon} alt="issuance_icon" />,
    activeIcon: <img src={LogoutIcon} alt="issuance_icon" />,
  },
];

export const DISPLAY_ITEMS: SidebarItemType[] = [
  {
    text: 'Issuance',
    url: generatePath(routePaths.TOKENIZATION.BASIC_ISSUANCE.ROOT),
    icon: <img src={IssuanceIcon} alt="issuance_icon" />,
    activeIcon: <img src={ActiveIssuanceIcon} alt="issuance_icon" />,
    items: [
      {
        text: 'Fungible Token',
        url: routePaths.TOKENIZATION.BASIC_ISSUANCE.FUNGIBLE,
      },
    ],
  },
  {
    text: 'Liquidity',
    url: routePaths.TOKENIZATION.LIQUIDITY,
    icon: <img src={LiquidityIcon} alt="liquidity_icon" />,
    activeIcon: <img src={ActiveLiquidityIcon} alt="liquidity_icon" />,
  },
  {
    text: 'Analytics',
    url: routePaths.TOKENIZATION.ANALYTICS,
    icon: <img src={AnalyticsIcon} alt="issuance_icon" />,
    activeIcon: <img src={ActiveAnalyticsIcon} alt="issuance_icon" />,
  },
];

const DISPLAY_FOOTER_ITEMS = [
  {
    text: 'Logout',
    url: routePaths.LOGOUT,
    onClick: (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault();
      localStorage.removeItem('privKey');
      window.location.replace(routePaths.TOKENIZATION.LOGIN);
    },
    icon: <img src={LogoutIcon} alt="issuance_icon" />,
    activeIcon: <img src={LogoutIcon} alt="issuance_icon" />,
  },
];

const disableItems = (
  sidebarItems: SidebarItemType[],
  exclude: string[] = [],
): SidebarItemType[] => {
  return sidebarItems.map((item) => {
    return exclude.includes(item.url)
      ? item
      : {
          ...item,
          ...(item.items ? { items: disableItems(item.items) } : {}),
          disabled: true,
        };
  });
};

const handleItems = (currentToken: any): SidebarItemType[] => {
  if (REACT_APP_MODE === 'retail') {
    if (currentToken) return DISPLAY_ITEMS;

    return disableItems(DISPLAY_ITEMS, [
      generatePath(routePaths.TOKENIZATION.BASIC_ISSUANCE.ROOT),
    ]);
  }
  if ((currentToken && REACT_APP_MODE === 'pro') || REACT_APP_MODE === 'devnet')
    return ITEMS;

  return disableItems(ITEMS, [
    generatePath(routePaths.TOKENIZATION.BASIC_ISSUANCE.ROOT),
  ]);
};

const handleFooterItems = (
  currentToken: any,
): SidebarItemType[] | undefined => {
  if (REACT_APP_MODE === 'retail') {
    if (currentToken) return DISPLAY_FOOTER_ITEMS;
    return disableItems(DISPLAY_FOOTER_ITEMS, [routePaths.LOGOUT]);
  }
  if (currentToken) return FOOTER_ITEMS;

  return disableItems(FOOTER_ITEMS, [routePaths.LOGOUT]);
};

export const TokenizationSidebar = () => {
  const [currentToken] = useCurrentToken();

  return (
    <Sidebar
      items={handleItems(currentToken)}
      footerItems={handleFooterItems(currentToken)}
      isDAO={false}
    />
  );
};
