import {
  Curve as BeCurve,
  Formula,
  CwebToken,
  ProtocolFields,
  TokenHashId,
  ExtraFields,
} from '@coinweb/cweb-wallet-library';
import { TokenFields, BondingCurveFields } from 'enums';
import BigNumber from 'bignumber.js';
import isEqual from 'lodash-es/isEqual';

type Curve = { poly: BigNumber[] };
// eslint-disable-next-line import/no-unused-modules
export const curveFunction = ({ poly }: Curve) => {
  return (x: BigNumber) =>
    BigNumber(poly[2])
      .multipliedBy(x.exponentiatedBy(BigNumber(2)))
      .plus(BigNumber(poly[1]).multipliedBy(x))
      .plus(BigNumber(poly[0]));
};

export type BondingCurve = {
  buy: Curve;
  sell: Curve;
  domain: BigNumber;
  supply: BigNumber;
  profit: BigNumber;
  curveExtraFields: ExtraFields | null;
};

const convertBigNumber = (x: any, decimals: number) =>
  BigNumber(x).dividedBy(BigNumber(10).exponentiatedBy(decimals));

const convertCurve = (curve: Formula, decimals: number): Curve => {
  if (!('Polynomial' in curve)) {
    // eslint-disable-next-line no-console
    return convertCurve({ Polynomial: { poly: ['0', '0', '0'] } }, decimals);
  }

  const { poly } = curve.Polynomial;
  let a = BigNumber(0);
  if (poly[2])
    a = BigNumber(poly[2]).dividedBy(BigNumber(10).exponentiatedBy(decimals));

  const c = BigNumber(poly[0]).multipliedBy(
    BigNumber(10).exponentiatedBy(decimals),
  );
  return {
    poly: [c, BigNumber(poly[1]), a],
  };
};

export class Token {
  public hashId!: TokenHashId;

  public bondingCurve?: BondingCurve;

  public tokenName?: string;

  private baseIcon?: string;

  public iconHash?: string;

  constructor(
    public protocolFields: ProtocolFields,
    public extraFields: ExtraFields,
    bondingCurve: BeCurve | null,
    bondingCurveExtraFields: ExtraFields | null,
    bondingCurveFunds: [CwebToken, CwebToken] | null,
  ) {
    this.hashId = protocolFields.hash_id;
    const decimals = this.extraFields.find(
      (field) => field.name === TokenFields.EXPONENT,
    )?.content;

    const tokenName = this.extraFields.find(
      (field) => field.name === TokenFields.NAME,
    )?.content;
    this.tokenName = tokenName;
    const exponent = decimals ? Number(decimals) : 0;
    const decimalPrice = exponent - 18;
    if (bondingCurve) {
      const buy = convertCurve(bondingCurve.curve_buy, decimalPrice);
      const sell = convertCurve(bondingCurve.curve_sell, decimalPrice);
      const domain = convertBigNumber(bondingCurve.domain, -decimalPrice);

      let supply;
      let profit;
      if (bondingCurveFunds) {
        [supply, profit] = bondingCurveFunds.map((fund) =>
          convertBigNumber(fund, -decimalPrice),
        );
      } else {
        // TODO: Remove this workaround once BE returning { Ok: ... }
        supply = convertBigNumber(bondingCurve.cweb_supply, -decimalPrice);
        const tokenSupply = convertBigNumber(
          bondingCurve.token_supply,
          -decimalPrice,
        );

        const sellFunction = curveFunction(sell);
        profit = sellFunction(BigNumber(0)).minus(
          tokenSupply.minus(sellFunction(supply)),
        );
      }
      const curveExtraFields = bondingCurveExtraFields;
      this.bondingCurve = {
        buy,
        sell,
        domain,
        supply,
        profit,
        curveExtraFields,
      };
    }
  }

  extraField(name: TokenFields) {
    return this.extraFields.find((field) => field.name === name)?.content;
  }

  setExtraField(extraField: ExtraFields) {
    this.extraFields = extraField;
  }

  bondingCurveExtraField(name: BondingCurveFields) {
    return this.bondingCurve && this.bondingCurve.curveExtraFields
      ? this.bondingCurve.curveExtraFields.find((field) => field.name === name)
          ?.content
      : null;
  }

  setBaseIcon(base: string) {
    this.baseIcon = base;
  }

  getBaseIcon() {
    return this.baseIcon;
  }

  isEqual(token: Token) {
    if (
      token.hashId === this.hashId &&
      token.bondingCurve === this.bondingCurve &&
      isEqual(token.extraFields, this.extraFields) &&
      isEqual(token.protocolFields, this.protocolFields)
    )
      return true;
    return false;
  }
}
