// eslint-disable-next-line import/no-unused-modules
export const L1TokensInfotext =
  'Choose which networks and token standards the token should be wrapped down to.';

export const tokenTypeInfotext =
  'Allows you to change editable data of your token. Once you finalize your selection, changes will be irreversible and can’t be toggled on again.';

export const tokenCreationInfotext =
  'Open your Coinweb wallet and scan the QR-code in order to ';
export const flowTypeInfotext =
  'Liquidity functionality through smart contract execution, or provided externally.';

export const onchainFlowTypeInfotext =
  'Liquidity provided through Coinweb smart contracts.';

export const offchainFlowTypeInfotext = 'Liquidity provided externally.';
// eslint-disable-next-line import/no-unused-modules
export const poolSetupInfotext = 'AMM liquidity pool setup.';
// eslint-disable-next-line import/no-unused-modules
export const cwebBalanceInfotext =
  'The ratio between CWEB and issued token will determine the price of the issued token.';
// eslint-disable-next-line import/no-unused-modules
export const liquidityPoolBalanceInfotext =
  'The ratio between CWEB and issued token will determine the price of the issued token.';

export const issuingModelSetupInfotext =
  'The ratio between CWEB and issued token will determine the price of the issued token.';

export const bondingCurveInfotext =
  'The ratio between CWEB and issued token will determine the price of the issued token.';

export const liquidityPoolInfotext =
  'The ratio between CWEB and issued token will determine the price of the issued token.';

export const fixedPriceInfoText =
  'The token has a fixed price in CWEB. This option requires filling the bonding curve with enough CWEB to cover the cost of the existing issued tokens, thus the transaction to create the bonding curve might require a significant amount of tokens.';
export const fixedPegPriceInfoText =
  'The token has a fixed price in CWEB, but a portion of tokens are naked meaning they are not covered by CWEB in the curve and cannot be sold back to the curve.';
export const smartPriceInfoText =
  'Curve specified through an initial price and growth in price and fees.';
export const smartPegPriceInfoText =
  'Curve specified through an initial price and growth in price and fees A portion of tokens are naked meaning they are not covered by CWEB in the curve and cannot be sold back to the curve.';
export const quadraticPriceInfoText =
  'Curve token amounts specified by a pair of quadratic functions for the buy and sell side.';
export const hyperbolicPriceInfoText =
  'Curve token price specified by a pair of hyperbolic functions for the buy and sell sides.';
export const drawPriceInfoText =
  'Buy and sell curves are fitted as a 16-degree polynomial based on hand drawn curves.';
export const chainPairsInfoText = 'Cross-chain liquidity setup.';
// eslint-disable-next-line import/no-unused-modules
export const thirdPartyInfoText = 'Connect to third-party liquidity providers.';

export const notificationHistoryInfoText =
  'Token holders and subscribers will receive a notification in their wallet app.';

export const complianceSettingsInfoText =
  'Coinweb is licensed in multiple jurisdictions. Projects can work with the Coinweb labs team to obtain necessary regulatory compliance on a case by case basis.';

export const complianceLicensedTokensaleInfoText =
  'Token sales regulation varies between jurisdictions. The Coinweb labs team can advice and facilitate licensed token sales.';
// eslint-disable-next-line import/no-unused-modules
export const priceParameterSummaryInfoText =
  'The price (p) will be represented in Cweb units at the parameter calculations.';

// eslint-disable-next-line import/no-unused-modules
export const tokenDetailsInfoText = 'Basic token properties.';

export const whiteListerInfoText =
  'Status of whether the token is whitelisted or not.';

export const tokenPropertiesInfoText = 'Properties that the token can possess.';

export const tokenSubTokenInfoText = 'Type of the subtoken.';
export const tokenFeePaymentInfoText = 'Payment method of your token fees.';
// eslint-disable-next-line import/no-unused-modules
export const bondingCurveWarningText =
  'Parameters can not be NaN or infinite value. Real numbers are required. Please correct your parameter values.';
export const tokenImageInfoText = 'The graphical symbol of the token.';
// eslint-disable-next-line import/no-unused-modules
export const bcFeeSetupInfoText = 'Fee setup.';
// eslint-disable-next-line import/no-unused-modules
export const bcInitialFeePercentageInfoText = 'Initial Fee percentage setup.';
export const bcPriceChartInfoText = 'y = -1 / (2 * a * x + b)';
export const bcAmountChartInfoText = 'y = a * x² + b * x + c';
export const bcPriceSetupComingInfoText = 'Coming soon.';
